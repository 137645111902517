@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Roboto:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Roboto:300,400,500,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background: #FFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.wrapper {
  display: table;
  height: 100%;
  width: 100%;
}

.container-fostrap {
  display: table-cell;
  padding: 1em;
  text-align: center;
  vertical-align: middle;
}
.fostrap-logo {
  width: 100px;
  margin-bottom:15px
}
h1.heading {
  color: #fff;
  font-size: 1.15em;
  font-weight: 900;
  margin: 0 0 0.5em;
  color: #505050;
}
@media (min-width: 450px) {
  h1.heading {
    font-size: 3.55em;
  }
}
@media (min-width: 760px) {
  h1.heading {
    font-size: 3.05em;
  }
}
@media (min-width: 900px) {
  h1.heading {
    font-size: 3.25em;
    margin: 0 0 0.3em;
  }
}
.card {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  transition: box-shadow .25s;
}
.card:hover {
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}
.img-card {
  width: 100%;
  height:200px;
  border-top-left-radius:2px;
  border-top-right-radius:2px;
  display:block;
  overflow: hidden;
}
.img-card img{
  width: 100%;
  height: 200px;
  object-fit:cover;
  transition: all .25s ease;
}
.card-content {
  padding:15px;
  text-align:left;
}
.card-title {
  margin-top:0px;
  font-weight: 700;
  font-size: 1.65em;
}
.card-title a {
  color: #000;
  text-decoration: none !important;
}
.card-read-more {
  border-top: 1px solid #D4D4D4;
}
.card-read-more a {
  text-decoration: none !important;
  padding:10px;
  font-weight:600;
  text-transform: uppercase
}

.baze-logo {
    height: 80px !important;
    width: 90px !important;
}

@media screen and (max-width: 400px) {
    .baze-logo {
        height: 60px !important;
        width: 60px !important;
    }
}
footer {
    height: 150px;
    bottom: 0;
    width: 100%;
}

.dialogBox .header {
    height: 40px;
}
.dialogBox .title {
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.dialogBox .header .title h5 {
    line-height: 40px;
    font-size: 14px;
}
.dialogBox .btn {
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
}
.dialogBox .header .title .btn {
    height: 30px;
    font-size: 12px;
    padding: 0px 20px;
    border-radius: 20px;
}

.dialogBox .children {
    font-size: 12px;
}

.container-fluid .why-we-stand-out {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 1.25rem;
}

.discover-section {
    text-align: justify;
}

.discover-section .button {
    margin-bottom: 0px;
}


.discover-section .bordered-dark {
    background-color: transparent !important;
    border: 1px solid #2f2f2f !important;
    color: #2f2f2f !important;
}

.discover-section .button {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color .25s ease-out, color .25s ease-out;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0.85em 1em;
    margin: 0 0 1rem;
    font-size: 1.5rem;
    background-color: #2199e8;
    color: #fefefe;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.w3-animate-fading2 {
    -webkit-animation: fading 13s infinite;
            animation: fading 13s infinite;
}

.image-zoom:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.bg-light {
    background-color: #f8f9fa !important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #63656A;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-text {
    font-size: 14px;
}

.count {
    border-radius: '10px';
    background-image: url(/static/media/count-bg.606e780a.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-position: center center !important;
    /* float: center; */
    width: 100%;
    padding: 40px 0;
    color: #ffffff;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.icon {
    padding-top: 20px;
    /* border: 1px solid rgba(255,255,255,0.2); */
    display: inline-block;
    width: 320px;
    height: 120px;
    border-radius: 50%;
}

.seperator-small {
    width: 10%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.47);
    margin: 15px auto;
}

.icons {
    font-size: 36px !important;
    margin-top: '10px';
}

.icon i {
    font-size: 56px !important;
    margin-top: '10px';
}

.count .col-xs-6 p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
}

.counter {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
    margin-top: 10px;
}

@media screen and (max-width: 400px) {
    .icon {
        padding-top: 20px;
        /* border: 1px solid rgba(255,255,255,0.2); */
        display: inline-block;
        width: 320px;
        height: 120px;
    }

    .seperator-small {
        width: 10%;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.47);
        margin: auto;
    }

    .icons {
        font-size: 26px !important;
        margin-top: '10px';
    }

    .count .col-xs-6 p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .counter {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 25px;
    }

    .marginb-5 {
        margin-bottom: 40px;
    }
}

.video-wrapper {
    position: relative;
}

.video-wrapper>video {
    width: 100%;
    /*vertical-align: middle;*/
}



.r-background {
    background: url('https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg');
    background-position: center;
    background-size: cover;
    height: 225px;
    width: 100%;
}

@media (min-width: 480px) {
    .r-background {
        height: 200px;
    }
}

@media (min-width: 768px) {
    .r-background {
        height: 150px;
    }
}

@media (min-width: 992px) {
    .r-background {
        height: 100px;
    }
}


.icons_i {
    font-size: 90px !important;
    margin-top: '10px';
}

.icon_i_blue {
    color: rgb(2, 0, 36) !important;
}


.icon_i_red {
    color: rgb(82, 7, 7) !important;
    
}

.icon_i_green {
    color: rgb(8, 73, 77) !important;
}

.icon_i_black {
    color: rgb(2, 0, 36) !important;
}

.icons_text {
    font-size: 20px !important;
}

.icon_i i {
    font-size: 76px !important;
    margin-top: '10px';
}


@media screen and (max-width: 450px) {
    .icons_i {
        font-size: 30px !important;
        margin-top: '10px';
    }
    .icons_text {
        font-size: 15px !important;
    }
    
    .icon_i i {
        font-size: 76px !important;
        margin-top: '10px';
    }
    .sm-margin{
        margin-bottom: 30px;
    }
  }

 
.col-image img {
    height: 700px !important;
}

.col-text {
    position: absolute !important;
    top: 400px !important;
}

.r-title {
    font-size: xx-large;
    font-weight: bolder;
}

.rr-title {
    font-size: '16px';
}

.tp-control .fa-arrow-circle-left {
    position: absolute;
    bottom: 300px;
    left: 10px;
    color: white;
    font-size: xx-large;
}

.tp-control .fa-arrow-circle-right {
    position: absolute;
    bottom: 300px;
    right: 10px;
    color: white;
    font-size: xx-large;
}

.mar-top {
    margin-top: 0px !important;
}

.caption_div {
    color: white;
    position: absolute;
    bottom: 2%;
    left: 5%;
    background-color: rgb(71, 71, 71);
    opacity: 0.9;
    padding: 25px;
    border-radius: 5px;
    text-align: left;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 250px;
}

.small_screen_btn {
    display: none;
}

@media screen and (max-width: 450px) {
    .col-image img {
        height: 150px !important;
    }

    .col-text {
        font-size: '12px';
        position: absolute !important;
        margin-top: -150px !important;
    }

    .r-title {
        font-size: small;
        font-weight: bolder;
    }

    .rr-title {
        font-size: '12px';
    }

    .tp-control .fa-arrow-circle-left {
        position: absolute;
        bottom: 300px;
        left: 10px;
        color: white;
        font-size: xx-large;
    }

    .tp-control .fa-arrow-circle-right {
        position: absolute;
        bottom: 300px;
        right: 10px;
        color: white;
        font-size: xx-large;
    }

    .mar-top {
        margin-top: -100px;
        position: relative;
    }

    .small_screen_btn {
        display: block;
        position: absolute;
        bottom: 22%;
        left: 5%;
        z-index: 5;
        background-color: rgb(77, 76, 76);
        color: white;
        padding: 5px;
        border-radius: 5px;
        opacity: 0.9;
    }

    .caption_div {
        display: none;
        color: white;
        position: absolute;
        bottom: 2%;
        left: 5%;
        background-color: rebeccapurple;
        opacity: 0.9;
        padding: 25px;
        border-radius: 5px;
        text-align: left;
        align-items: flex-start;
        margin-left: 50px;
        margin-right: 250px;
    }
}

@media screen and (max-width : 680px) {
    .small_screen_btn {
        display: block;
        position: absolute;
        bottom: 22%;
        left: 5%;
        z-index: 5;
        background-color: rgb(77, 76, 76);
        color: white;
        padding: 5px;
        border-radius: 5px;
        opacity: 0.9;
    }

    .caption_div {
        display: none;
        color: white;
        position: absolute;
        bottom: 2%;
        left: 5%;
        background-color: rebeccapurple;
        opacity: 0.9;
        padding: 25px;
        border-radius: 5px;
        text-align: left;
        align-items: flex-start;
        margin-left: 50px;
        margin-right: 250px;
    }

}

.date-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    height: 65px;
    width: 60px;
    padding-top: 12px;
    margin-right: 15px;
    margin-bottom: 1em;
    color: #fff;
    background-image: linear-gradient(#b5b5b5, #6e767d, #42484d);
    font-size: 18px;
}

.event-description span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: lighter;
    text-transform: uppercase;
    font-size: larger;
}

.event-description p {
    font-size: 12px;
}


/* ///////////// */

.pg_container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: grey;
    border-style: solid;

}

.pg_page-link {
    height: 30px;
    background-color: grey;
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    display: block;
    transition: 400ms ease;
    color: white;
    letter-spacing: 0.1em;
}

.pg_active {
    height: 30px;
    background-color: rgb(0, 0, 0);
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    color: white;
}

ul {
    list-style-type: none;

}


@media only screen and (max-width: 600px) {
    .items-list {
        grid-template-columns: repeat(2, 1fr);
    }
}


.information-boxes h3 {
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
}

.information-boxes {
    text-align: center;
    margin-top: 0px;
}

.information-boxes p,
.information-boxes ul {
    color: #FFF;
    text-align: left;
}

.information-boxes h4 {
    font-weight: bold;
    font-size: 2rem;
}

.module {
    position: relative;
    margin-bottom: 20px;
}

.courses-info,
.faculty-info,
.newadmission-info {
    position: relative;
    padding: 30px 30px;
}

.courses-info::after,
.faculty-info::after,
.newadmission-info::after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.courses-info {
    background-color: #9c9fa6;
    min-height: 200px
}

.no-bullet {
    margin-left: 0;
    list-style: none;
}

.faculty-info {
    background-color: #63656a;
}

.newadmission-info {
    background-color: #9c9fa6;
}


.coursecard {
    width: 100%;
    height: 150px;
    padding: 0 0 10 10;
    background-color: #9c9fa6;
    border-radius: 5px;
}

.coursedetcard {
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
}

.white-text {
    color: white;
}

.textBox {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
}

.textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
}


.finder_bg {
    background: rgb(23, 22, 22);
    background: linear-gradient(90deg, rgba(23, 22, 22, 1) 0%, rgba(120, 120, 120, 1) 18%, rgba(210, 210, 210, 1) 40%, rgba(0, 0, 0, 1) 76%, rgba(87, 83, 79, 1) 100%);

}
.col-image-banner img {
    height: 400px !important;
}
.col-text {
    position: absolute !important;
    top: 200px !important;
}

.col-text .cta-wrapper .cta-content {
    max-width: 775px !important;
}

@media screen and (min-width:335px) {
    .col-text{
        color:white;
        
    }
}


@media screen and (max-width:400px) {
    .col-image-banner img {
        height: 200px !important;
    }
    .col-text {
        color:white;
        position: absolute !important;
        top: 180px !important;
        font-size: 12px;
    }
}




.block.more-info div.more-info-items {
    padding-left: 0;
    margin: 0 -15px
}

.block.more-info div.more-info-items > .more-info-item {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 30px 0 0 15px;
    width: calc(100% - 30px)
}

.block.more-info div.more-info-items > .more-info-item:first-child {
    margin-top: 0
}

.block.more-info div.more-info-items > .more-info-item .media-left {
    min-width: 50px
}

.block.more-info div.more-info-items > .more-info-item .media-left span {
    font-size: 35px;
    line-height: 1em
}

.block.more-info div.more-info-items > .more-info-item .media-body > *:first-child {
    margin-top: 0
}

.block.more-info div.more-info-items > .more-info-item .media-body > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item, .layout .layout-9 .block.more-info div.more-info-items > .more-info-item, .layout .layout-8 .block.more-info div.more-info-items > .more-info-item, .layout .layout-6 .block.more-info div.more-info-items > .more-info-item {
        padding-right: 15px;
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-9 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-8 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-6 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2) {
        margin-top: 0
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-9 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-8 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-6 .block.more-info div.more-info-items > .more-info-item:nth-child(odd) {
        clear: left
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(odd) {
        clear: none
    }

    .layout .layout-12 .block.more-info div.more-info-items.two-items > .more-info-item {
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.three-items > .more-info-item {
        width: calc(33.3% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.max-items > .more-info-item {
        width: calc(25% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.max-items > .more-info-item:nth-child(4n+1) {
        clear: left
    }
}

.bef-exposed-form .form--inline .form-item {
    float: none;
}

.bef-exposed-form .form--inline > .form-item {
    float: left;
}

.bef-exposed-form .form--inline .bef--secondary {
    clear: left;
}

.block.page-filter .row.header ~ .row.divider hr {
    border-width: 3px
}

.block.page-filter .row.divider hr {
    border-color: #63656A
}

.block.page-filter .row.results ul.results {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.block.page-filter .row.results ul.results li.result {
    overflow: hidden;
    margin: 0 0 10px
}

.block.page-filter .row.results ul.results li.result span {
    display: block;
    font-size: .9em
}

.block.page-filter .row.results ul.results li.result a {
    font-weight: 600;
    padding: 10px;
    float: left;
    width: 100%;
    border-left: 10px solid;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    border-image: linear-gradient(to bottom, #ffa300, #63656A) 1;
    background: #f4f4f4
}

@media only screen and (max-width: 767px) {
    .block.page-filter .row.results ul.results {
        margin-top: 25px
    }
}


@media (min-width: 992px) {
    .block.page-filter .row.results ul.results {
        margin-left: -15px;
        margin-right: -15px;
    }

    .block.page-filter .row.results ul.results li.result {
        width: 33.3334%;
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        text-align: center;
        font-size: 19px;
        font-weight: 500;
    }

    .block.page-filter .row.results ul.results li.result a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 160px;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        border-top: 10px solid !important;
        border-left: 0 !important;
        border-image: linear-gradient(
                90deg, #63656A 0%, #cccccc 50%, #999999 100%) 1 !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        cursor: pointer;
    }
}



nav.pager {
    text-align: center
}

nav.pager ul.pager__items {
    display: inline-block;
    margin: 0
}

nav.pager ul.pager__items li.pager__item {
    display: inline-block;
    margin: 0
}

nav.pager ul.pager__items li.pager__item:not(:last-child) {
    margin-right: 5px !important
}

nav.pager ul.pager__items li.pager__item > a {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    border: 0;
    color: #fff;
    background-color: #8d8d8d;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

nav.pager ul.pager__items li.pager__item.is-active > a {
    cursor: default
}

nav.pager ul.pager__items li.pager__item.pager__item--next > a, nav.pager ul.pager__items li.pager__item.pager__item--previous > a {
    font-size: 20px;
    line-height: 20px
}

nav.pager ul.pager__items li.pager__item.pager__item--previous > a {
    padding-right: 2px
}

nav.pager ul.pager__items li.pager__item.pager__item--next > a {
    padding-left: 2px
}

nav.pager ul.pager__items li.pager__item.pager__item--ellipsis {
    position: relative;
    top: -2px;
    line-height: 10px;
    font-weight: bold;
    font-size: 38px;
    color: #8d8d8d
}

nav.pager ul.pager__items li.pager__item.pager__item--first, nav.pager ul.pager__items li.pager__item.pager__item--last {
    display: none
}

@media screen and (min-width: 767px) {
    nav.pager {
        text-align: right
    }

    nav.pager ul.pager_items li:not(:last-child) {
        margin-right: 20px !important
    }
}

nav.pager ul.pager__items li.pager__item > a:hover, nav.pager ul.pager__items li.pager__item > a:active, nav.pager ul.pager__items li.pager__item > a:focus {
    color: #fff;
    background-color: #a0a0a0
}

div.light-orange-accents nav.pager ul.pager__items li.pager__item > a:hover, div.light-orange-accents nav.pager ul.pager__items li.pager__item > a:active, div.light-orange-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #ffb126;
    color: #2d2d2d
}

div.dark-orange-accents nav.pager ul.pager__items li.pager__item > a:hover, div.dark-orange-accents nav.pager ul.pager__items li.pager__item > a:active, div.dark-orange-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #ff9245;
    color: #2d2d2d
}

div.red-accents nav.pager ul.pager__items li.pager__item > a:hover, div.red-accents nav.pager ul.pager__items li.pager__item > a:active, div.red-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #63656A;
    color: #fff
}

div.rubine-accents nav.pager ul.pager__items li.pager__item > a:hover, div.rubine-accents nav.pager ul.pager__items li.pager__item > a:active, div.rubine-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #63656A;
    color: #fff
}

div.berry-accents nav.pager ul.pager__items li.pager__item > a:hover, div.berry-accents nav.pager ul.pager__items li.pager__item > a:active, div.berry-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #63656A;
    color: #fff
}

div.chocolate-accents nav.pager ul.pager__items li.pager__item > a:hover, div.chocolate-accents nav.pager ul.pager__items li.pager__item > a:active, div.chocolate-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #874334;
    color: #fff
}

div.grey-accents nav.pager ul.pager__items li.pager__item > a:hover, div.grey-accents nav.pager ul.pager__items li.pager__item > a:active, div.grey-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #fff;
    color: #3d3d3d
}

nav.pager ul.pager__items li.pager__item.is-active > a {
    color: #fff;
    background-color: #a0a0a0
}

div.light-orange-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #ffb126;
    color: #2d2d2d
}

div.dark-orange-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #ff9245;
    color: #2d2d2d
}

div.red-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #63656A;
    color: #fff
}

div.rubine-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #63656A;
    color: #fff
}

div.berry-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #63656A;
    color: #fff
}

div.chocolate-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #874334;
    color: #fff
}

div.grey-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #fff;
    color: #3d3d3d
}

.block.views-block.pagination-toggle.dropdown-filters form .form-item.js-form-item-items-per-page, .block.views-block.pagination-toggle:not(.dropdown-filters) form {
    display: none
}

.block.views-block.pagination-toggle .pager-section {
    margin-top: 20px
}

.block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper {
    display: inline-flex
}

.block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper .show-all-button, .block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper .show-less-button, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper .show-all-button, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper .show-less-button {
    border-radius: 60px;
    font-size: 14px;
    font-weight: 700;
    padding: 2px 10px;
    color: #fff;
    background-color: #63656A
}

.block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper .show-all-button:hover, .block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper .show-less-button:hover, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper .show-all-button:hover, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper .show-less-button:hover {
    color: #fff;
    background-color: #63656A;
    border-color: #63656A !important
}

.block.views-block.pagination-toggle .pager-section .show-less-btn-container {
    text-align: center
}

@media only screen and (min-width: 768px) {
    .block.views-block.pagination-toggle .pager-section .show-less-btn-container {
        text-align: right
    }
}

@-webkit-keyframes bs-notify-fadeOut {
    0% {
        opacity: 0.9;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bs-notify-fadeOut {
    0% {
        opacity: 0.9;
    }
    100% {
        opacity: 0;
    }
}

select.bs-select-hidden, .bootstrap-select > select.bs-select-hidden, select.selectpicker {
    display: none !important;
}

.bootstrap-select {
    width: 220px \0;
    vertical-align: middle;
}

.bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.bootstrap-select > .dropdown-toggle:after {
    margin-top: -1px;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: #999;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
    color: rgba(255, 255, 255, 0.5);
}

.bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important;
}

.bootstrap-select > select.mobile-device {
    top: 0;
    left: 0;
    display: block !important;
    width: 100% !important;
    z-index: 2 !important;
}

.has-error .bootstrap-select .dropdown-toggle, .error .bootstrap-select .dropdown-toggle, .bootstrap-select.is-invalid .dropdown-toggle, .was-validated .bootstrap-select select:invalid + .dropdown-toggle {
    border-color: #63656A;
}

.bootstrap-select.is-valid .dropdown-toggle, .was-validated .bootstrap-select select:valid + .dropdown-toggle {
    border-color: #28a745;
}

.bootstrap-select.fit-width {
    width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 335px;
    max-width: 550px;
}

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle, .bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
}

.bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
    height: auto;
}

:not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
    float: none;
    z-index: auto;
}

.form-inline .bootstrap-select, .form-inline .bootstrap-select.form-control:not([class*="col-"]) {
    width: auto;
}

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
}

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*="col-"].dropdown-menu-right, .row .bootstrap-select[class*="col-"].dropdown-menu-right {
    float: right;
}

.form-inline .bootstrap-select, .form-horizontal .bootstrap-select, .form-group .bootstrap-select {
    margin-bottom: 0;
}

.form-group-lg .bootstrap-select.form-control, .form-group-sm .bootstrap-select.form-control {
    padding: 0;
}

.form-group-lg .bootstrap-select.form-control .dropdown-toggle, .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle, .bootstrap-select.form-control-lg .dropdown-toggle {
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
    padding: 0.25rem 0.5rem;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
    padding: 0.5rem 1rem;
}

.form-inline .bootstrap-select .form-control {
    width: 100%;
}

.bootstrap-select.disabled, .bootstrap-select > .disabled {
    cursor: not-allowed;
}

.bootstrap-select.disabled:focus, .bootstrap-select > .disabled:focus {
    outline: none !important;
}

.bootstrap-select.bs-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 0 !important;
    padding: 0 !important;
}

.bootstrap-select.bs-container .dropdown-menu {
    z-index: 1060;
}

.bootstrap-select .dropdown-toggle .filter-option {
    position: static;
    top: 0;
    left: 0;
    float: left;
    height: 100%;
    width: 100%;
    text-align: left;
    overflow: hidden;
    flex: 0 1 auto;
}

.bs3.bootstrap-select .dropdown-toggle .filter-option {
    padding-right: inherit;
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
    position: absolute;
    padding-top: inherit;
    padding-bottom: inherit;
    padding-left: inherit;
    float: none;
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
    padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
}

.bootstrap-select .dropdown-toggle .filter-expand {
    width: 0 !important;
    float: left;
    opacity: 0 !important;
    overflow: hidden;
}

.bootstrap-select .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
    border-radius: inherit;
}

.bootstrap-select[class*="col-"] .dropdown-toggle {
    width: 100%;
}

.bootstrap-select .dropdown-menu {
    min-width: 100%;
    box-sizing: border-box;
}

.bootstrap-select .dropdown-menu > .inner:focus {
    outline: none !important;
}

.bootstrap-select .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
}

.bootstrap-select .dropdown-menu li {
    position: relative;
}

.bootstrap-select .dropdown-menu li.active small {
    color: rgba(255, 255, 255, 0.5) !important;
}

.bootstrap-select .dropdown-menu li.disabled a {
    cursor: not-allowed;
}

.bootstrap-select .dropdown-menu li a {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.bootstrap-select .dropdown-menu li a.opt {
    position: relative;
    padding-left: 2.25em;
}

.bootstrap-select .dropdown-menu li a span.check-mark {
    display: none;
}

.bootstrap-select .dropdown-menu li a span.text {
    display: inline-block;
}

.bootstrap-select .dropdown-menu li small {
    padding-left: 0.5em;
}

.bootstrap-select .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: #f5f5f5;
    border: 1px solid #e3e3e3;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    box-sizing: border-box;
}

.bootstrap-select .dropdown-menu .notify.fadeOut {
    -webkit-animation: 300ms linear 750ms forwards bs-notify-fadeOut;
    animation: 300ms linear 750ms forwards bs-notify-fadeOut;
}

.bootstrap-select .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
    white-space: nowrap;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
    position: static;
    display: inline;
    padding: 0;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner, .bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
    display: inline;
}

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
    content: '\A0';
}

.bootstrap-select.fit-width .dropdown-toggle .caret {
    position: static;
    top: auto;
    margin-top: -1px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 5px;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
    margin-right: 34px;
}

.bootstrap-select .bs-ok-default:after {
    content: '';
    display: block;
    width: 0.5em;
    height: 1em;
    border-style: solid;
    border-width: 0 0.26em 0.26em 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
    z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(204, 204, 204, 0.2);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
    bottom: auto;
    top: -4px;
    border-top: 7px solid rgba(204, 204, 204, 0.2);
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
    bottom: auto;
    top: -4px;
    border-top: 6px solid white;
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
    right: 12px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
    right: 13px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
    display: block;
}

.bs-searchbox, .bs-actionsbox, .bs-donebutton {
    padding: 4px 8px;
}

.bs-actionsbox {
    width: 100%;
    box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
    width: 50%;
}

.bs-donebutton {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.bs-donebutton .btn-group button {
    width: 100%;
}

.bs-searchbox + .bs-actionsbox {
    padding: 0 8px 4px;
}

.bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
}

.block.views-block.dropdown-filters .container form .bootstrap-select button.btn.btn-default {
    padding: 0
}

.block.views-block.dropdown-filters .container form .bootstrap-select button.btn.btn-default:hover, .block.views-block.dropdown-filters .container form .bootstrap-select button.btn.btn-default:active, .block.views-block.dropdown-filters .container form .bootstrap-select button.btn.btn-default:focus {
    color: #3d3d3d;
    background-color: #fff
}

.block.views-block.dropdown-filters .container form .bootstrap-select .selectpicker.error + .dropdown-toggle {
    color: #63656A;
    border-color: #63656A
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle {
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 600;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid silver;
    display: flex
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .filter-option {
    position: relative;
    padding: 8px 10px 8px 20px;
    flex: 0 1 auto
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .filter-option, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .filter-option * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret {
    display: inline-block;
    height: 40px;
    width: 48px;
    text-align: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #fff;
    background-color: #63656A;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret::before {
    display: block;
    content: "\EE\A4\2026";
    font-family: ucalgary;
    font-size: 24px;
    line-height: 40px;
    transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret .caret {
    display: none
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret:hover, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret:active, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret:focus {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle:active, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
    border-color: #8d8d8d
}

.block.views-block.dropdown-filters .container form .bootstrap-select.open .dropdown-toggle {
    background-color: #fff
}

.block.views-block.dropdown-filters .container form .bootstrap-select.open .dropdown-toggle .bs-caret::before {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg)
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu {
    padding: 0;
    border-width: 2px;
    border-color: silver;
    z-index: 1968
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu .dropdown-header {
    font-weight: 700;
    font-size: 16px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li {
    margin: 0
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li[class^=optgroup-] {
    list-style: circle;
    margin-left: 35px;
    padding-left: 0
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a {
    padding: 10px 20px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a:hover {
    font-weight: 600
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a.active, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a.selected {
    color: #fff;
    background-color: #8d8d8d;
    font-weight: 600
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a.opt {
    padding-left: 5px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a span.check-mark {
    top: 15px;
    font-size: .75em
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li.no-results {
    padding: 3px 6px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .bs-searchbox {
    margin-top: 4px;
    margin-bottom: 4px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .bs-searchbox > .form-control:focus {
    border-width: 2px;
    border-color: silver;
    box-shadow: none;
    outline: none
}

@media only screen and (max-width: 767px) {
    .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu {
        max-width: 100%;
        min-width: auto !important
    }

    .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a span.text {
        display: inline
    }
}

.block.views-block.dropdown-filters .container form input[type=date], .block.views-block.dropdown-filters .container form input[type=datetime-local], .block.views-block.dropdown-filters .container form input[type=email], .block.views-block.dropdown-filters .container form input[type=month], .block.views-block.dropdown-filters .container form input[type=number], .block.views-block.dropdown-filters .container form input[type=password], .block.views-block.dropdown-filters .container form input[type=search], .block.views-block.dropdown-filters .container form input[type=tel], .block.views-block.dropdown-filters .container form input[type=text], .block.views-block.dropdown-filters .container form input[type=time], .block.views-block.dropdown-filters .container form input[type=url], .block.views-block.dropdown-filters .container form input[type=week], .block.views-block.dropdown-filters .container form textarea, .block.views-block.dropdown-filters .container form select {
    padding: 8px 10px;
    border-radius: 5px;
    border: 2px solid silver;
    width: 100%;
    max-width: 555px;
    font-size: 16px;
    height: auto
}

.block.views-block.dropdown-filters .container form input[type=date]:focus, .block.views-block.dropdown-filters .container form input[type=datetime-local]:focus, .block.views-block.dropdown-filters .container form input[type=email]:focus, .block.views-block.dropdown-filters .container form input[type=month]:focus, .block.views-block.dropdown-filters .container form input[type=number]:focus, .block.views-block.dropdown-filters .container form input[type=password]:focus, .block.views-block.dropdown-filters .container form input[type=search]:focus, .block.views-block.dropdown-filters .container form input[type=tel]:focus, .block.views-block.dropdown-filters .container form input[type=text]:focus, .block.views-block.dropdown-filters .container form input[type=time]:focus, .block.views-block.dropdown-filters .container form input[type=url]:focus, .block.views-block.dropdown-filters .container form input[type=week]:focus, .block.views-block.dropdown-filters .container form textarea:focus, .block.views-block.dropdown-filters .container form select:focus {
    outline: none;
    border-color: #8d8d8d
}

.block.views-block.dropdown-filters .container form input[type=date].error, .block.views-block.dropdown-filters .container form input[type=datetime-local].error, .block.views-block.dropdown-filters .container form input[type=email].error, .block.views-block.dropdown-filters .container form input[type=month].error, .block.views-block.dropdown-filters .container form input[type=number].error, .block.views-block.dropdown-filters .container form input[type=password].error, .block.views-block.dropdown-filters .container form input[type=search].error, .block.views-block.dropdown-filters .container form input[type=tel].error, .block.views-block.dropdown-filters .container form input[type=text].error, .block.views-block.dropdown-filters .container form input[type=time].error, .block.views-block.dropdown-filters .container form input[type=url].error, .block.views-block.dropdown-filters .container form input[type=week].error, .block.views-block.dropdown-filters .container form textarea.error, .block.views-block.dropdown-filters .container form select.error {
    border-color: #63656A;
    background-color: #f5ccd2
}

.block.views-block.dropdown-filters div.light-orange-accents .container form input[type=date], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=email], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=month], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=number], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=password], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=search], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=tel], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=text], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=time], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=url], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=week], .block.views-block.dropdown-filters div.light-orange-accents .container form textarea, .block.views-block.dropdown-filters div.light-orange-accents .container form select {
    border: 2px solid #ffa300
}

.block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=date], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=email], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=month], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=number], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=password], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=search], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=tel], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=text], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=time], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=url], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=week], .block.views-block.dropdown-filters div.dark-orange-accents .container form textarea, .block.views-block.dropdown-filters div.dark-orange-accents .container form select {
    border: 2px solid #ff7b1f
}

.block.views-block.dropdown-filters div.red-accents .container form input[type=date], .block.views-block.dropdown-filters div.red-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.red-accents .container form input[type=email], .block.views-block.dropdown-filters div.red-accents .container form input[type=month], .block.views-block.dropdown-filters div.red-accents .container form input[type=number], .block.views-block.dropdown-filters div.red-accents .container form input[type=password], .block.views-block.dropdown-filters div.red-accents .container form input[type=search], .block.views-block.dropdown-filters div.red-accents .container form input[type=tel], .block.views-block.dropdown-filters div.red-accents .container form input[type=text], .block.views-block.dropdown-filters div.red-accents .container form input[type=time], .block.views-block.dropdown-filters div.red-accents .container form input[type=url], .block.views-block.dropdown-filters div.red-accents .container form input[type=week], .block.views-block.dropdown-filters div.red-accents .container form textarea, .block.views-block.dropdown-filters div.red-accents .container form select {
    border: 2px solid #63656A
}

.block.views-block.dropdown-filters div.rubine-accents .container form input[type=date], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=email], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=month], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=number], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=password], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=search], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=tel], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=text], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=time], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=url], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=week], .block.views-block.dropdown-filters div.rubine-accents .container form textarea, .block.views-block.dropdown-filters div.rubine-accents .container form select {
    border: 2px solid #63656A
}

.block.views-block.dropdown-filters div.berry-accents .container form input[type=date], .block.views-block.dropdown-filters div.berry-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.berry-accents .container form input[type=email], .block.views-block.dropdown-filters div.berry-accents .container form input[type=month], .block.views-block.dropdown-filters div.berry-accents .container form input[type=number], .block.views-block.dropdown-filters div.berry-accents .container form input[type=password], .block.views-block.dropdown-filters div.berry-accents .container form input[type=search], .block.views-block.dropdown-filters div.berry-accents .container form input[type=tel], .block.views-block.dropdown-filters div.berry-accents .container form input[type=text], .block.views-block.dropdown-filters div.berry-accents .container form input[type=time], .block.views-block.dropdown-filters div.berry-accents .container form input[type=url], .block.views-block.dropdown-filters div.berry-accents .container form input[type=week], .block.views-block.dropdown-filters div.berry-accents .container form textarea, .block.views-block.dropdown-filters div.berry-accents .container form select {
    border: 2px solid #63656A
}

.block.views-block.dropdown-filters div.chocolate-accents .container form input[type=date], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=email], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=month], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=number], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=password], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=search], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=tel], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=text], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=time], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=url], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=week], .block.views-block.dropdown-filters div.chocolate-accents .container form textarea, .block.views-block.dropdown-filters div.chocolate-accents .container form select {
    border: 2px solid #6b3529
}

.block.views-block.dropdown-filters div.grey-accents .container form input[type=date], .block.views-block.dropdown-filters div.grey-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.grey-accents .container form input[type=email], .block.views-block.dropdown-filters div.grey-accents .container form input[type=month], .block.views-block.dropdown-filters div.grey-accents .container form input[type=number], .block.views-block.dropdown-filters div.grey-accents .container form input[type=password], .block.views-block.dropdown-filters div.grey-accents .container form input[type=search], .block.views-block.dropdown-filters div.grey-accents .container form input[type=tel], .block.views-block.dropdown-filters div.grey-accents .container form input[type=text], .block.views-block.dropdown-filters div.grey-accents .container form input[type=time], .block.views-block.dropdown-filters div.grey-accents .container form input[type=url], .block.views-block.dropdown-filters div.grey-accents .container form input[type=week], .block.views-block.dropdown-filters div.grey-accents .container form textarea, .block.views-block.dropdown-filters div.grey-accents .container form select {
    border: 2px solid #f4f4f4
}

.block.views-block.dropdown-filters .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 42px;
    height: 44px;
    color: #fff;
    background-color: #63656A;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.views-block.dropdown-filters .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus {
    box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25);
    outline: none;
    border-color: #8d8d8d
}

.block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #2d2d2d;
    background-color: #ffa300
}

.block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #ffb126
}

.block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #2d2d2d;
    background-color: #ff7b1f
}

.block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #ff9245
}

.block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #63656A
}

.block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #63656A
}

.block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #63656A
}

.block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #fff;
    background-color: #6b3529
}

.block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #874334
}

.block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #3d3d3d;
    background-color: #f4f4f4
}

.block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #fff
}

.block.views-block.dropdown-filters .container form .button.reset-button {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 42px;
    height: 44px;
    color: inherit;
    background-color: transparent;
    border-color: currentColor !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.views-block.dropdown-filters .container form .button.reset-button:hover, .block.views-block.dropdown-filters .container form .button.reset-button:active, .block.views-block.dropdown-filters .container form .button.reset-button:focus, .block.views-block.dropdown-filters .container form .button.reset-button.active {
    color: #fff;
    background-color: #63656A;
    border-color: #63656A !important
}

.block.views-block.dropdown-filters .container form .button.reset-button:active, .block.views-block.dropdown-filters .container form .button.reset-button:focus {
    border-width: 0;
    padding-left: 44px;
    padding-right: 44px;
    box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25);
    outline: none;
    border-color: #8d8d8d
}

.block.views-block.dropdown-filters form {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

.block.views-block.dropdown-filters .form-item.js-form-type-textfield, .block.views-block.dropdown-filters .form-item.js-form-type-select {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px
}

.block.views-block.dropdown-filters .form-item.js-form-type-textfield label, .block.views-block.dropdown-filters .form-item.js-form-type-select label {
    display: block
}

.block.views-block.dropdown-filters .form-item.js-form-type-textfield {
    order: 100
}

.block.views-block.dropdown-filters .form-item.js-form-type-select select {
    visibility: hidden
}

.block.views-block.dropdown-filters .form-item.js-form-type-select div.bootstrap-select {
    width: 100%
}

.block.views-block.dropdown-filters .form-actions {
    order: 101;
    flex-basis: 100%;
    margin-left: 15px;
    margin-right: 15px
}

.block.views-block.dropdown-filters .form-actions input.button {
    margin-bottom: 30px;
    margin-top: 20px
}

.block.views-block.dropdown-filters .form-actions input.button:not(:last-child) {
    margin-right: 15px
}

@media only screen and (max-width: 767px) {
    .block.views-block.dropdown-filters .form-item.js-form-type-textfield, .block.views-block.dropdown-filters .form-item.js-form-type-select {
        width: 100%
    }

    .block.views-block.dropdown-filters .form-item.js-form-type-select div.bootstrap-select {
        width: 100% !important
    }
}

@media only screen and (min-width: 768px)and (max-width: 991px) {
    .block.views-block.dropdown-filters .form-item.js-form-type-textfield, .block.views-block.dropdown-filters .form-item.js-form-type-select {
        width: calc(50% - 30px)
    }
}

@media only screen and (min-width: 992px) {
    .block.views-block.dropdown-filters .form-item.js-form-type-textfield, .block.views-block.dropdown-filters .form-item.js-form-type-select {
        width: calc(25% - 30px)
    }

    .block.views-block.dropdown-filters .form-actions input.button {
        margin-bottom: 60px
    }
}

.block.views-block.keyword-filters .row.search-bubbles {
    margin-left: 0;
    margin-right: 0
}

.block.views-block.keyword-filters .row.search-bubbles p {
    display: inline-block;
    margin: 0 10px 0 0
}

.block.views-block.keyword-filters .row.search-bubbles .search-bubble {
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    padding: 5px 35px 5px 15px;
    border-radius: 15px;
    background-color: #ffa300;
    color: #2d2d2d;
    cursor: default
}

.block.views-block.keyword-filters .row.search-bubbles .search-bubble .remove-bubble-icon {
    position: absolute;
    top: calc(50% - 8px);
    right: 10px;
    line-height: 16px;
    cursor: pointer
}


.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret::before {
    display: block;
    content: "\E905";
    font-family: ucalgary;
    font-size: 24px;
    line-height: 40px;
    transition: -webkit-transform 500ms ease 0s;
    transition: transform 500ms ease 0s;
    transition: transform 500ms ease 0s, -webkit-transform 500ms ease 0s;
}
.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret .caret {
    display: none;
}
.bootstrap-select .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}
.btn .caret {
    margin-left: 0;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.pop_up_box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
}

.pop_up_button {
    font-size: 1em;
    padding: 10px;
    color: #fff;
    border: 2px solid #06d85f;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.pop_up_button:hover {
    background: #06d85f;
}

.pop_up_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 5;
}

.pop_up_overlay:target {
    visibility: visible;
    opacity: 1;
}

.pop_up_popup {
    margin: 0px auto;
    padding: 20px;
    top: 30%;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
}

.pop_up_popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.pop_up_popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.pop_up_popup .close:hover {
    color: #06d85f;
}

.pop_up_popup .content {
    max-height: 30%;
    overflow: auto;
}

@media screen and (max-width: 700px) {
    .pop_up_box {
        width: 70%;
    }

    .pop_up_popup {
        width: 70%;
    }
}
.block.image-text .min-height-fix {
    display: flex
}

.block.image-text .rounded-box {
    display: flex;
    flex-direction: column;
    width: 100%
}

.block.image-text .rounded-box .sidekick-image {
    background-image: none
}

.block.image-text .rounded-box .sidekick-text {
    padding: 25px;
    text-align: left
}

.block.image-text .rounded-box .sidekick-text > *:first-child {
    margin-top: 0
}

.block.image-text .rounded-box .sidekick-text > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .block.image-text .rounded-box {
        flex-direction: row;
        align-items: center;
        min-height: 350px
    }

    .block.image-text .rounded-box .sidekick-image, .block.image-text .rounded-box .sidekick-text {
        width: 50%
    }

    .block.image-text .rounded-box .sidekick-image {
        height: auto;
        align-self: stretch;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat
    }

    .block.image-text .rounded-box .sidekick-image img {
        display: none
    }

    .block.image-text .rounded-box .sidekick-text {
        padding: 40px;
        text-align: center
    }

    .block.image-text.image-right .sidekick-text {
        order: -1
    }
}

@media only screen and (min-width: 992px) {
    .block.image-text .rounded-box {
        min-height: 400px
    }
}

.block.image-text .min-height-fix {
    display: flex
}

.block.image-text .rounded-box {
    display: flex;
    flex-direction: column;
    width: 100%
}

.block.image-text .rounded-box .sidekick-image {
    background-image: none
}

.block.image-text .rounded-box .sidekick-text {
    padding: 25px;
    text-align: left
}

.block.image-text .rounded-box .sidekick-text h2{
    margin-top: 5px !important;
}

.block.image-text .rounded-box .sidekick-text > *:first-child {
    margin-top: 0
}

.block.image-text .rounded-box .sidekick-text > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .block.image-text .rounded-box {
        flex-direction: row;
        align-items: center;
    }

    .block.image-text .rounded-box .sidekick-image, .block.image-text .rounded-box .sidekick-text {
        width: 50%
    }

    .block.image-text .rounded-box .sidekick-image {
        height: auto;
        align-self: stretch;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat
    }

    .block.image-text .rounded-box .sidekick-image img {
        display: none
    }

    .block.image-text .rounded-box .sidekick-text {
        padding: 40px;
        text-align: center
    }

    .block.image-text.image-right .sidekick-text {
        order: -1
    }
}

.block_quate {
    border-left: 4px solid #999999;
}

.block.more-info div.more-info-items {
    padding-left: 0;
    margin: 0 -15px;
}

/*.icon {*/
/*    width: 5% !important;*/
/*    padding-top: 0 !important;*/
/*}*/



.img-responsive {
    width: 100% !important;
    height: 320px !important;
    object-position: center top;
}

.img-responsive-top {
    width: 100% !important;
    height:400px !important;
    object-position: center top;
}
span.member_learn_more {
    position: relative;
    font-weight: 600;
    line-height: 1.55em;
    background-image: linear-gradient(currentColor,currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
}

.block.image-text .min-height-fix {
    display: flex
}

.block.image-text .rounded-box {
    display: flex;
    flex-direction: column;
    width: 100%
}

.block.image-text .rounded-box .sidekick-image {
    background-image: none
}

.block.image-text .rounded-box .sidekick-text {
    padding: 25px;
    text-align: left
}

.block.image-text .rounded-box .sidekick-text > *:first-child {
    margin-top: 0
}

.block.image-text .rounded-box .sidekick-text > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .block.image-text .rounded-box {
        flex-direction: row;
        align-items: center;
        min-height: 350px
    }

    .block.image-text .rounded-box .sidekick-image, .block.image-text .rounded-box .sidekick-text {
        width: 50%
    }

    .block.image-text .rounded-box .sidekick-image {
        height: auto;
        align-self: stretch;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat
    }

    .block.image-text .rounded-box .sidekick-image img {
        display: none
    }

    .block.image-text .rounded-box .sidekick-text {
        padding: 40px;
        text-align: center
    }

    .block.image-text.image-right .sidekick-text {
        order: -1
    }
}

@media only screen and (min-width: 992px) {
    .block.image-text .rounded-box {
        min-height: 400px
    }
}

.block.related-content > .row {
    background-color: rgba(255, 255, 255, .5)
}

.block.related-content.rounded-block-above > div.row:first-child {
    padding-top: 0 !important
}

.block.related-content.rounded-block-below > div.row:last-child {
    padding-bottom: 0 !important
}

.block.related-content .chicklet {
    padding: 0
}

.block.related-content .chicklet .chicklet-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px
}

.block.related-content .chicklet .chicklet-image p.h2 {
    display: none
}

.block.related-content .chicklet .chicklet-content {
    position: relative;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer
}

.block.related-content .chicklet .chicklet-content .content-wrapper {
    height: 100%;
    padding: 25px
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :first-child {
    margin-top: 0
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :last-child {
    margin-bottom: 5px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child) {
    margin-bottom: 20px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child):after {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: inherit
}

@media only screen and (max-width: 767px) {
    .block.related-content .chicklet .chicklet-content {
        margin-top: -8px
    }

    .block.related-content .chicklet:not(:last-child) .chicklet-content {
        margin-bottom: -8px
    }
}

@media only screen and (max-width: 767px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-image {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 0;
        padding-bottom: 20px
    }
}

@media only screen and (min-width: 768px) {
    .block.related-content .chicklet {
        height: 400px
    }

    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }

    .block.related-content .chicklet .chicklet-image {
        height: 100%
    }

    .block.related-content .chicklet .chicklet-content {
        z-index: 2
    }

    .block.related-content .chicklet .chicklet-content p.link {
        position: absolute;
        left: 25px;
        bottom: 25px
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        width: 50%;
        border-radius: 0
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-image {
        left: auto
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-content {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-image {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-content {
        left: auto
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) {
        height: 420px
    }

    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-content {
        margin-top: 20px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1), .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content .chicklet .chicklet-image img {
        cursor: pointer
    }

    .block.related-content .chicklet .chicklet-image::before {
        content: "";
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%)
    }

    .block.related-content .chicklet .chicklet-image p.h2 {
        display: block;
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;
        margin: 0 0 40px;
        z-index: 2;
        color: #fff;
        text-align: center;
        text-shadow: 0 0 10px #000
    }

    .block.related-content .chicklet .chicklet-content {
        top: auto;
        right: 25px;
        bottom: 25px;
        left: 25px;
        width: auto;
        height: 15px;
        transition: height .5s ease-in-out !important;
        overflow: hidden
    }

    .block.related-content .chicklet .chicklet-content .content-wrapper {
        position: relative;
        height: 350px
    }

    .block.related-content .chicklet:hover .chicklet-content, .block.related-content .chicklet:active .chicklet-content, .block.related-content .chicklet:focus .chicklet-content {
        height: 350px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content.rounded-block-above > div.row > .chicklet, .block.related-content.rounded-block-below > div.row > .chicklet {
        height: 450px
    }

    .block.related-content.rounded-block-above.rounded-block-below > div.row > .chicklet {
        height: 500px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-image p.h2 {
        bottom: 75px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-content {
        bottom: 75px
    }
}

.textBox_t {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
  }
  
  .textBox_t:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
  }

  /* ///////////// */

.pg_container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: grey;
    border-style: solid;

}

.pg_page-link {
    height: 30px;
    background-color: grey;
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    display: block;
    transition: 400ms ease;
    color: white;
    letter-spacing: 0.1em;
}

.pg_active {
    height: 30px;
    background-color: rgb(0, 0, 0);
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    color: white;
}
ul {
    list-style-type: none;

}


@media only screen and (max-width: 600px) {
    .items-list {
        grid-template-columns: repeat(2, 1fr);
    }
}



.block.related-content > .row {
    background-color: rgba(255, 255, 255, .5)
}

.block.related-content.rounded-block-above > div.row:first-child {
    padding-top: 0 !important
}

.block.related-content.rounded-block-below > div.row:last-child {
    padding-bottom: 0 !important
}

.block.related-content .chicklet {
    padding: 0
}

.block.related-content .chicklet .chicklet-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px
}

.block.related-content .chicklet .chicklet-image p.h2 {
    display: none
}

.block.related-content .chicklet .chicklet-content {
    position: relative;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer
}

.block.related-content .chicklet .chicklet-content .content-wrapper {
    height: 100%;
    padding: 25px
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :first-child {
    margin-top: 0
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :last-child {
    margin-bottom: 5px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child) {
    margin-bottom: 20px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child):after {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: inherit
}

@media only screen and (max-width: 767px) {
    .block.related-content .chicklet .chicklet-content {
        margin-top: -8px
    }

    .block.related-content .chicklet:not(:last-child) .chicklet-content {
        margin-bottom: -8px
    }
}

@media only screen and (max-width: 767px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-image {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 0;
        padding-bottom: 20px
    }
}

@media only screen and (min-width: 768px) {
    .block.related-content .chicklet {
        height: 400px
    }

    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }

    .block.related-content .chicklet .chicklet-image {
        height: 100%
    }

    .block.related-content .chicklet .chicklet-content {
        z-index: 2
    }

    .block.related-content .chicklet .chicklet-content p.link {
        position: absolute;
        left: 25px;
        bottom: 25px
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        width: 50%;
        border-radius: 0
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-image {
        left: auto
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-content {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-image {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-content {
        left: auto
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) {
        height: 420px
    }

    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-content {
        margin-top: 20px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1), .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content .chicklet .chicklet-image img {
        cursor: pointer
    }

    .block.related-content .chicklet .chicklet-image::before {
        content: "";
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%)
    }

    .block.related-content .chicklet .chicklet-image p.h2 {
        display: block;
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;
        margin: 0 0 40px;
        z-index: 2;
        color: #fff;
        text-align: center;
        text-shadow: 0 0 10px #000
    }

    .block.related-content .chicklet .chicklet-content {
        top: auto;
        right: 25px;
        bottom: 25px;
        left: 25px;
        width: auto;
        height: 15px;
        transition: height .5s ease-in-out !important;
        overflow: hidden
    }

    .block.related-content .chicklet .chicklet-content .content-wrapper {
        position: relative;
        height: 350px
    }

    .block.related-content .chicklet:hover .chicklet-content, .block.related-content .chicklet:active .chicklet-content, .block.related-content .chicklet:focus .chicklet-content {
        height: 350px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content.rounded-block-above > div.row > .chicklet, .block.related-content.rounded-block-below > div.row > .chicklet {
        height: 450px
    }

    .block.related-content.rounded-block-above.rounded-block-below > div.row > .chicklet {
        height: 500px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-image p.h2 {
        bottom: 75px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-content {
        bottom: 75px
    }
}

div.light-orange-accents, div.light-orange-accents * {
     border-color: #63656A;
}

.block.news-content .news-header .sidebar .sidebar-outline, .block.news-content .news-body .sidebar .sidebar-outline {
    height: 100%;
    border-left: 1px solid;
    border-color: inherit;
    padding-left: 30px
}

.block.news-content .news-header .sidebar .sidebar-outline > div:not(:last-child), .block.news-content .news-body .sidebar .sidebar-outline > div:not(:last-child) {
    margin-bottom: 30px
}

.block.news-content .news-header h1 {
    font-size: 30px;
    line-height: 35px
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-header h1 {
        font-size: 36px;
        line-height: 40px
    }
}

.block.news-content .news-header .content .head {
    margin-bottom: 20px
}

.block.news-content .news-body {
    margin-bottom: 20px
}

.block.news-content .news-body .content > .body p {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 20px
}

.block.news-content .news-body .content > .body p.caption, .block.news-content .news-body .content > .body p.credit {
    font-size: .9em;
    font-style: italic;
    line-height: 1.42857
}

.block.news-content .news-body .content > .body p.caption {
    margin-bottom: 5px
}

.block.news-content .news-body .content > .body p.credit {
    margin: 0
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-body .content > .body .wrap-image {
        width: 50%;
        margin: 0
    }

    .block.news-content .news-body .content > .body .wrap-image .image {
        margin: 0
    }

    .block.news-content .news-body .content > .body .wrap-image.wrap-image-right {
        float: right;
        padding: 9px 0 0 30px
    }

    .block.news-content .news-body .content > .body .wrap-image.wrap-image-left {
        float: left;
        padding: 9px 30px 0 0
    }
}

.block.news-content .news-body .content > .body.drop-cap > p:first-of-type {
    min-height: 52px
}

.block.news-content .news-body .content > .body.drop-cap > p:first-of-type:first-letter {
    float: left;
    font-size: 3em;
    font-weight: bold;
    padding: 2px;
    margin-top: 2px;
    margin-right: 6px;
    line-height: .67em
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-body .content > .body.drop-cap > p:first-of-type:first-letter {
        font-size: 4.6em;
        margin-right: 10px
    }
}

.block.news-content .news-body .content > .body .block {
    margin: 30px 0;
    padding: 0
}

.block.news-content .news-body .content > .body .block:last-child {
    margin-bottom: 0
}

.block.news-content .news-body .content > .body .block.image {
    padding: 0
}

.block.news-content .news-body .content > .body .block.image .image-wrapper {
    margin-bottom: 30px
}

.block.news-content .news-body .content > .body .block.image .image-wrapper div {
    margin-bottom: 10px
}

.block.news-content .news-body .content > .body .block.marketo > .row {
    height: auto
}

.block.news-content .news-body .content > .body .block.marketo .marketo-description h2 {
    background: none;
    padding-left: 0
}

.block.news-content .news-body .content > .body .block.marketo .marketo-content {
    background: transparent;
    color: #3d3d3d;
    margin: 0;
    padding: 0;
    min-height: 100px
}

@media only screen and (min-width: 992px) {
    .block.news-content .news-body .content > .body .block.marketo .marketo-content {
        padding: 0 65px 0 0
    }
}

.block.news-content .news-body .content > .body .block.marketo .marketo-snippet form button {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 42px;
    height: 44px;
    color: #fff;
    background-color: #cf0722;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.news-content .news-body .content > .body .block.marketo .marketo-snippet form button:hover, .block.news-content .news-body .content > .body .block.marketo .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo .marketo-snippet form button:focus, .block.news-content .news-body .content > .body .block.marketo .marketo-snippet form button.active {
    color: #fff;
    background-color: #ee0e2c
}

.block.news-content .news-body .content > .body .block.marketo .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo .marketo-snippet form button:focus {
    box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25);
    outline: none;
    border-color: #8d8d8d
}

.block.news-content .news-body .content > .body .block.marketo div.light-orange-accents .marketo-snippet form button {
    color: #2d2d2d;
    background-color: #CCCCCC
}

.block.news-content .news-body .content > .body .block.marketo div.light-orange-accents .marketo-snippet form button:hover, .block.news-content .news-body .content > .body .block.marketo div.light-orange-accents .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo div.light-orange-accents .marketo-snippet form button:focus, .block.news-content .news-body .content > .body .block.marketo div.light-orange-accents .marketo-snippet form button.active {
    background-color: #CCCCCC
}

.block.news-content .news-body .content > .body .block.marketo div.dark-orange-accents .marketo-snippet form button {
    color: #2d2d2d;
    background-color: #CCCCCC
}

.block.news-content .news-body .content > .body .block.marketo div.dark-orange-accents .marketo-snippet form button:hover, .block.news-content .news-body .content > .body .block.marketo div.dark-orange-accents .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo div.dark-orange-accents .marketo-snippet form button:focus, .block.news-content .news-body .content > .body .block.marketo div.dark-orange-accents .marketo-snippet form button.active {
    background-color: #CCCCCC
}

.block.news-content .news-body .content > .body .block.marketo div.red-accents .marketo-snippet form button {
    color: #fff;
    background-color: #cf0722
}

.block.news-content .news-body .content > .body .block.marketo div.red-accents .marketo-snippet form button:hover, .block.news-content .news-body .content > .body .block.marketo div.red-accents .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo div.red-accents .marketo-snippet form button:focus, .block.news-content .news-body .content > .body .block.marketo div.red-accents .marketo-snippet form button.active {
    background-color: #f40828
}

.block.news-content .news-body .content > .body .block.marketo div.rubine-accents .marketo-snippet form button {
    color: #fff;
    background-color: #ce0058
}

.block.news-content .news-body .content > .body .block.marketo div.rubine-accents .marketo-snippet form button:hover, .block.news-content .news-body .content > .body .block.marketo div.rubine-accents .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo div.rubine-accents .marketo-snippet form button:focus, .block.news-content .news-body .content > .body .block.marketo div.rubine-accents .marketo-snippet form button.active {
    background-color: #f40068
}

.block.news-content .news-body .content > .body .block.marketo div.berry-accents .marketo-snippet form button {
    color: #fff;
    background-color: #a6192e
}

.block.news-content .news-body .content > .body .block.marketo div.berry-accents .marketo-snippet form button:hover, .block.news-content .news-body .content > .body .block.marketo div.berry-accents .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo div.berry-accents .marketo-snippet form button:focus, .block.news-content .news-body .content > .body .block.marketo div.berry-accents .marketo-snippet form button.active {
    background-color: #c71e37
}

.block.news-content .news-body .content > .body .block.marketo div.chocolate-accents .marketo-snippet form button {
    color: #fff;
    background-color: #6b3529
}

.block.news-content .news-body .content > .body .block.marketo div.chocolate-accents .marketo-snippet form button:hover, .block.news-content .news-body .content > .body .block.marketo div.chocolate-accents .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo div.chocolate-accents .marketo-snippet form button:focus, .block.news-content .news-body .content > .body .block.marketo div.chocolate-accents .marketo-snippet form button.active {
    background-color: #874334
}

.block.news-content .news-body .content > .body .block.marketo div.grey-accents .marketo-snippet form button {
    color: #3d3d3d;
    background-color: #f4f4f4
}

.block.news-content .news-body .content > .body .block.marketo div.grey-accents .marketo-snippet form button:hover, .block.news-content .news-body .content > .body .block.marketo div.grey-accents .marketo-snippet form button:active, .block.news-content .news-body .content > .body .block.marketo div.grey-accents .marketo-snippet form button:focus, .block.news-content .news-body .content > .body .block.marketo div.grey-accents .marketo-snippet form button.active {
    background-color: #fff
}

.block.news-content .news-body .content > .body .block.marketo .marketo-snippet form label {
    color: #3d3d3d
}

.block.news-content .news-body .content > .body .block.marketo .marketo-legal a {
    color: #cf0722
}

.block.news-content .news-body .content > .body .full-width-news-image img {
    width: 100vw;
    max-width: none;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.block.news-content .row.news-extras .divider {
    margin-bottom: 15px
}

.block.news-content .author .title, .block.news-content .sharing .title {
    text-transform: uppercase
}

.block.news-content .author .title {
    margin-bottom: 5px
}

.block.news-content .author .name, .block.news-content .author .affiliation {
    margin-bottom: 0
}

.block.news-content .author .name {
    font-weight: bold
}

.block.news-content .author .affiliation {
    font-style: italic
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] {
    padding: 0
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_]:not(:first-child) {
    padding-left: 22px
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_]:not(:last-child) {
    padding-right: 22px;
    border-right-width: 1px;
    border-right-style: solid
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] span {
    background-color: transparent !important
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] span path {
    fill: currentColor
}

.block.news-content:not(.rounded-top) .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #8d8d8d
}

.block.news-content:not(.rounded-top) div.light-orange-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content:not(.rounded-top) div.dark-orange-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content:not(.rounded-top) div.red-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #cf0722
}

.block.news-content:not(.rounded-top) div.rubine-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #ce0058
}

.block.news-content:not(.rounded-top) div.berry-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #a6192e
}

.block.news-content:not(.rounded-top) div.chocolate-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #6b3529
}

.block.news-content:not(.rounded-top) div.grey-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #f4f4f4
}

.block.news-content #share-open {
    color: inherit;
    text-decoration: underline
}

.block.news-content #share-mobile {
    position: absolute;
    top: -20px;
    left: 15px;
    right: 15px;
    z-index: 3;
    padding: 15px;
    color: #3d3d3d;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .175)
}

.block.news-content #share-mobile .addtoany_list {
    display: flex
}

.block.news-content #share-mobile .addtoany_list a[class^=a2a_button_] {
    flex: 1 1;
    text-align: center;
    padding: 0
}

.block.news-content #share-close {
    color: #fff;
    background-color: #8d8d8d
}

.block.news-content div.light-orange-accents #share-close {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.dark-orange-accents #share-close {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.red-accents #share-close {
    background-color: #cf0722;
    color: #fff
}

.block.news-content div.rubine-accents #share-close {
    background-color: #ce0058;
    color: #fff
}

.block.news-content div.berry-accents #share-close {
    background-color: #a6192e;
    color: #fff
}

.block.news-content div.chocolate-accents #share-close {
    background-color: #6b3529;
    color: #fff
}

.block.news-content div.grey-accents #share-close {
    background-color: #f4f4f4;
    color: #3d3d3d
}

.block.news-content #share-close:hover, .block.news-content #share-close:active, .block.news-content #share-close:focus {
    color: #fff;
    background-color: #a0a0a0
}

.block.news-content div.light-orange-accents #share-close:hover, .block.news-content div.light-orange-accents #share-close:active, .block.news-content div.light-orange-accents #share-close:focus {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.dark-orange-accents #share-close:hover, .block.news-content div.dark-orange-accents #share-close:active, .block.news-content div.dark-orange-accents #share-close:focus {
    background-color: #ff9245;
    color: #2d2d2d
}

.block.news-content div.red-accents #share-close:hover, .block.news-content div.red-accents #share-close:active, .block.news-content div.red-accents #share-close:focus {
    background-color: #f40828;
    color: #fff
}

.block.news-content div.rubine-accents #share-close:hover, .block.news-content div.rubine-accents #share-close:active, .block.news-content div.rubine-accents #share-close:focus {
    background-color: #f40068;
    color: #fff
}

.block.news-content div.berry-accents #share-close:hover, .block.news-content div.berry-accents #share-close:active, .block.news-content div.berry-accents #share-close:focus {
    background-color: #c71e37;
    color: #fff
}

.block.news-content div.chocolate-accents #share-close:hover, .block.news-content div.chocolate-accents #share-close:active, .block.news-content div.chocolate-accents #share-close:focus {
    background-color: #874334;
    color: #fff
}

.block.news-content div.grey-accents #share-close:hover, .block.news-content div.grey-accents #share-close:active, .block.news-content div.grey-accents #share-close:focus {
    background-color: #fff;
    color: #3d3d3d
}

.block.news-content #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #8d8d8d
}

.block.news-content div.light-orange-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content div.dark-orange-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content div.red-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #cf0722
}

.block.news-content div.rubine-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #ce0058
}

.block.news-content div.berry-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #a6192e
}

.block.news-content div.chocolate-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #6b3529
}

.block.news-content div.grey-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #f4f4f4
}

.block.news-content .related .title, .block.news-content .tags .title {
    text-transform: uppercase;
    margin-bottom: 15px
}

.block.news-content .related-divider, .block.news-content .tag-divider {
    margin-bottom: 30px
}

.block.news-content .related-divider hr, .block.news-content .tag-divider hr {
    border-width: 2px
}

.block.news-content .related .news-teaser .item-info {
    margin-top: 0
}

.block.news-content .related .news-teaser .title-border {
    margin-top: 0
}

@media only screen and (max-width: 767px) {
    .block.news-content .related .news-teaser:not(:first-child) {
        margin-top: 30px
    }
}

.block.news-content .tags {
    position: relative;
    overflow-x: hidden
}

@media only screen and (max-width: 991px) {
    .block.news-content .tags {
        margin-bottom: 35px
    }
}

.block.news-content .tags ul {
    margin: 0;
    padding: 0
}

.block.news-content .tags ul li {
    color: #cf0722;
    list-style-type: none;
    float: left;
    margin: 0;
    padding-right: 16px
}

.block.news-content .tags ul li:before {
    content: "|";
    color: #3d3d3d;
    display: inline-block;
    font-size: 100%;
    margin-left: -12px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 4px
}

.block.news-content .boilerplate-container {
    border-top-width: 5px;
    border-top-style: solid;
    background-color: #f4f4f4;
    padding: 30px 15px 40px 15px;
    margin-top: 50px;
    margin-left: -15px;
    margin-right: -15px
}

.block.news-content .boilerplate-container .boilerplate-text {
    line-height: 1.8
}

.block.news-content .boilerplate-container .btn-wrapper {
    color: #cf0722
}

.block.news-content .text-only-layout .news-body .content > .body .full-width-news-image img {
    margin-left: calc(75% + 7.5px)
}

.news-hero-section .news-hero-details {
    margin-top: 15px
}

.news-hero-section .news-hero-details .image-information {
    display: flex
}

.news-hero-section .news-hero-details .image-information span:nth-child(2) {
    margin-left: 10px
}

.news-hero-section .news-hero-details .caption, .news-hero-section .news-hero-details .credit {
    font-size: .9em
}

.news-hero-section .news-hero-details .credit {
    display: flex;
    align-items: center
}

.news-hero-section .news-hero-details .credit .ucws-icon {
    margin-right: 5px;
    color: silver;
    font-size: inherit
}

.news-header-image-below {
    margin-bottom: 50px
}

.news-header-image-below .news-header {
    margin-bottom: 35px
}

.news-header-image-below .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 991px) {
    .news-header-image-below .sidebar {
        margin-top: 35px
    }
}

.news-header-image-above .news-hero-details {
    margin-bottom: 50px
}

.news-header-image-above .news-hero-details .caption-section {
    display: flex;
    flex-direction: column
}

.news-header-image-above .news-hero-details .caption-section .news-hero-accent-divider {
    order: 2;
    border-top: 10px solid;
    border-top-color: inherit;
    margin-top: 15px;
    margin-bottom: 0
}

.news-header-image-above .news-hero-details .caption-section .image-information {
    order: 1
}

@media only screen and (min-width: 768px) {
    .news-header-image-above .news-hero-details {
        margin-top: 0
    }

    .news-header-image-above .news-hero-details .caption-section .news-hero-accent-divider {
        order: 1;
        margin-top: 0;
        margin-bottom: 15px
    }

    .news-header-image-above .news-hero-details .caption-section .image-information {
        order: 2
    }
}

.news-header-image-above .news-header {
    margin-bottom: 50px
}

.news-header-image-above .news-header .content {
    margin-bottom: 35px
}

.news-header-image-above-container .col-image {
    overflow: hidden
}

.news-header-image-above-container .col-image img {
    display: block;
    position: relative
}

@media only screen and (max-width: 1499px) {
    .news-header-image-above-container .col-image img {
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

@media only screen and (min-width: 1500px) {
    .news-header-image-above-container .col-image img {
        width: 100%;
        height: auto
    }
}

body:not(.user-logged-in) .news-header-image-above-container {
    margin-top: -50px
}

@media only screen and (min-width: 992px) {
    body:not(.user-logged-in) .news-header-image-above-container {
        margin-top: -50px
    }
}

.news-header-side-by-side .news-header {
    margin-bottom: 50px
}

.news-header-side-by-side .news-header .content {
    margin-bottom: 35px
}

.news-header-side-by-side .news-hero-details {
    margin-top: 0
}

.news-header-side-by-side .news-hero-details .image-information {
    margin: 15px 0
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .news-hero-details .news-hero-caption-divider {
        border-bottom: 10px solid;
        border-bottom-color: inherit;
        margin-bottom: 50px
    }
}

.news-header-side-by-side .side-by-side-divider-container {
    margin-top: 50px;
    margin-bottom: 35px
}

.news-header-side-by-side .side-by-side-divider-container .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .side-by-side-divider-container {
        display: none
    }
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .image-col {
        margin-top: 35px
    }
}

.news-header-text-only {
    margin-bottom: 50px
}

.news-header-text-only .text-only-divider {
    margin-top: 35px
}

.news-header-text-only .text-only-divider .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 991px) {
    .news-header-text-only .sidebar {
        margin-top: 35px
    }
}

.block.news-banner .col-image {
    overflow: hidden
}

.block.news-banner .col-image img {
    width: 100%;
    display: block;
    position: relative;
    height: auto
}

.block.news-banner .col-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1
}

.block.news-banner .col-text .cta-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 50px 15px
}

@media only screen and (min-width: 992px) {
    .block.news-banner .col-text .cta-wrapper {
        padding: 50px 15px
    }
}

.block.news-banner .col-text .cta-wrapper .cta-content {
    max-width: 550px
}

.block.news-banner .col-text .cta-wrapper .cta-content > :first-child {
    margin-top: 0
}

.block.news-banner .col-text .cta-wrapper .cta-content > :last-child {
    margin-bottom: 0
}

.block.news-banner .col-text .cta-wrapper .cta-content.light {
    color: #fff
}

.block.news-banner .col-text .cta-wrapper .cta-content.light:not(.no-shadow) {
    text-shadow: 0 0 15px #000
}

.block.news-banner .col-text .cta-wrapper .cta-content.light:not(.no-shadow) .btn {
    box-shadow: 0 0 15px rgba(0, 0, 0, .25);
    text-shadow: none
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark {
    color: #3d3d3d
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark:not(.no-shadow) {
    text-shadow: 0 0 15px #fff
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark:not(.no-shadow) .btn {
    box-shadow: 0 0 15px rgba(255, 255, 255, .25);
    text-shadow: none
}

@media only screen and (max-width: 767px) {
    .block.news-banner .col-text .cta-wrapper .cta-content {
        text-align: left
    }
}

.block.news-banner.last-item {
    margin-bottom: 0
}

.block.news-banner > .row {
    position: relative
}

@media only screen and (max-width: 767px) {
    .block.news-banner > .row .col-text .cta-wrapper {
        justify-content: flex-start;
        align-items: flex-end
    }
}

@media only screen and (min-width: 768px) {
    .block.news-banner > .row.vmiddle .col-text .cta-wrapper {
        align-items: center
    }

    .block.news-banner > .row.hleft .col-text .cta-wrapper {
        justify-content: flex-start
    }
}

.block.news-banner:not(.no-grad).light > .row.hleft:before, .block.news-banner:not(.no-grad).dark > .row.hleft:before {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

@media only screen and (max-width: 767px) {
    .block.news-banner:not(.no-grad).light > .row:before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)
    }

    .block.news-banner:not(.no-grad).dark > .row:before {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.8) 100%)
    }
}

@media only screen and (min-width: 768px) {
    .block.news-banner:not(.no-grad).light > .row.hleft:before {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%)
    }

    .block.news-banner:not(.no-grad).dark > .row.hleft:before {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%)
    }
}

.news-teaser {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px
}

.news-teaser .image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    width: 90px;
    height: 140px
}

.news-teaser .image-wrapper img {
    width: auto;
    height: 140px
}

.news-teaser .item-info {
    display: flex;
    flex-direction: column;
    width: 100%
}

.news-teaser .item-info .title-spacer {
    margin: 10px 0 32px
}

.news-teaser .item-info .title-border {
    margin: 10px 0 20px;
    width: 30px;
    border-top-width: 2px;
    border-top-style: solid
}

.news-teaser .item-info h4, .news-teaser .item-info h5 {
    margin: 0 0 10px
}

.news-teaser .item-info h4 {
    flex-grow: 1
}

.news-teaser .item-info p:last-child {
    margin-bottom: 0
}

.news-teaser:not(:first-child) > .image-wrapper, .news-teaser:not(:first-child) > .image-wrapper + .item-info {
    margin-top: 10px
}

.news-teaser:not(:last-child) > .image-wrapper, .news-teaser:not(:last-child) > .image-wrapper + .item-info {
    margin-bottom: 7px
}



@media screen and (min-width: 480px) {
    .mtop {
        margin-top: -150px;
    }
}

.mtop {
    margin-top: 40px;
}
.block.related-content > .row {
    background-color: rgba(255, 255, 255, .5)
}

.block.related-content.rounded-block-above > div.row:first-child {
    padding-top: 0 !important
}

.block.related-content.rounded-block-below > div.row:last-child {
    padding-bottom: 0 !important
}

.block.related-content .chicklet {
    padding: 0
}

.block.related-content .chicklet .chicklet-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px
}

.block.related-content .chicklet .chicklet-image p.h2 {
    display: none
}

.block.related-content .chicklet .chicklet-content {
    position: relative;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer
}

.block.related-content .chicklet .chicklet-content .content-wrapper {
    height: 100%;
    padding: 25px
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :first-child {
    margin-top: 0
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :last-child {
    margin-bottom: 5px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child) {
    margin-bottom: 20px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child):after {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: inherit
}

@media only screen and (max-width: 767px) {
    .block.related-content .chicklet .chicklet-content {
        margin-top: -8px
    }

    .block.related-content .chicklet:not(:last-child) .chicklet-content {
        margin-bottom: -8px
    }
}

@media only screen and (max-width: 767px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-image {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 0;
        padding-bottom: 20px
    }
}

@media only screen and (min-width: 768px) {
    .block.related-content .chicklet {
        height: 400px
    }

    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }

    .block.related-content .chicklet .chicklet-image {
        height: 100%
    }

    .block.related-content .chicklet .chicklet-content {
        z-index: 2
    }

    .block.related-content .chicklet .chicklet-content p.link {
        position: absolute;
        left: 25px;
        bottom: 25px
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        width: 50%;
        border-radius: 0
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-image {
        left: auto
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-content {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-image {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-content {
        left: auto
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) {
        height: 420px
    }

    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-content {
        margin-top: 20px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1), .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content .chicklet .chicklet-image img {
        cursor: pointer
    }

    .block.related-content .chicklet .chicklet-image::before {
        content: "";
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%)
    }

    .block.related-content .chicklet .chicklet-image p.h2 {
        display: block;
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;
        margin: 0 0 40px;
        z-index: 2;
        color: #fff;
        text-align: center;
        text-shadow: 0 0 10px #000
    }

    .block.related-content .chicklet .chicklet-content {
        top: auto;
        right: 25px;
        bottom: 25px;
        left: 25px;
        width: auto;
        height: 15px;
        transition: height .5s ease-in-out !important;
        overflow: hidden
    }

    .block.related-content .chicklet .chicklet-content .content-wrapper {
        position: relative;
        height: 350px
    }

    .block.related-content .chicklet:hover .chicklet-content, .block.related-content .chicklet:active .chicklet-content, .block.related-content .chicklet:focus .chicklet-content {
        height: 350px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content.rounded-block-above > div.row > .chicklet, .block.related-content.rounded-block-below > div.row > .chicklet {
        height: 450px
    }

    .block.related-content.rounded-block-above.rounded-block-below > div.row > .chicklet {
        height: 500px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-image p.h2 {
        bottom: 75px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-content {
        bottom: 75px
    }
}

.block.related-content > .row {
    background-color: rgba(255, 255, 255, .5)
}

.block.related-content.rounded-block-above > div.row:first-child {
    padding-top: 0 !important
}

.block.related-content.rounded-block-below > div.row:last-child {
    padding-bottom: 0 !important
}

.block.related-content .chicklet {
    padding: 0
}

.block.related-content .chicklet .chicklet-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px
}

.block.related-content .chicklet .chicklet-image p.h2 {
    display: none
}

.block.related-content .chicklet .chicklet-content {
    position: relative;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer
}

.block.related-content .chicklet .chicklet-content .content-wrapper {
    height: 100%;
    padding: 25px
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :first-child {
    margin-top: 0
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :last-child {
    margin-bottom: 5px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child) {
    margin-bottom: 20px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child):after {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: inherit
}

@media only screen and (max-width: 767px) {
    .block.related-content .chicklet .chicklet-content {
        margin-top: -8px
    }

    .block.related-content .chicklet:not(:last-child) .chicklet-content {
        margin-bottom: -8px
    }
}

@media only screen and (max-width: 767px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-image {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 0;
        padding-bottom: 20px
    }
}

@media only screen and (min-width: 768px) {
    .block.related-content .chicklet {
        height: 400px
    }

    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }

    .block.related-content .chicklet .chicklet-image {
        height: 100%
    }

    .block.related-content .chicklet .chicklet-content {
        z-index: 2
    }

    .block.related-content .chicklet .chicklet-content p.link {
        position: absolute;
        left: 25px;
        bottom: 25px
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        width: 50%;
        border-radius: 0
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-image {
        left: auto
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-content {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-image {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-content {
        left: auto
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) {
        height: 420px
    }

    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-content {
        margin-top: 20px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1), .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content .chicklet .chicklet-image img {
        cursor: pointer
    }

    .block.related-content .chicklet .chicklet-image::before {
        content: "";
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%)
    }

    .block.related-content .chicklet .chicklet-image p.h2 {
        display: block;
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;
        margin: 0 0 40px;
        z-index: 2;
        color: #fff;
        text-align: center;
        text-shadow: 0 0 10px #000
    }

    .block.related-content .chicklet .chicklet-content {
        top: auto;
        right: 25px;
        bottom: 25px;
        left: 25px;
        width: auto;
        height: 15px;
        transition: height .5s ease-in-out !important;
        overflow: hidden
    }

    .block.related-content .chicklet .chicklet-content .content-wrapper {
        position: relative;
        height: 350px
    }

    .block.related-content .chicklet:hover .chicklet-content, .block.related-content .chicklet:active .chicklet-content, .block.related-content .chicklet:focus .chicklet-content {
        height: 350px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content.rounded-block-above > div.row > .chicklet, .block.related-content.rounded-block-below > div.row > .chicklet {
        height: 450px
    }

    .block.related-content.rounded-block-above.rounded-block-below > div.row > .chicklet {
        height: 500px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-image p.h2 {
        bottom: 75px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-content {
        bottom: 75px
    }
}


div.light-orange-accents,
div.light-orange-accents * {
    border-color: #63656A;
}

.block.news-content .news-header .sidebar .sidebar-outline,
.block.news-content .news-body .sidebar .sidebar-outline {
    height: 100%;
    border-left: 1px solid;
    border-color: inherit;
    padding-left: 30px
}

.block.news-content .news-header .sidebar .sidebar-outline>div:not(:last-child),
.block.news-content .news-body .sidebar .sidebar-outline>div:not(:last-child) {
    margin-bottom: 30px
}

.block.news-content .news-header h1 {
    font-size: 30px;
    line-height: 35px
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-header h1 {
        font-size: 36px;
        line-height: 40px
    }
}

.block.news-content .news-header .content .head {
    margin-bottom: 20px
}

.block.news-content .news-body {
    margin-bottom: 20px
}

.block.news-content .news-body .content>.body p {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 20px
}

.block.news-content .news-body .content>.body p.caption,
.block.news-content .news-body .content>.body p.credit {
    font-size: .9em;
    font-style: italic;
    line-height: 1.42857
}

.block.news-content .news-body .content>.body p.caption {
    margin-bottom: 5px
}

.block.news-content .news-body .content>.body p.credit {
    margin: 0
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-body .content>.body .wrap-image {
        width: 50%;
        margin: 0
    }

    .block.news-content .news-body .content>.body .wrap-image .image {
        margin: 0
    }

    .block.news-content .news-body .content>.body .wrap-image.wrap-image-right {
        float: right;
        padding: 9px 0 0 30px
    }

    .block.news-content .news-body .content>.body .wrap-image.wrap-image-left {
        float: left;
        padding: 9px 30px 0 0
    }
}

.block.news-content .news-body .content>.body.drop-cap>p:first-of-type {
    min-height: 52px
}

.block.news-content .news-body .content>.body.drop-cap>p:first-of-type:first-letter {
    float: left;
    font-size: 3em;
    font-weight: bold;
    padding: 2px;
    margin-top: 2px;
    margin-right: 6px;
    line-height: .67em
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-body .content>.body.drop-cap>p:first-of-type:first-letter {
        font-size: 4.6em;
        margin-right: 10px
    }
}

.block.news-content .news-body .content>.body .block {
    margin: 30px 0;
    padding: 0
}

.block.news-content .news-body .content>.body .block:last-child {
    margin-bottom: 0
}

.block.news-content .news-body .content>.body .block.image {
    padding: 0
}

.block.news-content .news-body .content>.body .block.image .image-wrapper {
    margin-bottom: 30px
}

.block.news-content .news-body .content>.body .block.image .image-wrapper div {
    margin-bottom: 10px
}

.block.news-content .news-body .content>.body .block.marketo>.row {
    height: auto
}

.block.news-content .news-body .content>.body .block.marketo .marketo-description h2 {
    background: none;
    padding-left: 0
}

.block.news-content .news-body .content>.body .block.marketo .marketo-content {
    background: transparent;
    color: #3d3d3d;
    margin: 0;
    padding: 0;
    min-height: 100px
}

@media only screen and (min-width: 992px) {
    .block.news-content .news-body .content>.body .block.marketo .marketo-content {
        padding: 0 65px 0 0
    }
}

.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 42px;
    height: 44px;
    color: #fff;
    background-color: #cf0722;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button.active {
    color: #fff;
    background-color: #ee0e2c
}

.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:focus {
    box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25);
    outline: none;
    border-color: #8d8d8d
}

.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button {
    color: #2d2d2d;
    background-color: #CCCCCC
}

.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button.active {
    background-color: #CCCCCC
}

.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button {
    color: #2d2d2d;
    background-color: #CCCCCC
}

.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button.active {
    background-color: #CCCCCC
}

.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button {
    color: #fff;
    background-color: #cf0722
}

.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button.active {
    background-color: #f40828
}

.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button {
    color: #fff;
    background-color: #ce0058
}

.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button.active {
    background-color: #f40068
}

.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button {
    color: #fff;
    background-color: #a6192e
}

.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button.active {
    background-color: #c71e37
}

.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button {
    color: #fff;
    background-color: #6b3529
}

.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button.active {
    background-color: #874334
}

.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button {
    color: #3d3d3d;
    background-color: #f4f4f4
}

.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button.active {
    background-color: #fff
}

.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form label {
    color: #3d3d3d
}

.block.news-content .news-body .content>.body .block.marketo .marketo-legal a {
    color: #cf0722
}

.block.news-content .news-body .content>.body .full-width-news-image img {
    width: 100vw;
    max-width: none;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.block.news-content .row.news-extras .divider {
    margin-bottom: 15px
}

.block.news-content .author .title,
.block.news-content .sharing .title {
    text-transform: uppercase
}

.block.news-content .author .title {
    margin-bottom: 5px
}

.block.news-content .author .name,
.block.news-content .author .affiliation {
    margin-bottom: 0
}

.block.news-content .author .name {
    font-weight: bold
}

.block.news-content .author .affiliation {
    font-style: italic
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] {
    padding: 0
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_]:not(:first-child) {
    padding-left: 22px
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_]:not(:last-child) {
    padding-right: 22px;
    border-right-width: 1px;
    border-right-style: solid
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] span {
    background-color: transparent !important
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] span path {
    fill: currentColor
}

.block.news-content:not(.rounded-top) .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #8d8d8d
}

.block.news-content:not(.rounded-top) div.light-orange-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content:not(.rounded-top) div.dark-orange-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content:not(.rounded-top) div.red-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #cf0722
}

.block.news-content:not(.rounded-top) div.rubine-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #ce0058
}

.block.news-content:not(.rounded-top) div.berry-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #a6192e
}

.block.news-content:not(.rounded-top) div.chocolate-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #6b3529
}

.block.news-content:not(.rounded-top) div.grey-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #f4f4f4
}

.block.news-content #share-open {
    color: inherit;
    text-decoration: underline
}

.block.news-content #share-mobile {
    position: absolute;
    top: -20px;
    left: 15px;
    right: 15px;
    z-index: 3;
    padding: 15px;
    color: #3d3d3d;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .175)
}

.block.news-content #share-mobile .addtoany_list {
    display: flex
}

.block.news-content #share-mobile .addtoany_list a[class^=a2a_button_] {
    flex: 1 1;
    text-align: center;
    padding: 0
}

.block.news-content #share-close {
    color: #fff;
    background-color: #8d8d8d
}

.block.news-content div.light-orange-accents #share-close {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.dark-orange-accents #share-close {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.red-accents #share-close {
    background-color: #cf0722;
    color: #fff
}

.block.news-content div.rubine-accents #share-close {
    background-color: #ce0058;
    color: #fff
}

.block.news-content div.berry-accents #share-close {
    background-color: #a6192e;
    color: #fff
}

.block.news-content div.chocolate-accents #share-close {
    background-color: #6b3529;
    color: #fff
}

.block.news-content div.grey-accents #share-close {
    background-color: #f4f4f4;
    color: #3d3d3d
}

.block.news-content #share-close:hover,
.block.news-content #share-close:active,
.block.news-content #share-close:focus {
    color: #fff;
    background-color: #a0a0a0
}

.block.news-content div.light-orange-accents #share-close:hover,
.block.news-content div.light-orange-accents #share-close:active,
.block.news-content div.light-orange-accents #share-close:focus {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.dark-orange-accents #share-close:hover,
.block.news-content div.dark-orange-accents #share-close:active,
.block.news-content div.dark-orange-accents #share-close:focus {
    background-color: #ff9245;
    color: #2d2d2d
}

.block.news-content div.red-accents #share-close:hover,
.block.news-content div.red-accents #share-close:active,
.block.news-content div.red-accents #share-close:focus {
    background-color: #f40828;
    color: #fff
}

.block.news-content div.rubine-accents #share-close:hover,
.block.news-content div.rubine-accents #share-close:active,
.block.news-content div.rubine-accents #share-close:focus {
    background-color: #f40068;
    color: #fff
}

.block.news-content div.berry-accents #share-close:hover,
.block.news-content div.berry-accents #share-close:active,
.block.news-content div.berry-accents #share-close:focus {
    background-color: #c71e37;
    color: #fff
}

.block.news-content div.chocolate-accents #share-close:hover,
.block.news-content div.chocolate-accents #share-close:active,
.block.news-content div.chocolate-accents #share-close:focus {
    background-color: #874334;
    color: #fff
}

.block.news-content div.grey-accents #share-close:hover,
.block.news-content div.grey-accents #share-close:active,
.block.news-content div.grey-accents #share-close:focus {
    background-color: #fff;
    color: #3d3d3d
}

.block.news-content #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #8d8d8d
}

.block.news-content div.light-orange-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content div.dark-orange-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content div.red-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #cf0722
}

.block.news-content div.rubine-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #ce0058
}

.block.news-content div.berry-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #a6192e
}

.block.news-content div.chocolate-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #6b3529
}

.block.news-content div.grey-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #f4f4f4
}

.block.news-content .related .title,
.block.news-content .tags .title {
    text-transform: uppercase;
    margin-bottom: 15px
}

.block.news-content .related-divider,
.block.news-content .tag-divider {
    margin-bottom: 30px
}

.block.news-content .related-divider hr,
.block.news-content .tag-divider hr {
    border-width: 2px
}

.block.news-content .related .news-teaser .item-info {
    margin-top: 0
}

.block.news-content .related .news-teaser .title-border {
    margin-top: 0
}

@media only screen and (max-width: 767px) {
    .block.news-content .related .news-teaser:not(:first-child) {
        margin-top: 30px
    }
}

.block.news-content .tags {
    position: relative;
    overflow-x: hidden
}

@media only screen and (max-width: 991px) {
    .block.news-content .tags {
        margin-bottom: 35px
    }
}

.block.news-content .tags ul {
    margin: 0;
    padding: 0
}

.block.news-content .tags ul li {
    color: #cf0722;
    list-style-type: none;
    float: left;
    margin: 0;
    padding-right: 16px
}

.block.news-content .tags ul li:before {
    content: "|";
    color: #3d3d3d;
    display: inline-block;
    font-size: 100%;
    margin-left: -12px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 4px
}

.block.news-content .boilerplate-container {
    border-top-width: 5px;
    border-top-style: solid;
    background-color: #f4f4f4;
    padding: 30px 15px 40px 15px;
    margin-top: 50px;
    margin-left: -15px;
    margin-right: -15px
}

.block.news-content .boilerplate-container .boilerplate-text {
    line-height: 1.8
}

.block.news-content .boilerplate-container .btn-wrapper {
    color: #cf0722
}

.block.news-content .text-only-layout .news-body .content>.body .full-width-news-image img {
    margin-left: calc(75% + 7.5px)
}

.news-hero-section .news-hero-details {
    margin-top: 15px
}

.news-hero-section .news-hero-details .image-information {
    display: flex
}

.news-hero-section .news-hero-details .image-information span:nth-child(2) {
    margin-left: 10px
}

.news-hero-section .news-hero-details .caption,
.news-hero-section .news-hero-details .credit {
    font-size: .9em
}

.news-hero-section .news-hero-details .credit {
    display: flex;
    align-items: center
}

.news-hero-section .news-hero-details .credit .ucws-icon {
    margin-right: 5px;
    color: silver;
    font-size: inherit
}

.news-header-image-below {
    margin-bottom: 50px
}

.news-header-image-below .news-header {
    margin-bottom: 35px
}

.news-header-image-below .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 991px) {
    .news-header-image-below .sidebar {
        margin-top: 35px
    }
}

.news-header-image-above .news-hero-details {
    margin-bottom: 50px
}

.news-header-image-above .news-hero-details .caption-section {
    display: flex;
    flex-direction: column
}

.news-header-image-above .news-hero-details .caption-section .news-hero-accent-divider {
    order: 2;
    border-top: 10px solid;
    border-top-color: inherit;
    margin-top: 15px;
    margin-bottom: 0
}

.news-header-image-above .news-hero-details .caption-section .image-information {
    order: 1
}

@media only screen and (min-width: 768px) {
    .news-header-image-above .news-hero-details {
        margin-top: 0
    }

    .news-header-image-above .news-hero-details .caption-section .news-hero-accent-divider {
        order: 1;
        margin-top: 0;
        margin-bottom: 15px
    }

    .news-header-image-above .news-hero-details .caption-section .image-information {
        order: 2
    }
}

.news-header-image-above .news-header {
    margin-bottom: 50px
}

.news-header-image-above .news-header .content {
    margin-bottom: 35px
}

.news-header-image-above-container .col-image {
    overflow: hidden
}

.news-header-image-above-container .col-image img {
    display: block;
    position: relative
}

@media only screen and (max-width: 1499px) {
    .news-header-image-above-container .col-image img {
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

@media only screen and (min-width: 1500px) {
    .news-header-image-above-container .col-image img {
        width: 100%;
        height: auto
    }
}

body:not(.user-logged-in) .news-header-image-above-container {
    margin-top: -50px
}

@media only screen and (min-width: 992px) {
    body:not(.user-logged-in) .news-header-image-above-container {
        margin-top: -50px
    }
}

.news-header-side-by-side .news-header {
    margin-bottom: 50px
}

.news-header-side-by-side .news-header .content {
    margin-bottom: 35px
}

.news-header-side-by-side .news-hero-details {
    margin-top: 0
}

.news-header-side-by-side .news-hero-details .image-information {
    margin: 15px 0
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .news-hero-details .news-hero-caption-divider {
        border-bottom: 10px solid;
        border-bottom-color: inherit;
        margin-bottom: 50px
    }
}

.news-header-side-by-side .side-by-side-divider-container {
    margin-top: 50px;
    margin-bottom: 35px
}

.news-header-side-by-side .side-by-side-divider-container .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .side-by-side-divider-container {
        display: none
    }
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .image-col {
        margin-top: 35px
    }
}

.news-header-text-only {
    margin-bottom: 50px
}

.news-header-text-only .text-only-divider {
    margin-top: 35px
}

.news-header-text-only .text-only-divider .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 991px) {
    .news-header-text-only .sidebar {
        margin-top: 35px
    }
}

.block.news-banner .col-image {
    overflow: hidden
}

.block.news-banner .col-image img {
    width: 100%;
    display: block;
    position: relative;
    height: auto
}

.block.news-banner .col-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1
}

.block.news-banner .col-text .cta-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 50px 15px
}

@media only screen and (min-width: 992px) {
    .block.news-banner .col-text .cta-wrapper {
        padding: 50px 15px
    }
}

.block.news-banner .col-text .cta-wrapper .cta-content {
    max-width: 550px
}

.block.news-banner .col-text .cta-wrapper .cta-content> :first-child {
    margin-top: 0
}

.block.news-banner .col-text .cta-wrapper .cta-content> :last-child {
    margin-bottom: 0
}

.block.news-banner .col-text .cta-wrapper .cta-content.light {
    color: #fff
}

.block.news-banner .col-text .cta-wrapper .cta-content.light:not(.no-shadow) {
    text-shadow: 0 0 15px #000
}

.block.news-banner .col-text .cta-wrapper .cta-content.light:not(.no-shadow) .btn {
    box-shadow: 0 0 15px rgba(0, 0, 0, .25);
    text-shadow: none
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark {
    color: #3d3d3d
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark:not(.no-shadow) {
    text-shadow: 0 0 15px #fff
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark:not(.no-shadow) .btn {
    box-shadow: 0 0 15px rgba(255, 255, 255, .25);
    text-shadow: none
}

@media only screen and (max-width: 767px) {
    .block.news-banner .col-text .cta-wrapper .cta-content {
        text-align: left
    }
}

.block.news-banner.last-item {
    margin-bottom: 0
}

.block.news-banner>.row {
    position: relative
}

@media only screen and (max-width: 767px) {
    .block.news-banner>.row .col-text .cta-wrapper {
        justify-content: flex-start;
        align-items: flex-end
    }
}

@media only screen and (min-width: 768px) {
    .block.news-banner>.row.vmiddle .col-text .cta-wrapper {
        align-items: center
    }

    .block.news-banner>.row.hleft .col-text .cta-wrapper {
        justify-content: flex-start
    }
}

.block.news-banner:not(.no-grad).light>.row.hleft:before,
.block.news-banner:not(.no-grad).dark>.row.hleft:before {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

@media only screen and (max-width: 767px) {
    .block.news-banner:not(.no-grad).light>.row:before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)
    }

    .block.news-banner:not(.no-grad).dark>.row:before {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.8) 100%)
    }
}

@media only screen and (min-width: 768px) {
    .block.news-banner:not(.no-grad).light>.row.hleft:before {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%)
    }

    .block.news-banner:not(.no-grad).dark>.row.hleft:before {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%)
    }
}

.news-teaser {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px
}

.news-teaser .image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    width: 90px;
    height: 140px
}

.news-teaser .image-wrapper img {
    width: auto;
    height: 140px
}

.news-teaser .item-info {
    display: flex;
    flex-direction: column;
    width: 100%
}

.news-teaser .item-info .title-spacer {
    margin: 10px 0 32px
}

.news-teaser .item-info .title-border {
    margin: 10px 0 20px;
    width: 30px;
    border-top-width: 2px;
    border-top-style: solid
}

.news-teaser .item-info h4,
.news-teaser .item-info h5 {
    margin: 0 0 10px
}

.news-teaser .item-info h4 {
    flex-grow: 1
}

.news-teaser .item-info p:last-child {
    margin-bottom: 0
}

.news-teaser:not(:first-child)>.image-wrapper,
.news-teaser:not(:first-child)>.image-wrapper+.item-info {
    margin-top: 10px
}

.news-teaser:not(:last-child)>.image-wrapper,
.news-teaser:not(:last-child)>.image-wrapper+.item-info {
    margin-bottom: 7px
}


@media screen and (min-width: 480px) {
    .mtop {
        margin-top: -150px;
    }
}

.mtop {
    margin-top: 40px;
}
.block.divider hr {
    height: 0;
    border-width: 2px 0 0;
    border-style: solid
}

.block.divider hr.dotted {
    border-width: 3px 0 0;
    border-style: dotted
}

.block.checklist div.checklist-items {
    padding-left: 0;
    margin: 0 -15px;
    counter-reset: checklist
}

.block.checklist div.checklist-items > .checklist-item {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 30px 0 0 15px;
    width: calc(100% - 30px)
}

.block.checklist div.checklist-items > .checklist-item:first-child {
    margin-top: 0
}

.block.checklist div.checklist-items > .checklist-item .media-left {
    min-width: 45px
}

.block.checklist div.checklist-items > .checklist-item .media-left span {
    position: relative;
    top: -3px;
    left: -3px;
    font-size: 35px;
    line-height: 1em
}

.block.checklist div.checklist-items > .checklist-item .media-body h3:before {
    display: block;
    counter-increment: checklist 1;
    content: counter(checklist);
    font-size: 50px;
    font-weight: bold;
    line-height: 35px;
    padding-left: 75px;
    margin-left: -75px;
    margin-bottom: 10px
}

.block.checklist div.checklist-items > .checklist-item .media-body > *:first-child {
    margin-top: 0
}

.block.checklist div.checklist-items > .checklist-item .media-body > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item {
        padding-right: 15px;
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item:nth-child(-n+2) {
        margin-top: 0
    }

    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item:nth-child(odd) {
        clear: left
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item:nth-child(odd) {
        clear: none
    }

    .layout .layout-12 .block.checklist div.checklist-items.two-items > .checklist-item {
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.checklist div.checklist-items.three-items > .checklist-item {
        width: calc(33.3% - 15px)
    }

    .layout .layout-12 .block.checklist div.checklist-items.max-items > .checklist-item {
        width: calc(25% - 15px)
    }

    .layout .layout-12 .block.checklist div.checklist-items.max-items > .checklist-item:nth-child(4n+1) {
        clear: left
    }
}

[class^="ucws-checklist-"], [class*=" ucws-checklist-"] {
    font-family: 'ucws-checklist' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ucws-checklist-check-square:before {
    content: "\E901";
}

.ucws-checklist-check-circle:before {
    content: "\E900";
}

.ucws-checklist-check-square-solid:before {
    content: "\E834";
}

.ucws-checklist-check-circle-solid:before {
    content: "\E86C";
}

.layout--onecol .layout__region {
    width: 100%;
}

.block.more-info div.more-info-items {
    padding-left: 0;
    margin: 0 -15px
}

.block.more-info div.more-info-items > .more-info-item {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 30px 0 0 15px;
    width: calc(100% - 30px)
}

.block.more-info div.more-info-items > .more-info-item:first-child {
    margin-top: 0
}

.block.more-info div.more-info-items > .more-info-item .media-left {
    min-width: 50px
}

.block.more-info div.more-info-items > .more-info-item .media-left span {
    font-size: 35px;
    line-height: 1em
}

.block.more-info div.more-info-items > .more-info-item .media-body > *:first-child {
    margin-top: 0
}

.block.more-info div.more-info-items > .more-info-item .media-body > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item, .layout .layout-9 .block.more-info div.more-info-items > .more-info-item, .layout .layout-8 .block.more-info div.more-info-items > .more-info-item, .layout .layout-6 .block.more-info div.more-info-items > .more-info-item {
        padding-right: 15px;
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-9 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-8 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-6 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2) {
        margin-top: 0
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-9 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-8 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-6 .block.more-info div.more-info-items > .more-info-item:nth-child(odd) {
        clear: left
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(odd) {
        clear: none
    }

    .layout .layout-12 .block.more-info div.more-info-items.two-items > .more-info-item {
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.three-items > .more-info-item {
        width: calc(33.3% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.max-items > .more-info-item {
        width: calc(25% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.max-items > .more-info-item:nth-child(4n+1) {
        clear: left
    }
}

.block.accordion .panel-default {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none
}

.block.accordion .panel-default + .panel {
    margin-top: 15px
}

.block.accordion .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border: none
}

.block.accordion .panel-default:last-child .panel-body > *:last-child {
    margin-bottom: 0
}

.block.accordion .container .row.header ol, .block.accordion .container .row.header ul {
    margin-bottom: 15px
}

.block.accordion .container .row.header p ~ ul, .block.accordion .container .row.header p ~ ol, .block.accordion .container .row.header ol ol, .block.accordion .container .row.header ol ul, .block.accordion .container .row.header ul ol, .block.accordion .container .row.header ul ul {
    padding-left: 40px
}

.block.accordion.solid .panel-heading, .block.accordion.border .panel-heading {
    border-radius: 0;
    transition: background-color 500ms ease;
    padding: 10px 5px 10px 15px
}

.block.accordion.solid .panel-heading h4.panel-title a, .block.accordion.border .panel-heading h4.panel-title a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #63656A
}

.block.accordion.solid .panel-heading h4.panel-title a span, .block.accordion.border .panel-heading h4.panel-title a span {
    display: block
}

.block.accordion.solid .panel-heading h4.panel-title a .ucws-icon, .block.accordion.border .panel-heading h4.panel-title a .ucws-icon {
    font-size: 32px;
    transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms;
    color: #63656A
}

.block.accordion.solid .panel-heading.active, .block.accordion.border .panel-heading.active {
    background-color: #63656A
}

.block.accordion.solid .panel-heading.active h4.panel-title a, .block.accordion.border .panel-heading.active h4.panel-title a {
    color: #fff
}

.block.accordion.solid .panel-heading.active h4.panel-title a .ucws-icon, .block.accordion.border .panel-heading.active h4.panel-title a .ucws-icon {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    color: #fff
}

.block.accordion.solid .panel-heading {
    background-color: #f4f4f4
}

.block.accordion.border .panel-heading {
    background-color: #fff;
    border: 2px solid #63656A
}

.block.accordion .panel-body {
    padding: 15px
}

.block.accordion .panel-body > * {
    margin-top: 15px;
    margin-bottom: 30px
}

.block.accordion .panel-body > *:last-child {
    margin-bottom: 15px
}

.block.accordion .panel-body .section-header *:last-child {
    margin-bottom: 0
}

.block.accordion ol.section-items {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    counter-reset: checklist;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0
}

.block.accordion ol.section-items > li {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-bottom: 2px solid #63656A;
    margin: 30px 0 0 15px;
    width: calc(100% - 30px)
}

.block.accordion ol.section-items > li:nth-child(1) {
    margin-top: 0
}

.block.accordion ol.section-items > li:not(:last-child) {
    padding-bottom: 30px
}

.block.accordion ol.section-items > li:last-child {
    border-bottom: none
}

.block.accordion ol.section-items > li::before {
    display: block;
    counter-increment: checklist 1;
    content: counter(checklist);
    font-size: 36pt;
    color: #63656A;
    line-height: 1em;
    font-weight: bold;
    margin-top: 0
}

.block.accordion ol.section-items > li ol > li > p {
    display: inline
}

.block.accordion ol.section-items > li > *:last-child {
    margin-bottom: 0
}

.block.accordion ol.section-items > li.no-counter {
    vertical-align: top;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7)
}

.block.accordion ol.section-items > li.no-counter ul > li, .block.accordion ol.section-items > li.no-counter ol > li {
    list-style-image: none
}

.block.accordion ol.section-items > li.no-counter::before {
    content: none
}

.block.accordion ol.section-items .btn-wrapper {
    margin-top: 10px
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.accordion ol.section-items > li, .layout .layout-9 .block.accordion ol.section-items > li, .layout .layout-8 .block.accordion ol.section-items > li, .layout .layout-6 .block.accordion ol.section-items > li {
        padding-right: 15px;
        width: calc(50% - 15px);
        padding-bottom: 0 !important;
        border-bottom: none;
        border-right: 2px solid #63656A
    }

    .layout .layout-12 .block.accordion ol.section-items > li:nth-child(-n+2), .layout .layout-9 .block.accordion ol.section-items > li:nth-child(-n+2), .layout .layout-8 .block.accordion ol.section-items > li:nth-child(-n+2), .layout .layout-6 .block.accordion ol.section-items > li:nth-child(-n+2) {
        margin-top: 0
    }

    .layout .layout-12 .block.accordion ol.section-items > li:nth-child(odd), .layout .layout-9 .block.accordion ol.section-items > li:nth-child(odd), .layout .layout-8 .block.accordion ol.section-items > li:nth-child(odd), .layout .layout-6 .block.accordion ol.section-items > li:nth-child(odd) {
        clear: left
    }

    .layout .layout-12 .block.accordion ol.section-items > li:last-child, .layout .layout-12 .block.accordion ol.section-items > li:nth-child(even), .layout .layout-9 .block.accordion ol.section-items > li:last-child, .layout .layout-9 .block.accordion ol.section-items > li:nth-child(even), .layout .layout-8 .block.accordion ol.section-items > li:last-child, .layout .layout-8 .block.accordion ol.section-items > li:nth-child(even), .layout .layout-6 .block.accordion ol.section-items > li:last-child, .layout .layout-6 .block.accordion ol.section-items > li:nth-child(even) {
        border-right: none
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.accordion ol.section-items > li:nth-child(-n+5) {
        margin-top: 0
    }

    .layout .layout-12 .block.accordion ol.section-items > li:nth-child(odd) {
        clear: none
    }

    .layout .layout-12 .block.accordion ol.section-items > li:not(:last-child) {
        border-right: 2px solid #63656A
    }

    .layout .layout-12 .block.accordion ol.section-items.two-items > li {
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.accordion ol.section-items.three-items > li {
        width: calc(33.3% - 15px)
    }

    .layout .layout-12 .block.accordion ol.section-items.four-items > li {
        width: calc(25% - 15px)
    }

    .layout .layout-12 .block.accordion ol.section-items.max-items > li {
        width: calc(20% - 15px)
    }

    .layout .layout-12 .block.accordion ol.section-items.max-items > li:nth-child(5n+1) {
        clear: left
    }

    .layout .layout-12 .block.accordion ol.section-items.max-items > li:nth-child(5n) {
        border-right: none
    }
}

@media only screen and (min-width: 768px) {
    .block.hero-cta.top h1 {
        line-height: 1.05em
    }

    .block.hero-cta.top p {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.25em
    }

    .block.hero-cta.top .btn {
        font-size: 18px
    }
}

.block.hero-cta.image-hero-cta.top.inline-bg-image.rounded-block-below > .row {
    padding: 0
}

.block.hero-cta.image-hero-cta.top.inline-bg-image.rounded-block-below .cta-wrapper {
    padding-bottom: 70px
}

@media only screen and (min-width: 1200px) {
    .block.hero-cta.image-hero-cta.top.inline-bg-image.rounded-block-below .cta-wrapper {
        padding-bottom: 100px
    }
}

.block.hero-cta.legacy-image-hero .col-image {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    text-align: center;
    overflow: hidden
}

.block.hero-cta.legacy-image-hero .col-text .cta-content > :first-child {
    margin-top: 0
}

.block.hero-cta.legacy-image-hero .col-text .cta-content > :last-child {
    margin-bottom: 0
}

@media only screen and (max-width: 991px) {
    .block.hero-cta.legacy-image-hero > .row {
        background-size: 0 0;
        background-repeat: no-repeat
    }

    .block.hero-cta.legacy-image-hero.bg-image > [class*=-back].row > .container > .row:first-child {
        padding-top: 0 !important
    }

    .block.hero-cta.legacy-image-hero.bg-image > [class*=-back].row > .container > .row:last-child {
        padding-bottom: 0 !important
    }

    .block.hero-cta.legacy-image-hero.bg-image .col-image img {
        min-width: 500px;
        max-width: calc(100% + 2px);
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .block.hero-cta.legacy-image-hero.bg-image .col-text {
        padding-top: 25px;
        padding-bottom: 25px
    }
}

@media only screen and (min-width: 992px) {
    .block.hero-cta.legacy-image-hero {
        height: 600px
    }

    .block.hero-cta.legacy-image-hero > .row {
        height: 100%;
        position: relative;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat
    }

    .block.hero-cta.legacy-image-hero > .row > .container {
        height: 100%
    }

    .block.hero-cta.legacy-image-hero > .row > .container > .row {
        height: 100%;
        padding: 50px 0
    }

    .block.hero-cta.legacy-image-hero > .row > .container > .row::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%
    }

    .block.hero-cta.legacy-image-hero > .row .col-text {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        float: none;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 10px;
        width: calc(100% - 5px)
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        max-width: 550px;
        z-index: 2
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light, .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light a:not(.btn) {
        color: #fff
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light:not(.no-shadow) {
        text-shadow: 0 0 15px #000
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light:not(.no-shadow) .btn {
        box-shadow: 0 0 15px rgba(0, 0, 0, .25);
        text-shadow: none
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light:not(.no-shadow) .btn:focus {
        box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25), 0 0 15px rgba(0, 0, 0, .25)
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark, .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark a:not(.btn) {
        color: #3d3d3d
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark:not(.no-shadow) {
        text-shadow: 0 0 15px #fff
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark:not(.no-shadow) .btn {
        box-shadow: 0 0 15px rgba(255, 255, 255, .25);
        text-shadow: none
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark:not(.no-shadow) .btn:focus {
        box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25), 0 0 15px rgba(255, 255, 255, .25)
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vtop .col-text {
        vertical-align: top
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vmiddle .col-text {
        vertical-align: middle
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vbottom .col-text {
        vertical-align: bottom
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.hleft .col-text {
        text-align: left
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.hcenter .col-text {
        text-align: center
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.hright .col-text {
        text-align: right
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vtop.hcenter .col-text .cta-content, .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vbottom.hcenter .col-text .cta-content {
        max-width: 75%
    }

    .block.hero-cta.legacy-image-hero.light > .row.vtop::before, .block.hero-cta.legacy-image-hero.light > .row.hright::before, .block.hero-cta.legacy-image-hero.light > .row.vbottom::before, .block.hero-cta.legacy-image-hero.light > .row.hleft::before, .block.hero-cta.legacy-image-hero.dark > .row.vtop::before, .block.hero-cta.legacy-image-hero.dark > .row.hright::before, .block.hero-cta.legacy-image-hero.dark > .row.vbottom::before, .block.hero-cta.legacy-image-hero.dark > .row.hleft::before {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }

    .block.hero-cta.legacy-image-hero.light > .row.hcenter.vtop::before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 10%, rgba(0, 0, 0, 0.39) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.light > .row.hright::before {
        background: linear-gradient(to left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.light > .row.hcenter.vbottom::before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%)
    }

    .block.hero-cta.legacy-image-hero.light > .row.hleft::before {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.dark > .row.hcenter.vtop::before {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 10%, rgba(255, 255, 255, 0.39) 30%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.dark > .row.hright::before {
        background: linear-gradient(to left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.dark > .row.hcenter.vbottom::before {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.55) 100%)
    }

    .block.hero-cta.legacy-image-hero.dark > .row.hleft::before {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.no-grad > .row::before {
        background: none !important
    }
}

.block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading {
    padding-top: 20px
}

.block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading .divider hr {
    margin-bottom: 15px;
    border-width: 2px
}

.block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading .divider p {
    font-size: 1em;
    line-height: 1em
}

@media only screen and (min-width: 768px) {
    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading h1, .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading h2 {
        line-height: 1.05em
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading p {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.25em
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading .btn {
        font-size: 18px
    }
}

@media only screen and (min-width: 992px) {
    .block.hero-cta.legacy-image-hero.top.news {
        height: 700px
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 0;
        color: #fff;
        text-shadow: 0 0 15px #000
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading hr {
        border-color: #fff !important
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading hr, .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading a.btn.btn-ghost {
        box-shadow: 0 0 15px rgba(0, 0, 0, .25)
    }
}
.font-black{
    color:black;
    text-decoration:none
}
/* {----------} */
.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item {
    /* padding: 8px; */
    cursor: pointer;
    width: 33.3% !important;
    text-align: center !important;
}




.profile_card {
    width: 500px;
    min-height: 100px;
    padding: 20px;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }
  .profile_card:after {
    content: "";
    display: block;
    width: 100%;
    height: 300px;
    background: rgb(149, 153, 153);
    position: absolute;
    -webkit-animation: rotatemagic 0.75s cubic-bezier(0.425, 1.04, 0.47, 1.105) 1s both;
            animation: rotatemagic 0.75s cubic-bezier(0.425, 1.04, 0.47, 1.105) 1s both;
  }
  
  .badgescard {
    padding: 10px 20px;
    border-radius: 3px;
    background-color: #ECECEC;
    width: 480px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: -1;
    left: 10px;
    bottom: 10px;
    -webkit-animation: animainfos 0.5s cubic-bezier(0.425, 1.04, 0.47, 1.105) 0.75s forwards;
            animation: animainfos 0.5s cubic-bezier(0.425, 1.04, 0.47, 1.105) 0.75s forwards;
  }
  .badgescard span {
    font-size: 1.6em;
    margin: 0px 6px;
    opacity: 0.6;
  }
  
  .firstinfo {
    flex-direction: row;
    z-index: 2;
    position: relative;
  }
  .firstinfo img {
    border-radius: 50%;
    width: 120px;
    height: 120px;
  }
  .firstinfo .profileinfo {
    padding: 0px 20px;
  }
  .firstinfo .profileinfo h1 {
    font-size: 1.8em;
  }
  .firstinfo .profileinfo h3 {
    font-size: 1.2em;
    color: #0c0c0c;
    font-style: italic;
  }
  .firstinfo .profileinfo p.bio {
    padding: 10px 0px;
    color: #5A5A5A;
    line-height: 1.2;
    font-style: initial;
  }

  .profile_content {
    position: relative;
    -webkit-animation: animatop 0.9s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards;
            animation: animatop 0.9s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards;
  }
  
.block.profile-list.views-block.dropdown-filters .view-output .form-item-field-ucws-profile-research-area,
.block.profile-list.views-block.dropdown-filters .view-output .form-item-field-ucws-profile-job-title,
.block.profile-list.views-block.dropdown-filters .view-output .form-item-field-ucws-profile-research-thm,
.block.profile-list.views-block.dropdown-filters .view-output .form-item-field-ucws-profile-unit-dept {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.show_faculty_research_area_filter .form-item-field-ucws-profile-research-area {
    display: inline-block
}

.block.profile-list.views-block.dropdown-filters .view-output.show_job_title_filter .form-item-field-ucws-profile-job-title {
    display: inline-block
}

.block.profile-list.views-block.dropdown-filters .view-output.show_research_theme_filter .form-item-field-ucws-profile-research-thm {
    display: inline-block
}

.block.profile-list.views-block.dropdown-filters .view-output.show_unit_department_filter .form-item-field-ucws-profile-unit-dept {
    display: inline-block
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_job_title .roles .job-title {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_profile_photos .profile-image {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_research_interests .research-areas {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_unit_department .roles .unit-department-name {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_keyword_search .row.filters,
.block.profile-list.views-block.dropdown-filters .view-output.hide_keyword_search .row.filters+.row.divider,
.block.profile-list.views-block.dropdown-filters .view-output.hide_keyword_search .row.search-bubbles {
    display: none
}

.block.profile-list .row.header .icon {
    width: 56px;
    height: 56px;
    margin-top: 6px;
    margin-right: 30px;
    border-image: linear-gradient(90deg, #63656A 0%, #cccccc 50%, #999999 100%) 1 !important;
}

.block.profile-list .row.header .icon .icomoon {
    font-size: 56px
}

.block.profile-list .row.header .text {
    align-self: center
}

.block.profile-list .row.header .text>*:first-child {
    margin-top: 0
}

.block.profile-list .row.header .text>*:last-child {
    margin-bottom: 0
}

.block.profile-list .row.header .text h2:last-child {
    margin-top: 8px
}

.block.profile-list .divider hr {
    border-width: 3px;
    border-image: linear-gradient(90deg, #63656A 0%, #cccccc 50%, #999999 100%) 1 !important;
}

.block.profile-list .divider hr.pager-divider {
    border-width: 1px
}

.block.profile-list .row.profile.view-output>div {
    padding-left: 15px;
    padding-right: 15px
}

.block.profile-list .row.profile.view-output>div .profile-view-container {
    padding-top: 15px;
    padding-bottom: 25px
}

.block.profile-list ol.profile-items-list {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0
}

.block.profile-list ol.profile-items-list>li.profile {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 30px 0 0 20px;
    padding-right: 20px;
    width: calc(50% - 20px)
}

.block.profile-list ol.profile-items-list>li.profile:nth-child(-n+2) {
    margin-top: 0
}

.block.profile-list ol.profile-items-list>li.profile:nth-child(odd) {
    clear: none
}

.block.profile-list ol.profile-items-list>li.profile:not(:last-child) {
    border-right: 1px solid #8d8d8d
}

.block.profile-list ol.profile-items-list>li.profile:nth-child(2n) {
    border-right: none
}

.block.profile-list ol.profile-items-list>li.profile:first-child {
    margin-top: 0
}

.block.profile-list ol.profile-items-list>li.profile .profile-image {
    margin-bottom: 15px
}

.block.profile-list ol.profile-items-list>li.profile .profile-image img {
    max-height: 205px
}

.block.profile-list ol.profile-items-list>li.profile .status {
    margin-top: 20px;
    margin-bottom: 5px;
    font-style: italic
}

.block.profile-list ol.profile-items-list>li.profile .status .accepting {
    color: #ffcd00
}

.block.profile-list ol.profile-items-list>li.profile .status .seeking {
    color: #47a67c
}

.block.profile-list ol.profile-items-list>li.profile .status .unavailable:before {
    content: "\E083";
    color: #8d8d8d
}

.block.profile-list ol.profile-items-list>li.profile .roles {
    font-weight: bold;
    margin-bottom: 20px
}

.block.profile-list ol.profile-items-list>li.profile .research-areas {
    margin-bottom: 20px
}

.block.profile-list ol.profile-items-list>li {
    vertical-align: top
}

.hgt {
    max-height: 400px !important;
}

@media screen and (max-width:400px) {
    .hgt {
        max-height: 200px !important;
    }
}


@media only screen and (min-width: 556px) {
    .layout .layout-12 .block.profile-list ol.profile-items-list>li {
        width: calc(33.3% - 20px)
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(-n+3) {
        margin-top: 0
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:not(:last-child) {
        border-right: 1px solid #8d8d8d
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(3n) {
        border-right: none
    }
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.profile-list ol.profile-items-list>li {
        width: calc(25% - 20px)
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:not(:last-child) {
        border-right: 1px solid #8d8d8d
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(4n) {
        border-right: none
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.profile-list ol.profile-items-list>li {
        width: calc(25% - 20px)
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(4n+1) {
        clear: left
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:not(:last-child) {
        border-right: 1px solid #8d8d8d
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(4n) {
        border-right: none
    }
}


.video_frame {
    border-style: none;
    width: 100%;
    height: 120px;
}
.textBox {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url('');
  background-position: 0px 0px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 10px;
  transition: width 0.4s ease-in-out;
}

.textBox:focus {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
}


.st_card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
}

.st_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.st_container {
  padding: 2px 16px;
}



.fv_container {
  box-shadow: 0 15px 30px 1px grey;
  background: rgba(255, 255, 255, 0.90);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  height: 250px;
  width: 500px;
  margin-bottom: 22px;
}

@media screen and (max-width: 490px) {
  .fv_container {
    box-shadow: 0 15px 30px 1px grey;
    background: rgba(255, 255, 255, 0.90);
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    height: 250px;
    width: 350px;
    margin-bottom: 22px;
  }

  .fv_product-image {
    display: none;
  }

  .fv_product-details {

    float: left;
    width: 100%;

  }

}

@media screen and (max-width: 360px) {
  .fv_container {
    box-shadow: 0 15px 30px 1px grey;
    background: rgba(255, 255, 255, 0.90);
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    height: 250px;
    width: 100%;
    margin-bottom: 22px;
  }

  .fv_product-image {
    display: none;
  }

  .fv_product-details {

    float: left;
    width: 100%;
  }
}


/* @media screen and (max-width: 760px) {
  .fv_container {
    box-shadow: 0 15px 30px 1px grey;
    background: rgba(255, 255, 255, 0.90);
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    height: 250px;
    width: 650px;
    margin-bottom: 22px;
  }
  
}
 */



.fv_product-details {
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 20px;

  height: 100%;
  float: left;
  width: 50%;
  max-width: 50%;

}


.fv_container .product-details>p {
  font-family: 'EB Garamond', serif;
  text-align: center;
  font-size: 18px;
  color: #7d7d7d;

}

.fv_control {
  position: absolute;
  bottom: 5%;
  left: 55.8%;
  color: white;

}

.fv_btn {

  -webkit-transform: translateY(0px);

          transform: translateY(0px);
  transition: 0.3s linear;
  background: #595a5a;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
  color: #eee;
  padding: 10px;
  margin: 0;

}

.fv_btn:hover {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
  background: #d5d6d8;
  color: black
}

.fv_btn span {
  font-family: 'Farsan', cursive;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  display: inline-block;
  padding: 10px;
  font-size: 14px;
  margin: 0;

}

.fv_btn .fv_price,
.fv_shopping-cart {
  background: #333;
  border: 0;
  margin: 0;
}

.fv_btn .fv_price {
  -webkit-transform: translateX(-10%);
          transform: translateX(-10%);
  padding-right: 15px;
}

.fv_btn .fv_shopping-cart {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  position: absolute;
  background: #333;
  z-index: 1;
  left: 0;
  top: 0;
}

.fv_btn .fv_buy {
  z-index: 3;
  font-weight: bolder;
  color: white;
}

.fv_btn:hover .fv_price {
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
}

.fv_btn:hover .fv_shopping-cart {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}



.fv_product-image {
  transition: all 0.3s ease-out;
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 100%;
  float: right;
  width: 45%;
  display: inline-block;
}

.fv_container img {
  width: 100%;
  height: 100%;
}

.fv_info {
  background: rgba(27, 26, 26, 0.9);
  font-family: 'monospace', monospace;
  transition: all 0.3s ease-out;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  position: absolute;
  text-align: left;
  font-size: 105%;
  cursor: no-drop;
  color: #FFF;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.fv_info h2 {
  text-align: center
}

.fv_product-image:hover .fv_info {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.fv_info ul li {
  transition: 0.3s ease;
}

.fv_info ul li:hover {
  -webkit-transform: translateX(50px) scale(1.3);
          transform: translateX(50px) scale(1.3);
}

.fv_product-image:hover img {
  transition: all 0.3s ease-out;
}

.fv_product-image:hover img {
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
}
ul.tabs {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.tabs>a li {
    padding: 10px;
    background-color: #f4f4f4;
    color: black;
    border-bottom: 1px solid lightgray;
    align-content: space-between;
}

ul.tabs>a li:hover {
    background-color: lightgray;
}
ul.tabs>a li {
    color: #333333;
    text-decoration: none !important;
    font-weight: normal;

}

ul.tabs>a li.selected {
    background-color: #f4f4f4;
}

.nav-top{
    margin-left: 20px;
}

.icons{
    font-weight: bold;
    font-size: 18px;
}

.cardImage{

}
.cardDetailsContainer{
    margin:'10px'
}
.cardDetails{
    background-color:  #f4f4f4;
    top:-50px;
    border-radius:5px;
    padding: 15px
}
.applyButton{
    margin-top: -40px;
}

.padd{
    margin-left: 20%;
    margin-right: 20%;
}
.textBox {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
    display: flex;
  }
  
  .textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid gray;
  }

.link{
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
}

.alert {
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 12px;
    font-size: 16px;
  }
  
  .alert.alert-success {
    background-color: rgba(227, 253, 235, 1);
    border-color: rgba(38, 179, 3, 1);
    color: rgba(60, 118, 61, 1);
  }
  
  .alert.alert-info {
    background-color: rgba(217, 237, 247, 1);
    color: rgba(49, 112, 143, 1);
    border-color: rgba(126, 182, 193, 1);
  }
  
  
  .alert.alert-warning {
    background-color: rgba(252, 248, 227, 1);
    border-color: rgba(177, 161, 129, 1);
    color: rgba(138, 109, 59, 1);
  }
  
  .alert.alert-danger {
    background-color: rgba(248, 215, 218, 1);
    border-color: rgba(220, 53, 69, 1);
    color: rgba(114, 28, 36,1);
  }
.textBox {
    width: 230px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
}

.textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
}


.search-container{
    padding-top: 5px;
    border-bottom: 1px solid #000000;
}

.search-container span{
    margin-top: -5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-size: 12px;
}
.search-card{
    border-bottom: 1px solid #eeeeee;
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-card  .title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: inherit;
}
.search-card  p{
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.search-card > .links{
    margin-top: -15px !important;
    color: #0a6aa1;
}




.textBox {
    width: 230px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
}

.textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
}


.search-container{
    padding-top: 5px;
    border-bottom: 1px solid #000000;
}

.search-container span{
    margin-top: -5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-size: 12px;
}
.search-card{
    border-bottom: 1px solid #eeeeee;
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-card  .title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: inherit;
}
.search-card  p{
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.search-card > .links{
    margin-top: -15px !important;
    color: #0a6aa1;
}




.f_textBox {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
  }
  
  .f_textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
  }


  @media screen and (max-width: 760px) {
    .jumbo_pad{
      padding: 20px;
      padding-right: 100px;
    }
    
  }


/**
 * WEBSITE: https://themefisher.com
 * TWITTER: https://twitter.com/themefisher
 * FACEBOOK: https://www.facebook.com/themefisher
 * GITHUB: https://github.com/themefisher/
 */

/*=== MEDIA QUERY ===*/

.form-control::-webkit-input-placeholder {
  color: #b3b3b3;
  font-size: 0.9375rem;
}

/* ul.social-links {
  margin-bottom: 0;
}
ul.social-links li:first-child a {
  padding-left: 0;
}
ul.social-links li a {
  padding: 0 15px;
  display: block;
}
ul.social-links li a i {
  font-size: 20px;
  color: #222222;
} */

.shadow,
.testimonial .testimonial-item .comment:hover,
.news-hr .news-post-hr:hover,
ul.pagination .active a,
ul.pagination li a:hover,
ul.pagination li a:focus,
.sponsors .block .image-block:hover,
.schedule.two .schedule-contents,
.speakers.white .speaker-item:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

/* .overlay:before {
  content: "";
  background: url(../images/background/objects.png) no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */

.overlay:after {
  content: "";
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-lighter:before {
  content: "";
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text-overlay:after {
  content: "CONFERENCE";
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 215px;
  width: 100%;
  height: 300px;
  background-position: center center;
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: 0.05;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media (max-width: 767px) {
  .text-overlay:after {
    font-size: 100px;
  }
}

@media (max-width: 575px) {
  .text-overlay:after {
    font-size: 70px;
  }
}

.overlay-primary:before {
  content: "";
  background: rgba(255, 102, 0, 0.93);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-dark:before {
  content: "";
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-dark-2:before {
  content: "";
  background: -webkit-linear-gradient(-45deg, #0e315a 0%, #010103 0%, #050208 100%, #207cca 100%);
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-white:before {
  content: "";
  background: linear-gradient(0deg, rgba(245, 245, 248, 0.8) 0%, rgb(247, 247, 247) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-white-slant:before {
  content: "";
  width: 100%;
  margin-left: -45%;
  background: rgb(255, 255, 255);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: skewX(-20deg);
          transform: skewX(-20deg);
}

@media (max-width: 767px) {
  .overlay-white-slant:before {
    content: none;
  }
}

/* 
span.alternate {
  color: #161515;
  font-style: italic;
  font-weight: 400;
}

a {
  transition: 0.2s ease;
}

a:focus, a:hover {
  color: #161515;
  text-decoration: none;
} */

/* button:hover {
  cursor: pointer;
} */

.bg-gray {
  background: #fafafa;
}

.bg-blue {
  background: #161515;
}

.bg-banner-one {
  background: url(/static/media/homepage-one-banner.a01b22c7.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}



@media (max-width: 767px) {
  .bg-banner-two {
    background-position: center center;
  }
}


.bg-speaker {
  background: url(/static/media/spaeker-background.4baf8294.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}


.bg-subscribe {
  background: url(/static/media/organizers.2ef8a623.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}

/* .bg-title {
  background: url(../images/background/page-title-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
} */

.section {
  padding: 40px 0;
}

.border-less {
  border-bottom: 1px solid #e5e5e5;
}

.section-title {
  margin-top: 5px;
  text-align: center;
  margin-bottom: 20px;
}

.section-title h3 {
  margin-bottom: 0px;
}

.section-title.white h3,
.section-title.white p {
  color: #fff;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.page-title {
  text-align: center;
}

.video {
  position: relative;
}

.video:before {
  border-radius: 3px;
}

.video img {
  width: 100%;
  border-radius: 8px;
}

.video .video-button {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video .video-box a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video .video-box a i {
  height: 125px;
  width: 125px;
  font-size: 40px;
  background: #161515;
  border-radius: 100%;
  color: #fff;
  line-height: 125px;
  text-align: center;
}

.video .video-box a iframe {
  width: 100%;
  height: 100%;
}

.form-control {
  background: transparent;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  font-size: 16px;
  padding: 15px 20px;
  height: 48px;
  margin-bottom: 20px;
  color: #b3b3b3;
}

.form-control:focus {
  border: 1px solid #ffffff;
  box-shadow: none;
}

.form-control.white {
  background: #fff;
  color: #000000e5;
}

select.form-control:not([size]):not([multiple]) {
  height: 100%;
}

.left {
  overflow: hidden;
}

.left img {
  margin-left: -40px;
}

@media (max-width: 767px) {
  .left img {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.right {
  overflow: hidden;
}

.right img {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .right img {
    margin-left: 0;
  }
}

.hide-overflow {
  overflow: hidden;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  /* ideally, transition speed should match zoom duration */
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.border-top {
  border-top: 1px solid;
}

.slick-slide:focus {
  outline: 0;
}

.btn {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease;
}

.btn-main {
  padding: 25px 45px;
  border-radius: 3px;
  background: #161515;
  color: #fff;
  outline: none;
}

.btn-main:hover {
  color: #fff;
}

.btn-main:focus {
  color: #fff;
  box-shadow: none;
}

.btn-main-md {
  padding: 15px 40px;
  border-radius: 0;
  background: #4e4d4d;
  color: #fff;
  outline: none;
  font-size: 1.9375rem;
}

.btn-main-md:hover {
  color: #fff;
  background: #161515;
}

.btn-main-md:focus {
  color: white;
  box-shadow: none;
}

.btn-transparent-md {
  padding: 15px 40px;
  background: transparent;
  outline: none;
  font-size: 1.9375rem;
  color: black;
  border: 1px solid #e5e5e5;
  border-radius: 0;
}

.btn-transparent-md:hover {
  background: #161515;
  border-color: #161515;
  color: #fff;
}

.btn-main-sm {
  padding: 15px 35px;
  border-radius: 3px;
  background: #161515;
  color: #fff;
  outline: none;
  font-size: 14px;
}

.btn-main-sm:hover {
  color: #fff;
}

.btn-main-sm:focus {
  color: #fff;
  box-shadow: none;
}

.btn-white-md {
  background: #fff;
  color: #222222;
  padding: 15px 40px;
  border-radius: 0;
}

.btn-white-md:hover {
  background: #161515;
  color: #fff;
}

.btn-rounded-icon {
  border-radius: 100px;
  color: #fff;
  border: 1px solid #fff;
  padding: 13px 50px;
}

.main-nav {
  background: #fff;
}

@media (max-width: 767px) {
  .main-nav {
    position: relative;
  }
}

.main-nav .navbar-brand {
  padding: 35px 40px 35px 50px;
  border-right: 1px solid #e5e5e5;
}

@media (max-width: 1200px) {
  .main-nav .navbar-brand {
    padding: 20px 30px;
  }
}

@media (max-width: 767px) {
  .main-nav .navbar-brand {
    border-right: none;
  }
}

.main-nav .navbar-toggler {
  font-size: 1.5rem;
  margin-right: 15px;
}

.main-nav .navbar-nav .nav-item {
  position: relative;
  font-family: "Roboto", sans-serif;
}

.main-nav .navbar-nav .nav-item .nav-link {
  position: relative;
  text-align: center;
  color: #222222;
  padding-left: 5px;
  padding-right: 17px;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-size: 1.06rem;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1352px) {
  .main-nav .navbar-nav .nav-item .nav-link {
    font-size: 0.86rem;
    padding-right: 10px;
  }
}

@media (max-width: 1200px) {
  .main-nav .navbar-nav .nav-item .nav-link {
    padding-left: 4px;
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .main-nav .navbar-nav .nav-item .nav-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.main-nav .navbar-nav .nav-item .nav-link span {
  color: #222222;
  margin-left: 16px;
}

@media (max-width: 1200px) {
  .main-nav .navbar-nav .nav-item .nav-link span {
    margin-left: 14px;
  }
}

@media (max-width: 1024px) {
  .main-nav .navbar-nav .nav-item .nav-link span {
    margin-left: 10px;
  }
}

@media (max-width: 991px) {
  .main-nav .navbar-nav .nav-item .nav-link span {
    display: none;
  }
}

.main-nav .navbar-nav .nav-item:hover .nav-link,
.main-nav .navbar-nav .nav-item.active .nav-link {
  color: #161515;
}

.main-nav .dropdown {
  position: relative;
}

.main-nav .dropdown .open>a,
.main-nav .dropdown .open>a:focus,
.main-nav .dropdown .open>a:hover {
  background: transparent;
}

.main-nav .dropdown.full-width .dropdown-menu {
  left: 0 !important;
  right: 0 !important;
}

.main-nav .dropdown .dropdown-menu {
  min-width: 220px;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.main-nav .dropdown .dropdown-menu li:not(:last-child) .dropdown-item {
  border-bottom: 1px solid #e5e5e5;
}

@media (max-width: 991px) {
  .main-nav .dropdown .dropdown-menu li:not(:last-child) .dropdown-item {
    border: 0;
  }
}

.main-nav .dropdown .dropdown-menu .dropdown-item {
  font-size: 15px;
  padding: 10px 16px;
  transition: 0.3s ease;
}

.main-nav .dropdown .dropdown-menu .dropdown-item:hover {
  background: #fff;
  color: #161515;
}

@media (max-width: 991px) {
  .main-nav .dropdown .dropdown-menu {
    -webkit-transform: none;
            transform: none;
    left: auto;
    position: relative;
    text-align: center;
    opacity: 1;
    visibility: visible;
    background: rgb(243, 243, 243);
  }
}

@media (min-width: 992px) {
  .main-nav .dropdown .dropdown-menu {
    transition: 0.3s all;
    position: absolute;
    display: block;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }

  .main-nav .dropdown:hover>.dropdown-menu {
    border-top: 3px solid #161515;
    opacity: 1;
    display: block;
    visibility: visible;
    color: #777;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  .main-nav .dropdown .dropleft .dropdown-menu {
    right: 100%;
  }
}

.main-nav .dropleft .dropdown-menu,
.main-nav .dropright .dropdown-menu {
  margin: 0;
}

.main-nav .dropleft .dropdown-menu {
  right: 0;
}

.main-nav .dropleft .dropdown-toggle::before,
.main-nav .dropright .dropdown-toggle::after {
  font-weight: bold;
  font-family: "FontAwesome";
  border: 0;
  font-size: 14px;
  vertical-align: 1px;
}

.main-nav .dropleft .dropdown-toggle::before {
  content: "\F104";
  margin-right: 7px;
}

.main-nav .dropright .dropdown-toggle::after {
  content: "\F105";
  margin-left: 7px;
}

.main-nav .dropdown-submenu:hover>.dropdown-toggle,
.main-nav .dropdown-submenu.active>.dropdown-toggle {
  color: #161515;
}

.main-nav .ticket {
  background: #161515;
  display: block;
  padding: 34px 32px;
}

@media (max-width: 1352px) {
  .main-nav .ticket {
    padding: 34px 26px;
  }
}

@media (max-width: 1200px) {
  .main-nav .ticket {
    padding: 23px 26px;
  }
}

@media (max-width: 991px) {
  .main-nav .ticket {
    text-align: center;
  }
}

.main-nav .ticket img {
  margin-right: 25px;
}

@media (max-width: 1200px) {
  .main-nav .ticket img {
    display: none;
  }
}

.main-nav .ticket span {
  color: #fff;
  font-size: 1.125rem;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  border-left: 1px solid #ffa366;
  padding-left: 24px;
}

@media (max-width: 1200px) {
  .main-nav .ticket span {
    padding-left: 0;
    font-size: 1rem;
    border-left: none;
  }
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none;
}

.page-title {
  position: relative;
  padding: 165px 0 65px;
}

@media (max-width: 1200px) {
  .page-title {
    padding: 140px 0 65px;
  }
}

@media (max-width: 767px) {
  .page-title {
    padding: 65px 0;
  }
}

.page-title h3,
.page-title li,
.page-title a {
  color: #fff;
}

.page-title .title h3 {
  font-weight: 500;
  text-transform: uppercase;
}

.page-title .breadcrumb {
  background: transparent;
}

.page-title .breadcrumb .breadcrumb-item {
  float: none;
  display: inline-block;
  text-transform: uppercase;
}

.page-title .breadcrumb .breadcrumb-item.active {
  color: #fff;
}

.page-title .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: "\F105";
  font-family: FontAwesome;
  color: #fff;
}

.footer-main {
  background: #272735;
  padding: 90px 0;
}

@media (max-width: 767px) {
  .footer-main {
    padding: 50px 0;
  }
}

.footer-main .block img {
  margin-bottom: 60px;
}

.footer-main .block ul.social-links-footer {
  margin-bottom: 0;
}

.footer-main .block ul.social-links-footer .list-inline-item:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 575px) {
  .footer-main .block ul.social-links-footer .list-inline-item:not(:last-child) {
    margin-right: 5px;
  }
}

.footer-main .block ul.social-links-footer li a {
  display: block;
  height: 50px;
  width: 50px;
  text-align: center;
  background: #fff;
}

.footer-main .block ul.social-links-footer li a i {
  line-height: 50px;
}

.footer-main .block ul.social-links-footer li a:hover {
  background: #161515;
}

.footer-main .block ul.social-links-footer li a:hover i {
  color: #fff;
}

.subfooter {
  background: #2a2a37;
  padding: 18px 0;
}

.subfooter .copyright-text p {
  font-family: "Montserrat", sans-serif;
}

.subfooter .copyright-text p a {
  color: #161515;
}

@media (max-width: 767px) {
  .subfooter .copyright-text {
    text-align: center;
  }
}

.subfooter .to-top {
  display: block;
  height: 45px;
  width: 45px;
  text-align: center;
  background: #161515;
  float: right;
}

.subfooter .to-top i {
  font-size: 1.5rem;
  color: #fff;
  line-height: 45px;
}

@media (max-width: 767px) {
  .subfooter .to-top {
    float: none;
    margin: auto;
    margin-top: 30px;
  }
}

.cta-ticket {
  position: relative;
}

.cta-ticket .content-block {
  padding: 100px 0;
}

.cta-ticket .content-block h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
}

.cta-ticket .content-block p {
  color: #e6e6e6;
  margin-bottom: 45px;
}

.cta-ticket .image-block {
  position: absolute;
  right: 5%;
  bottom: 0;
}

@media (max-width: 767px) {
  .cta-ticket .image-block {
    display: none;
  }
}

.cta-subscribe {
  position: relative;
}

.cta-subscribe .content {
  padding: 70px 0;
}

.cta-subscribe .content h3,
.cta-subscribe .content p {
  color: #fff;
}

.cta-subscribe .content h3 {
  font-weight: 500;
}

.cta-subscribe .content p {
  font-size: 1.9375rem;
}

.cta-subscribe .subscribe-button button {
  cursor: pointer;
}

@media (max-width: 767px) {
  .cta-subscribe .subscribe-button {
    margin-bottom: 30px;
  }
}

.banner {
  padding: 250px 0 150px;
  position: relative;
}

@media (max-width: 1024px) {
  .banner {
    padding: 100px 0;
  }
}

.banner .block {
  position: relative;
  z-index: 10;
}

.banner .block .timer .syotimer-cell {
  margin-right: 25px;
  margin-bottom: 33px;
  display: inline-block;
}

@media (max-width: 575px) {
  .banner .block .timer .syotimer-cell {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.banner .block .timer .syotimer-cell .syotimer-cell__value {
  min-width: 80px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  line-height: 77px;
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #fff;
  border: 2px solid #7c7491;
  margin-bottom: 8px;
  border-radius: 100%;
  box-shadow: 3.5px 6.062px 0px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
  .banner .block .timer .syotimer-cell .syotimer-cell__value {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .banner .block .timer .syotimer-cell .syotimer-cell__value {
    min-width: 65px;
    line-height: 61px;
    font-size: 15px;
  }
}

.banner .block .timer .syotimer-cell .syotimer-cell__unit {
  text-align: center;
  font-size: 1rem;
  text-transform: lowercase;
  font-weight: normal;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.banner .block h1 {
  /* text-transform: uppercase; */
  font-size: 5.0rem;
  color: #ececec;
  font-weight: 500;
  margin-bottom: 5px;
  text-shadow: 3.5px 6.062px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .banner .block h1 {
    font-size: 4rem;
  }
}

@media (max-width: 400px) {
  .banner .block h1 {
    font-size: 2rem;
  }
}

.banner .block h2 {
  text-transform: uppercase;
  font-size: 4.0rem;
  color: #fff;
  font-weight: 400;
  text-shadow: 3.5px 6.062px 0px rgba(0, 0, 0, 0.1);
  font-style: italic;
}

@media (max-width: 767px) {
  .banner .block h2 {
    font-size: 3.2rem;
  }
}

@media (max-width: 400px) {
  .banner .block h2 {
    font-size: 2.3rem;
  }
}

.banner .block h6 {
  color: #fff;
  font-weight: 400;
  font-size: 2.0rem;
  margin-bottom: 50px;
}

.banner-two {
  padding: 250px 0 150px;
  position: relative;
  overflow: hidden;
}

.banner-two .block h1 {
  color: #161515;
  font-size: 88px;
}

@media (max-width: 575px) {
  .banner-two .block h1 {
    font-size: 40px;
  }
}


.banner-two .block h2 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 74px;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .banner-two .block h2 {
    font-size: 35px;
  }
}

.banner-two .block h3 {
  font-size: 74px;
}

@media (max-width: 575px) {
  .banner-two .block h3 {
    font-size: 35px;
  }
}

.banner-two .block h6 {
  margin-top: 48px;
  font-weight: 400;
  margin-bottom: 60px;
}

@media (max-width: 575px) {
  .banner-two .block h6 {
    margin-top: 30px;
  }
}

.about .image-block {
  padding: 30px;
}

.about .image-block img {
  border-radius: 0%;
  width: 100%;
  box-shadow: 0px 0px 199px 10px rgba(3, 11, 24, 0.15);
}

@media (max-width: 767px) {
  .about .image-block {
    margin-bottom: 30px;
    text-align: center;
  }
}

.about .image-block.two {
  padding: 0;
  margin-top: 30px;
}

.about .image-block.two img {
  border-radius: 0;
  -webkit-transform: translate(30px, -30px);
          transform: translate(30px, -30px);
}

@media (max-width: 767px) {
  .about .image-block.two img {
    -webkit-transform: translate(0, -30px);
            transform: translate(0, -30px);
  }
}

.about .content-block {
  margin-left: 15px;
}

@media (max-width: 767px) {
  .about .content-block {
    text-align: center;
  }
}

.about .content-block h2 {
  margin-bottom: 35px;
}

.about .content-block h2:after {
  content: "";
  width: 60px;
  height: 3px;
  background: #161515;
  display: block;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .about .content-block h2:after {
    margin: auto;
  }
}

.about .content-block .description-one {
  margin-bottom: 20px;
}

.about .content-block .description-one-theme p {
  color: #222222;
  font-size: 3.0625rem;
}

.about .content-block .description-one p {
  color: #222222;
  font-size: 17px;
}

.about .content-block .description-two {
  margin-bottom: 30px;
}

.about .content-block .description-two p {
  font-size: 17px;
  text-align: justify;
}

@media (max-width: 575px) {
  .about .content-block ul.list-inline .list-inline-item {
    display: block;
  }
}

.about .content-block .list-inline-item:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 991px) {
  .about .content-block .list-inline-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .about .content-block .list-inline-item:not(:last-child) {
    margin-right: 0;
  }
}

.speakers {
  position: relative;
}

.speakers.white .speaker-item {
  background: #fff;
  border: 1px solid #e5e5e5;
}

.speakers .speaker-item {
  background: #eae9ec;
  padding: 7px;
  margin-bottom: 30px;
}

.speakers .speaker-item .image {
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 575px) {
  .speakers .speaker-item .image img {
    width: 100% !important;
  }
}

.speakers .speaker-item .image .primary-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161515;
  opacity: 0.85;
  transition: 0.3s ease;
}

.speakers .speaker-item .image .socials {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
}

.speakers .speaker-item .image .socials ul {
  width: 50%;
}

@media (max-width: 1200px) {
  .speakers .speaker-item .image .socials ul {
    width: 100%;
    text-align: center;
    padding: 0 20px;
  }
}

.speakers .speaker-item .image .socials ul li.list-inline-item:not(:last-child) {
  margin-right: 7px;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .speakers .speaker-item .image .socials ul li.list-inline-item:not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

@media (max-width: 1200px) {
  .speakers .speaker-item .image .socials ul li.list-inline-item:last-child {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.speakers .speaker-item .image .socials ul li a {
  display: block;
  height: 50px;
  width: 50px;
  text-align: center;
  border: 1px solid #fff;
}

.speakers .speaker-item .image .socials ul li a i {
  font-size: 0.9375rem;
  color: #fff;
  line-height: 50px;
}

.speakers .speaker-item .image .socials ul li a:hover {
  background: #fff;
}

.speakers .speaker-item .image .socials ul li a:hover i {
  color: #222222;
}

.speakers .speaker-item .image:hover .primary-overlay {
  top: 0;
}

.speakers .speaker-item .image:hover .socials {
  top: 0;
}

.speakers .speaker-item .content h5 a {
  font-size: 22px;
  color: #222222;
  font-weight: 400;
  margin-bottom: 0;
}

.speakers .speaker-item .content h5 a:hover {
  color: #161515;
}

.speakers .speaker-item .content p {
  font-size: 1.875rem;
  margin-bottom: 5px;
}

.schedule .schedule-tab {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

.schedule .schedule-tab ul li.nav-item {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .schedule .schedule-tab ul li.nav-item {
    width: 100%;
    margin-bottom: 10px;
  }
}

.schedule .schedule-tab ul li.nav-item a {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #161515;
  font-weight: 500;
  display: block;
  border-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 20px 30px;
}

.schedule .schedule-tab ul li.nav-item a span {
  margin-top: 2px;
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 1rem;
  color: #848484;
  font-weight: 400;
}

.schedule .schedule-tab ul li.nav-item a.active {
  background: #161515;
  color: #fff;
  position: relative;
}

.schedule .schedule-tab ul li.nav-item a.active span {
  color: #fff;
}

.schedule .schedule-tab ul li.nav-item a.active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #161515 transparent transparent;
}

@media (max-width: 767px) {
  .schedule .schedule-tab ul li.nav-item a.active:after {
    content: none;
  }
}

.schedule .schedule-contents {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
}

.schedule .schedule-contents .schedule-item ul {
  margin-bottom: 0;
}

.schedule .schedule-contents .schedule-item ul li {
  list-style: none;
}

.schedule .schedule-contents .schedule-item ul li div {
  display: inline-block;
}

.schedule .schedule-contents .schedule-item ul li .time {
  width: 20%;
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .time {
    width: 37%;
  }
}

@media (max-width: 575px) {
  .schedule .schedule-contents .schedule-item ul li .time {
    width: 48%;
  }
}

.schedule .schedule-contents .schedule-item ul li .speaker {
  width: 30%;
}

@media (max-width: 991px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 60%;
  }
}

@media (max-width: 575px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .schedule .schedule-contents .schedule-item ul li .speaker img {
    display: none;
  }
}

.schedule .schedule-contents .schedule-item ul li .subject {
  width: 30%;
}

@media (max-width: 1200px) {
  .schedule .schedule-contents .schedule-item ul li .subject {
    display: none;
  }
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .venue {
    display: none;
  }
}

.schedule .schedule-contents .schedule-item li.headings {
  padding: 22px 40px;
  background: #161515;
}

.schedule .schedule-contents .schedule-item li.headings div {
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.schedule .schedule-contents .schedule-item li.schedule-details {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #e5e5e5;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block {
  padding: 10px 40px;
  background: #fff;
  width: 100%;
  transition: 0.2s ease-in;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div {
  color: #222222;
  font-family: "Montserrat", sans-serif;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div i {
  font-size: 1.1875em;
  color: #c7c8c9;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div img {
  border-radius: 100%;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div span.time {
  margin-left: 5px;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div span.name {
  margin-left: 20px;
  transition: 0.2s ease-in;
}

@media (max-width: 575px) {
  .schedule .schedule-contents .schedule-item li.schedule-details .block div span.name {
    margin-left: 0;
  }
}

.schedule .schedule-contents .schedule-item li.schedule-details .block:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.schedule .schedule-contents .schedule-item li.schedule-details .block:hover span.name {
  color: #161515;
}

.schedule .download-button {
  padding-top: 40px;
  margin-bottom: 30px;
}

.schedule.two .schedule-tab {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.schedule.two .schedule-tab ul li.nav-item {
  margin-right: 0;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .schedule.two .schedule-tab ul li.nav-item {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .schedule.two .schedule-tab ul li.nav-item {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.schedule.two .schedule-tab ul li.nav-item a {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  color: #222222;
  font-weight: 500;
  display: block;
  border-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 20px 30px;
}

.schedule.two .schedule-tab ul li.nav-item a span {
  margin-top: 2px;
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 14px;
  color: #848484;
  font-weight: 400;
}

.schedule.two .schedule-tab ul li.nav-item a.active {
  background: #161515;
  color: #fff;
  position: relative;
}

.schedule.two .schedule-tab ul li.nav-item a.active span {
  color: #fff;
}

.schedule.two .schedule-tab ul li.nav-item a.active:after {
  content: "";
  position: absolute;
  left: 100%;
  right: 0;
  bottom: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #161515;
}

@media (max-width: 991px) {
  .schedule.two .schedule-tab ul li.nav-item a.active:after {
    content: none;
  }
}

.schedule.two .schedule-contents {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0;
}

.schedule.two .schedule-contents .schedule-item ul {
  margin-bottom: 0;
}

.schedule.two .schedule-contents .schedule-item ul li {
  list-style: none;
}

.schedule.two .schedule-contents .schedule-item ul li div {
  display: inline-block;
}

.schedule.two .schedule-contents .schedule-item ul li .time {
  width: 20%;
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .time {
    width: 37%;
  }
}

@media (max-width: 575px) {
  .schedule.two .schedule-contents .schedule-item ul li .time {
    width: 48%;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .speaker {
  width: 30%;
}

@media (max-width: 991px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 60%;
  }
}

@media (max-width: 575px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .subject {
  width: 30%;
}

@media (max-width: 1200px) {
  .schedule.two .schedule-contents .schedule-item ul li .subject {
    display: none;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .venue {
  width: 18.6%;
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .venue {
    display: none;
  }
}

.schedule.two .schedule-contents .schedule-item li.headings {
  padding: 25px 0;
  background: #161515;
}

.schedule.two .schedule-contents .schedule-item li.headings div {
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block {
  text-align: center;
  padding: 0;
  background: #fff;
  width: 100%;
  transition: 0.2s ease-in;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div {
  padding-top: 25px;
  padding-bottom: 25px;
  color: #222222;
  font-family: "Montserrat", sans-serif;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div i {
  font-size: 1.1875em;
  color: #c7c8c9;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div img {
  border-radius: 100%;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.time {
  margin-left: 0px;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.name {
  margin-left: 0px;
  transition: 0.2s ease-in;
}

@media (max-width: 575px) {
  .schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.name {
    margin-left: 0;
  }
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:hover {
  box-shadow: none;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:hover span.name {
  color: #161515;
}

.ticket-feature {
  overflow: hidden;
}

.ticket-feature .block {
  position: relative;
  padding-top: 150px;
  padding-bottom: 110px;
}

.ticket-feature .block .section-title,
.ticket-feature .block a {
  position: relative;
}

.ticket-feature .block .section-title h3 {
  font-weight: 500;
}

.ticket-feature .block .timer {
  position: relative;
  margin-top: 60px;
  margin-bottom: 35px;
}

.ticket-feature .block .timer .syotimer-cell {
  margin-right: 25px;
  margin-bottom: 33px;
  display: inline-block;
}

@media (max-width: 575px) {
  .ticket-feature .block .timer .syotimer-cell {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.ticket-feature .block .timer .syotimer-cell .syotimer-cell__value {
  min-width: 80px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.35);
  background-clip: content-box;
  background: rgba(255, 255, 255, 0.26);
  margin-bottom: 8px;
  border-radius: 100%;
}

@media (max-width: 575px) {
  .ticket-feature .block .timer .syotimer-cell .syotimer-cell__value {
    font-size: 30px;
    height: 80px;
    width: 80px;
    line-height: 80px;
  }
}

.ticket-feature .block .timer .syotimer-cell .syotimer-cell__unit {
  text-align: center;
  font-size: 1rem;
  text-transform: lowercase;
  font-weight: normal;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.ticket-feature .block-2 {
  position: relative;
  padding: 150px 10%;
  height: 100%;
  background: #161515;
}

@media (max-width: 575px) {
  .ticket-feature .block-2 {
    padding: 50px 20px;
  }
}

.ticket-feature .block-2 [class*=col-]:first-child {
  border-right: 1px solid #161515;
  border-bottom: 1px solid #161515;
}

.ticket-feature .block-2 [class*=col-]:nth-child(2) {
  border-bottom: 1px solid #161515;
}

.ticket-feature .block-2 [class*=col-]:nth-child(3) {
  border-right: 1px solid #161515;
}

.ticket-feature .block-2 .service-item {
  padding: 30px;
  text-align: center;
}

.ticket-feature .block-2 .service-item i,
.ticket-feature .block-2 .service-item h5 {
  color: #fff;
}

.ticket-feature .block-2 .service-item i {
  font-size: 3.25rem;
  margin-bottom: 20px;
}

.ticket-feature .block-2 .service-item h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  .ticket-feature .block-2 .service-item h5 {
    font-size: 1rem;
  }
}

.ticket-feature .block-2:after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 1000px 300px;
  border-color: transparent transparent #161515 transparent;
}

@media (max-width: 1200px) {
  .ticket-feature .block-2:after {
    content: none;
  }
}

.registration {
  overflow: hidden;
}

.registration .service-block {
  position: relative;
  padding: 120px 10%;
}

.registration .service-block [class*=col-]:first-child {
  border-right: 1px solid #161515;
  border-bottom: 1px solid #161515;
}

.registration .service-block [class*=col-]:nth-child(2) {
  border-bottom: 1px solid #161515;
}

.registration .service-block [class*=col-]:nth-child(3) {
  border-right: 1px solid #161515;
}

.registration .service-block .service-item {
  padding: 30px;
  text-align: center;
}

.registration .service-block .service-item i,
.registration .service-block .service-item h5 {
  color: #fff;
}

.registration .service-block .service-item i {
  font-size: 3.25rem;
  margin-bottom: 20px;
}

.registration .service-block .service-item h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  .registration .service-block .service-item h5 {
    font-size: 12px;
  }
}

.registration .registration-block {
  position: relative;
  height: 100%;
  padding: 120px 10%;
}

.registration .registration-block .block {
  position: relative;
  z-index: 9;
}

.registration .registration-block .block .title {
  margin-bottom: 35px;
}

.registration .registration-block .block .title h3 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

.registration .registration-block .block .title p {
  color: #b3b3b3;
  line-height: 1;
}

.pricing .pricing-item {
  border: 1px solid #e5e5e5;
}

.pricing .content-block h2:after {
  content: "";
  width: 60px;
  height: 3px;
  background: #161515;
  display: block;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .pricing .pricing-item {
    margin-bottom: 30px;
  }
}

.pricing .pricing-item .pricing-heading {
  padding: 20px 40px 30px 40px;
  background: #fafafa;
  border-bottom: 1px solid #e5e5e5;
}

.pricing .pricing-item .pricing-heading .title h6 {
  text-transform: uppercase;
  font-weight: 400;
  line-height: 50px;
  border-bottom: 1px solid #e5e5e5;
}

.pricing .pricing-item .pricing-heading .price {
  margin-top: 28px;
}

.pricing .pricing-item .pricing-heading .price h2 {
  font-size: 3.625rem;
  font-weight: 400;
  margin-bottom: 0px;
}

.pricing .pricing-item .pricing-heading .price h2 span {
  font-size: 1.5625rem;
}

.pricing .pricing-item .pricing-body {
  padding: 10px 15px;
}

.pricing .pricing-item .pricing-body-2 {
  padding: 45px 40px;
}

.pricing .pricing-item .pricing-body ul.feature-list li {
  list-style: none;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span {
  margin-right: 15px;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span.available {
  color: #161515;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span.unavailable {
  color: #d2d2d2;
}

.pricing .pricing-item .pricing-body ul.feature-list li:not(:last-child) {
  margin-bottom: 15px;
}

.pricing .pricing-item .pricing-footer {
  padding-bottom: 40px;
}

.pricing .pricing-item.featured {
  border: none;
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.pricing .pricing-item.featured .pricing-heading {
  background: #161515;
  border-bottom: 1px solid #161515;
}

.pricing .pricing-item.featured .pricing-heading .title h6 {
  color: #fff;
  border-bottom: 1px solid #161515;
}

.pricing .pricing-item.featured .pricing-heading .price {
  margin-top: 28px;
}

.pricing .pricing-item.featured .pricing-heading .price h2 {
  color: #fff;
  font-size: 3.625rem;
  margin-bottom: 0px;
}

.pricing .pricing-item.featured .pricing-heading .price h2 span {
  font-size: 1.5625rem;
}

.pricing .pricing-item.featured .pricing-heading .price p {
  color: #fff;
}

.pricing.two .pricing-item {
  border: 1px solid #e5e5e5;
  overflow: hidden;
}

@media (max-width: 991px) {
  .pricing.two .pricing-item {
    margin-bottom: 30px;
  }
}

.pricing.two .pricing-item .pricing-heading {
  position: relative;
  margin-bottom: 10px;
}

.pricing.two .pricing-item .pricing-heading .title h6 {
  position: relative;
}

.pricing.two .pricing-item .pricing-heading .price {
  position: relative;
}

.pricing.two .pricing-item .pricing-heading:before {
  content: "";
  position: absolute;
  bottom: -25%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 64px 500px 0 0;
  border-color: #fafafa transparent transparent transparent;
}

@media (max-width: 991px) {
  .pricing.two .pricing-item .pricing-heading:before {
    content: none;
  }
}

.pricing.two .pricing-item .pricing-body {
  padding: 70px 40px 45px;
}

.pricing.two .pricing-item .pricing-body ul.feature-list li p span.available {
  color: #161515;
}

.pricing.two .pricing-item.featured .pricing-heading:before {
  border-color: #161515 transparent transparent transparent;
}

.sponsors {
  position: relative;
}

.sponsors .sponsor-title {
  margin-top: 10px;
  margin-bottom: 30px;
}

.sponsors .sponsor-title h5 {
  color: #161515;
}

.sponsors .block {
  margin-bottom: 40px;
}

.sponsors .block .list-inline-item:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 991px) {
  .sponsors .block .list-inline-item:not(:last-child) {
    margin-right: 7px;
    margin-left: 7px;
  }
}

@media (max-width: 991px) {
  .sponsors .block .list-inline-item:last-child {
    margin-right: 7px;
    margin-left: 7px;
  }
}

.sponsors .block .image-block {
  padding: 15px 0;
  background: #fff;
  width: 374px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  margin-bottom: 10px;
  box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
}

.sponsors .block .image-block img {
  height: 55px;
}

.sponsors .block .image-block:hover {
  border: 1px solid #161515;
}

.sponsors .sponsor-btn {
  margin-top: 10px;
  margin-bottom: 30px;
}

.map {
  position: relative;
}

.map #map {
  height: 480px;
  color: white;
}

.map .address-block {
  position: absolute;
  padding: 45px 50px 50px 50px;
  top: 80px;
  left: 10%;
  background: #161515;
}

@media (max-width: 575px) {
  .map .address-block {
    display: none;
  }
}

.map .address-block h4,
.map .address-block li {
  color: #fff;
  margin-bottom: 20px;
}

.map .address-block h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

.map .address-block ul.address-list li {
  list-style: none;
  margin-bottom: 10px;
}

.map .address-block ul.address-list li i {
  font-size: 1.125rem;
}

.map .address-block ul.address-list li span {
  margin-left: 15px;
}

.map .address-block a {
  margin-top: 35px;
}

.map.new {
  overflow: hidden;
}

.map.new #map {
  height: 640px;
}

.map.new .address-block {
  left: 60%;
}

@media (max-width: 991px) {
  .map.new .address-block {
    left: 52%;
  }
}

@media (max-width: 767px) {
  .map.new .address-block {
    left: 5%;
  }
}

.map.new .register {
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 767px) {
  .map.new .register {
    width: 100%;
    position: relative;
  }
}

.map.new .register .block {
  padding: 130px 10%;
}

.map.new .register .block .title {
  position: relative;
  margin-bottom: 65px;
}

.map.new .register .block .title h3 {
  color: #fff;
  font-weight: 400;
}

.map.new .register .block .title p {
  color: #fff;
}

.map.new .register .block .form-control,
.map.new .register .block button {
  margin-bottom: 40px;
}

.feature .feature-content h2,
.feature .feature-content p {
  margin-bottom: 25px;
}

@media (max-width: 767px) {

  .feature .feature-content h2,
  .feature .feature-content p {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .feature .testimonial {
    text-align: center;
  }
}

.feature .testimonial p {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  font-style: italic;
  color: #242424;
}

.feature .testimonial ul.meta li {
  font-size: 12px;
  margin-right: 10px;
}

.feature .testimonial ul.meta li img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

@media (max-width: 575px) {
  .app-features .app-feature {
    margin-bottom: 30px;
  }
}

.app-features .app-explore {
  display: flex;
  justify-content: center !important;
  margin-bottom: 40px;
}

.banner-full .image {
  display: flex;
  justify-content: center;
}

.banner-full .image img {
  height: 625px;
}

@media (max-width: 767px) {
  .banner-full .image {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .banner-full .block {
    text-align: center;
  }
}

.banner-full .block .logo {
  margin-bottom: 40px;
}

.banner-full .block h1 {
  margin-bottom: 40px;
}

.banner-full .block p {
  font-size: 20px;
  margin-bottom: 50px;
}

.banner-full .block .app {
  margin-bottom: 20px;
}

.video-promo {
  padding: 150px 0;
}

.video-promo .content-block {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.video-promo .content-block h2 {
  font-size: 30px;
  color: #fff;
}

.video-promo .content-block p {
  margin-bottom: 30px;
}

.video-promo .content-block a i.video {
  height: 125px;
  width: 125px;
  background: #161515;
  display: inline-block;
  font-size: 40px;
  color: #fff;
  text-align: center;
  line-height: 125px;
  border-radius: 100%;
}

.video-promo .content-block a:focus {
  outline: 0;
}

.testimonial .testimonial-slider .item {
  padding-bottom: 10px;
}

.testimonial .testimonial-slider .item .block {
  padding: 40px;
  text-align: center;
  margin: 10px;
  border-radius: 5px;
}

.testimonial .testimonial-slider .item .block .image {
  margin-top: 30px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .testimonial .testimonial-slider .item .block .image {
    flex-grow: 1;
  }
}

.testimonial .testimonial-slider .item .block .image img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.testimonial .testimonial-slider .item .block p {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  color: #888888;
}

.testimonial .testimonial-slider .item .block cite {
  font-style: normal;
  font-size: 14px;
  color: #161616;
}

.testimonial .testimonial-slider .owl-dots .owl-dot:hover span {
  background: #161515;
}

.testimonial .testimonial-slider .owl-dots .owl-dot.active span {
  background: #161515;
}

.single-speaker .block {
  padding-bottom: 70px;
  border-bottom: 1px solid #e5e5e5;
}

.single-speaker .block .image-block {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 6px;
}

.single-speaker .block .image-block img {
  width: 100%;
}

@media (max-width: 767px) {
  .single-speaker .block .image-block {
    margin-bottom: 30px;
  }
}

.single-speaker .block .content-block .name h3 {
  font-weight: 400;
}

.single-speaker .block .content-block .profession {
  margin-bottom: 20px;
}

.single-speaker .block .content-block .profession p {
  color: #161515;
  font-size: 0.9375rem;
}

.single-speaker .block .content-block .details {
  margin-bottom: 35px;
}

.single-speaker .block .content-block .details p {
  font-size: 0.9375rem;
}

.single-speaker .block .content-block .details p:not(:last-child) {
  margin-bottom: 35px;
}

.single-speaker .block .content-block .social-profiles h5 {
  font-weight: 400;
  margin-bottom: 25px;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a {
  display: block;
  height: 40px;
  width: 40px;
  background: #fff;
  border: 1px solid #e5e5e5;
  text-align: center;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a i {
  line-height: 40px;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a:hover {
  background: #161515;
  border: 1px solid #161515;
  color: #fff;
}

.single-speaker .block-2 {
  padding-top: 70px;
  padding-bottom: 10px;
}

.single-speaker .block-2 h3,
.single-speaker .block-2 p,
.single-speaker .block-2 li {
  font-size: 0.9375rem;
}

.single-speaker .block-2 .personal-info h5,
.single-speaker .block-2 .skills h5 {
  font-weight: 400;
  margin-bottom: 15px;
}

.single-speaker .block-2 .personal-info p,
.single-speaker .block-2 .skills p {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .single-speaker .block-2 .personal-info {
    margin-bottom: 30px;
  }
}

.single-speaker .block-2 .personal-info ul li {
  position: relative;
  list-style: none;
  margin-left: 25px;
}

.single-speaker .block-2 .personal-info ul li:not(:last-child) {
  margin-bottom: 5px;
}

.single-speaker .block-2 .personal-info ul li:before {
  position: absolute;
  left: -25px;
  content: "\F10C";
  font-family: FontAwesome;
  color: #161515;
}

.single-speaker .block-2 .skills .skill-bar {
  margin-bottom: 20px;
}

.single-speaker .block-2 .skills .skill-bar p {
  margin-bottom: 5px;
}

.single-speaker .block-2 .skills .skill-bar .progress {
  border-radius: 0;
  overflow: visible;
}

.single-speaker .block-2 .skills .skill-bar .progress .progress-bar {
  background-color: #161515;
}

.single-speaker .block-2 .skills .skill-bar .progress .progress-bar span {
  font-family: "Montserrat", sans-serif;
  float: right;
  color: white;
}

.speakers-full-width {
  overflow: hidden;
}

.speakers-full-width .speaker-slider .speaker-image {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.speakers-full-width .speaker-slider .speaker-image img {
  width: 100%;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161515;
  opacity: 0.85;
  transition: 0.3s ease;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay p,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay i {
  color: #fff;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5 {
  padding-top: 25%;
  margin-bottom: 10px;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay ul,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  transition: 0.7s ease;
  -webkit-transform: translate(0, 30px);
          transform: translate(0, 30px);
  opacity: 0;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  margin-bottom: 10px;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay ul .list-inline-item:not(:last-child) {
  margin-right: 10px;
}

.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay {
  top: 0;
}

.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay h5,
.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay p,
.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay ul {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  opacity: 1;
}

.speakers-full-width .speaker-slider .slick-prev {
  left: 60px;
}

@media (max-width: 767px) {
  .speakers-full-width .speaker-slider .slick-prev {
    left: 20px;
  }
}

.speakers-full-width .speaker-slider .slick-prev:before {
  content: "\F104";
}

.speakers-full-width .speaker-slider .slick-next {
  right: 60px;
}

@media (max-width: 767px) {
  .speakers-full-width .speaker-slider .slick-next {
    right: 20px;
  }
}

.speakers-full-width .speaker-slider .slick-next:before {
  content: "\F105";
}

.speakers-full-width .speaker-slider .slick-prev,
.speakers-full-width .speaker-slider .slick-next {
  background: #fff;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  z-index: 100;
}

@media (max-width: 1024px) {

  .speakers-full-width .speaker-slider .slick-prev,
  .speakers-full-width .speaker-slider .slick-next {
    height: 35px;
    width: 35px;
  }
}

.speakers-full-width .speaker-slider .slick-prev:hover:before,
.speakers-full-width .speaker-slider .slick-next:hover:before {
  color: #161515;
}

.speakers-full-width .speaker-slider .slick-prev:before,
.speakers-full-width .speaker-slider .slick-next:before {
  color: #161515;
  font-family: FontAwesome;
}

/* Controls
---------------------------------------------------------------------- */
.controls {
  text-align: center;
  margin-bottom: 40px;
}

.controls .btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  margin-right: 25px;
}

@media (max-width: 767px) {
  .controls .btn {
    margin-right: 10px;
  }
}

.controls .btn.focus,
.controls .btn:focus {
  outline: 0;
  box-shadow: none;
}

.controls .btn:hover {
  color: #161515;
}

.mixitup-control-active {
  color: #161515;
}

/* Container
---------------------------------------------------------------------- */
.gallery-wrapper {
  /* Target Elements
    ---------------------------------------------------------------------- */
  /* Grid Breakpoints
    ---------------------------------------------------------------------- */
  /* 2 Columns */
  /* 3 Columns */
}

.gallery-wrapper .gallery-item {
  padding: 15px;
}

.gallery-wrapper .gallery-item .image-block {
  padding: 6px;
  border: 1px solid #e5e5e5;
}

.gallery-wrapper .gallery-item .image-block .image {
  position: relative;
  overflow: hidden;
}

.gallery-wrapper .gallery-item .image-block .image img {
  width: 100%;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161515;
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: 0.3s ease;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay a {
  display: block;
  height: 55px;
  width: 55px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  outline: none;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay a i {
  color: #fff;
  line-height: 55px;
  font-size: 1.25rem;
}

.gallery-wrapper .gallery-item .image-block .image:hover .primary-overlay {
  opacity: 0.85;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.gallery-wrapper .mix,
.gallery-wrapper .gap {
  display: inline-block;
  vertical-align: top;
}

.gallery-wrapper .mix {
  background: #fff;
  position: relative;
}

.gallery-wrapper .mix {
  width: calc(50% - 0.5rem);
}

@media screen and (min-width: 768px) {
  .gallery-wrapper .mix {
    width: calc(33.3333333333% - 0.6666666667rem);
  }
}

.gallery-wrapper.less-gutter .gallery-item {
  padding: 5px;
}

.gallery-wrapper.less-gutter .gallery-item .image-block {
  padding: 0;
  border: none;
}

@media screen and (min-width: 1200px) {

  .gallery-wrapper.less-gutter .mix,
  .gallery-wrapper.less-gutter .gap {
    width: calc(25% - 0.75rem);
  }
}

.gallery-full {
  overflow: hidden;
}

.gallery-full .image {
  position: relative;
  overflow: hidden;
  margin-bottom: -1px;
}

.gallery-full .image img {
  width: 100%;
}

.gallery-full .image .primary-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161515;
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: 0.3s ease;
}

.gallery-full .image .primary-overlay a {
  display: block;
  height: 55px;
  width: 55px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  outline: none;
}

.gallery-full .image .primary-overlay a i {
  color: #fff;
  line-height: 55px;
  font-size: 1.25rem;
}

.gallery-full .image:hover .primary-overlay {
  opacity: 0.85;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.news .blog-post {
  transition: 0.2s ease;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .news .blog-post .post-thumb img {
    width: 100%;
  }
}

.news .blog-post .post-content {
  position: relative;
  padding: 30px 30px 25px 30px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #e5e5e5;
}

.news .blog-post .post-content .date {
  position: absolute;
  top: -32px;
  left: 30px;
  width: 65px;
  height: 65px;
  text-align: center;
  background: #161515;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news .blog-post .post-content .date h4 {
  color: #fff;
}

.news .blog-post .post-content .date h4 span {
  display: block;
  font-size: 1rem;
  font-weight: 400;
}

.news .blog-post .post-content .post-title {
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}

.news .blog-post .post-content .post-title h2 {
  margin-top: 10px;
}

.news .blog-post .post-content .post-title h2 a {
  font-weight: 400;
  color: #222222;
}

.news .blog-post .post-content .post-title h2 a:hover {
  color: #161515;
}

.news .blog-post .post-content .post-meta ul {
  margin-bottom: 0;
}

.news .blog-post .post-content .post-meta ul li i {
  margin-right: 7px;
}

.news .blog-post .post-content .post-meta ul li a {
  display: inline-block;
  padding-right: 25px;
  font-size: 0.875rem;
}

.news .blog-post:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.news .blog-post.single {
  margin-bottom: 100px;
}

.news .blog-post.single .post-title {
  padding-top: 35px;
  padding-bottom: 0;
  border: none;
  margin-bottom: 10px;
}

.news .blog-post.single .post-title h3 {
  font-weight: 400;
}

.news .blog-post.single .post-meta {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.news .blog-post.single .post-details p {
  padding: 13px 0;
  font-size: 15px;
}

.news .blog-post.single .post-details .quotes {
  background: #f5f5f8;
  padding: 30px;
  border-left: 3px solid #161515;
  margin: 15px 0;
}

.news .blog-post.single .post-details .quotes blockquote {
  font-size: 15px;
  font-style: italic;
}

.news .blog-post.single .post-details .share-block {
  display: flex;
  padding-top: 20px;
  padding-bottom: 25px;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block {
    flex-wrap: wrap;
  }
}

.news .blog-post.single .post-details .share-block p {
  padding: 0;
}

.news .blog-post.single .post-details .share-block .tag {
  flex-basis: 50%;
  align-self: center;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block .tag {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 30px;
    flex-grow: 1;
  }
}

.news .blog-post.single .post-details .share-block .tag p,
.news .blog-post.single .post-details .share-block .tag ul {
  display: inline;
  font-family: "Montserrat", sans-serif;
}

.news .blog-post.single .post-details .share-block .tag p {
  color: #222222;
}

.news .blog-post.single .post-details .share-block .tag ul li a {
  color: #848484;
  font-size: 15px;
}

.news .blog-post.single .post-details .share-block .tag ul li a:hover {
  color: #161515;
}

.news .blog-post.single .post-details .share-block .share {
  flex-basis: 50%;
  text-align: right;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block .share {
    flex-basis: 100%;
    flex-grow: 1;
    text-align: center;
  }
}

.news .blog-post.single .post-details .share-block .share p,
.news .blog-post.single .post-details .share-block .share ul {
  display: inline;
  font-family: "Montserrat", sans-serif;
}

.news .blog-post.single .post-details .share-block .share p {
  margin-right: 18px;
}

@media (max-width: 400px) {
  .news .blog-post.single .post-details .share-block .share p {
    display: block;
    margin-bottom: 10px;
  }
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share {
  margin-bottom: 0;
}

@media (max-width: 400px) {
  .news .blog-post.single .post-details .share-block .share ul.social-links-share {
    text-align: left;
  }
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share .list-inline-item:not(:last-child) {
  margin-right: 7px;
}

@media (max-width: 400px) {
  .news .blog-post.single .post-details .share-block .share ul.social-links-share .list-inline-item:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a {
  display: block;
  height: 38px;
  width: 38px;
  text-align: center;
  background: #fff;
  border: 1px solid #e5e5e5;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a i {
  line-height: 38px;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a:hover {
  background: #161515;
  border-color: #161515;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a:hover i {
  color: #fff;
}

.news .blog-post.single:hover {
  box-shadow: none;
}

.news .comments {
  padding-bottom: 80px;
}

.news .comments h5 {
  font-weight: 400;
  margin-bottom: 10px;
}

.news .comments .comment {
  padding-top: 14px;
}

.news .comments .comment img {
  margin-right: 30px;
}

.news .comments .comment .media-body h6 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.news .comments .comment .media-body p,
.news .comments .comment .media-body ul {
  font-size: 15px;
}

.news .comments .comment .media-body p {
  margin-bottom: 14px;
}

.news .comments .comment .media-body ul {
  margin-bottom: 10px;
}

.news .comments .comment .media-body ul li span {
  margin-right: 7px;
}

.news .comments .comment .media-body ul li a {
  color: #161515;
}

.news .comment-form h5 {
  font-weight: 400;
  margin-bottom: 30px;
}

.news .comment-form .form-control,
.news .comment-form button {
  margin-bottom: 30px;
}


.sidebar {
  padding: 0 20px;
}

.widget {
  margin-bottom: 45px;
}

.widget .widget-header {
  font-weight: 400;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .widget.search {
    margin-top: 30px;
  }
}

.widget.search .input-group .form-control {
  border-right: none;
}

.widget.search .input-group .input-group-addon {
  background: #161515;
  color: #fff;
  border: none;
  border-radius: 0;
  line-height: 45px;
  padding: 0 10px;
}

.widget.search .input-group .input-group-addon i {
  font-size: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.widget.category ul.category-list li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  list-style: none;
}

.widget.category ul.category-list li a {
  font-size: 15px;
  letter-spacing: 0;
  display: block;
}

.widget.category ul.category-list li a:hover {
  color: #161515;
}

.widget.category ul.category-list li:last-child {
  margin-bottom: 5px;
}

.widget.tags ul li a {
  display: block;
  padding: 10px 25px;
  border: 1px solid #e5e5e5;
  margin-bottom: 15px;
  font-size: 15px;
}

.widget.tags ul li a:hover {
  background: #161515;
  color: #fff;
  border-color: #161515;
}

.widget.latest-post .media {
  padding: 15px 0;
}

.widget.latest-post .media:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.widget.latest-post .media img {
  margin-right: 15px;
}

.widget.latest-post .media .media-body h6 a {
  font-size: 15px;
  color: #222222;
  line-height: 1;
  font-weight: 400;
}

.widget.latest-post .media .media-body h6 a:hover {
  color: #161515;
}

.widget.latest-post .media .media-body p span {
  margin-right: 10px;
}

.news-hr .news-post-hr {
  display: flex;
}

@media (max-width: 1200px) {
  .news-hr .news-post-hr {
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .news-hr .news-post-hr {
    margin-bottom: 30px;
  }
}

.news-hr .news-post-hr .post-thumb {
  flex-basis: 50%;
}

@media (max-width: 1200px) {
  .news-hr .news-post-hr .post-thumb {
    flex-basis: 100%;
  }
}

.news-hr .news-post-hr .post-thumb img {
  width: 100%;
}

.news-hr .news-post-hr .post-contents {
  flex-basis: 50%;
  padding: 30px;
  position: relative;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: #e5e5e5;
}

@media (max-width: 1200px) {
  .news-hr .news-post-hr .post-contents {
    border-left: 1px solid #e5e5e5;
    flex-basis: 100%;
  }
}

.news-hr .news-post-hr .post-contents .post-title h6 {
  margin-bottom: 36px;
  margin-top: 5px;
}

.news-hr .news-post-hr .post-contents .post-title h6 a {
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  font-size: 20px;
  color: #222222;
}

.news-hr .news-post-hr .post-contents .post-title h6 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 37px;
  content: "";
  background: #161515;
  width: 30px;
  height: 2px;
}

.news-hr .news-post-hr .post-contents .post-exerpts p {
  font-size: 15px;
}

.news-hr .news-post-hr .post-contents .date {
  margin-top: 17px;
  width: 65px;
  height: 65px;
  text-align: center;
  background: #161515;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-hr .news-post-hr .post-contents .date h4 {
  color: #fff;
  font-weight: 400;
}

.news-hr .news-post-hr .post-contents .date h4 span {
  display: block;
  font-size: 1rem;
  font-weight: 400;
}

.news-hr .news-post-hr .post-contents .more {
  text-align: right;
  margin-top: -25px;
}

.news-hr .news-post-hr .post-contents .more a {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  text-transform: uppercase;
  font-size: 14px;
}

.news-hr .news-post-hr .post-contents:after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
}

@media (max-width: 1200px) {
  .news-hr .news-post-hr .post-contents:after {
    content: none;
  }
}

.about {
  padding: 120px 0;
}

.testimonial .testimonial-item {
  margin-bottom: 30px;
}

.testimonial .testimonial-item .comment {
  padding: 40px 30px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  position: relative;
  transition: 0.3s ease;
}

.testimonial .testimonial-item .comment:before {
  content: "";
  position: absolute;
  left: -2px;
  bottom: -27px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 27px 0;
  border-color: transparent #e5e5e5 transparent transparent;
  transition: 0.3s ease;
}

.testimonial .testimonial-item .comment:after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: -26px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 28px 27px 0;
  border-color: transparent #fff transparent transparent;
  transition: 0.3s ease;
}

.testimonial .testimonial-item .comment:hover {
  background: #161515;
  border: 1px solid #161515;
}

.testimonial .testimonial-item .comment:hover p {
  color: #fff;
}

.testimonial .testimonial-item .comment:hover:before {
  border-color: transparent #161515 transparent transparent;
}

.testimonial .testimonial-item .comment:hover:after {
  border-color: transparent #161515 transparent transparent;
}

.testimonial .testimonial-item .media {
  margin-left: 50px;
}

.testimonial .testimonial-item .media img {
  height: 65px;
  width: 65px;
  border-radius: 100%;
}

.testimonial .testimonial-item .media .media-body {
  margin-left: 15px;
  align-self: center;
}

.testimonial .testimonial-item .media .media-body .name p {
  font-family: "Montserrat", sans-serif;
  color: #222222;
}

.testimonial .testimonial-item .media .media-body .profession p {
  font-size: 0.8126rem;
  color: #161515;
}

.error .block {
  margin-top: 60px;
  margin-bottom: 60px;
}

.error .block img {
  margin-bottom: 45px;
}

.error .block h3 {
  font-weight: 400;
  color: #161515;
  margin-bottom: 40px;
}

.error .block h3 span {
  font-style: italic;
  color: #222222;
}

.faq .accordion-block #accordion .card,
.faq .accordion-block #accordionTwo .card {
  border-radius: 0;
  margin-bottom: 20px;
}

.faq .accordion-block #accordion .card .card-header,
.faq .accordion-block #accordionTwo .card .card-header {
  background: #fff;
  padding: 0;
}

.faq .accordion-block #accordion .card .card-header h5 a,
.faq .accordion-block #accordionTwo .card .card-header h5 a {
  font-weight: 400;
  color: #222222;
}

.faq .accordion-block #accordion .card .card-header h5 a span,
.faq .accordion-block #accordionTwo .card .card-header h5 a span {
  padding: 20px;
  border-right: 1px solid #e5e5e5;
  margin-right: 20px;
}

.faq .accordion-block #accordion .card .card-body,
.faq .accordion-block #accordionTwo .card .card-body {
  color: #848484;
  padding: 15px 20px;
}

.faq .accordion-block #accordion .card .collapse.show+.card-header h5 a,
.faq .accordion-block #accordionTwo .card .collapse.show+.card-header h5 a {
  color: #161515;
}

.contact-form .form-control {
  margin-bottom: 30px;
}

.contact-form button {
  margin-top: 30px;
  margin-bottom: 30px;
}

/*# sourceMappingURL=style.css.map */



.event-table {
  width: 100%;
}

.event-table td,
.event-table th {
  color: #2b686e;
  padding: 10px;
}

.event-table td {
  text-align: center;
  vertical-align: middle;
}

.event-table td:last-child {
  font-size: 0.95em;
  line-height: 1.4;
  text-align: left;
}

.event-table th {
  background-color: #daeff1;
  font-weight: 300;
}

.event-table tr:nth-child(2n) {
  background-color: white;
}

.event-table tr:nth-child(2n+1) {
  background-color: #edf7f8;
}
.hr_card {
    position: relative;
}

.hr_card .sponsor-title {
    margin-top: 10px;
    margin-bottom: 30px;
}

.hr_card .sponsor-title h5 {
    color: #161515;
}

.hr_card .block {
    margin-bottom: 40px;
}

.hr_card .block .list-inline-item:not(:last-child) {
    margin-right: 15px;
}

@media (max-width: 991px) {
    .hr_card .block .list-inline-item:not(:last-child) {
        margin-right: 17px;
        /* margin-left: 27px; */
    }
}

@media (max-width: 991px) {
    .hr_card .block .list-inline-item:last-child {
        margin-right: 7px;
        /* margin-left: 7px; */
    }
}



.hr_card .block .image-block {
    padding: 15px 0;
    background: #fff;
    width: 374px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid transparent;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
    -webkit-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
}


@media (max-width: 991px) {
    .hr_card .block .image-block {
        padding: 15px 0;
        background: #fff;
        width: 274px;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid transparent;
        padding: 10px;
        margin-bottom: 10px;
        box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
        -webkit-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
        -moz-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
    }
}


.hr_card .block .image-block img {
    height: 55px;
}

.hr_card .block .image-block:hover {
    border: 1px solid #161515;
}

.hr_card .sponsor-btn {
    margin-top: 10px;
    margin-bottom: 30px;
}


.hr_card_flex_container {
    display: flex;
    flex-wrap: nowrap;
    background-color: rgb(218, 219, 221);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

.hr_card_flex_container .item {
    background-color: #f1f1f1;
    width: 100px;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}

@media (max-width: 991px) {
    .hr_card_flex_container {
        display:block;
        /* flex-wrap: nowrap; */
        background-color: rgb(218, 219, 221);
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        text-align: left;
    }

}
/**
 * WEBSITE: https://themefisher.com
 * TWITTER: https://twitter.com/themefisher
 * FACEBOOK: https://www.facebook.com/themefisher
 * GITHUB: https://github.com/themefisher/
 */

/*=== MEDIA QUERY ===*/

.form-control::-webkit-input-placeholder {
  color: #b3b3b3;
  font-size: 0.9375rem;
}

/* ul.social-links {
  margin-bottom: 0;
}
ul.social-links li:first-child a {
  padding-left: 0;
}
ul.social-links li a {
  padding: 0 15px;
  display: block;
}
ul.social-links li a i {
  font-size: 20px;
  color: #222222;
} */

.shadow,
.testimonial .testimonial-item .comment:hover,
.news-hr .news-post-hr:hover,
ul.pagination .active a,
ul.pagination li a:hover,
ul.pagination li a:focus,
.sponsors .block .image-block:hover,
.schedule.two .schedule-contents,
.speakers.white .speaker-item:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

/* .overlay:before {
  content: "";
  background: url(../images/background/objects.png) no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */

.overlay:after {
  content: "";
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-lighter:before {
  content: "";
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text-overlay:after {
  content: "CONFERENCE";
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 215px;
  width: 100%;
  height: 300px;
  background-position: center center;
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: 0.05;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media (max-width: 767px) {
  .text-overlay:after {
    font-size: 100px;
  }
}

@media (max-width: 575px) {
  .text-overlay:after {
    font-size: 70px;
  }
}

.overlay-primary:before {
  content: "";
  background: rgba(255, 102, 0, 0.93);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-dark:before {
  content: "";
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-dark-2:before {
  content: "";
  background: -webkit-linear-gradient(-45deg, #0e315a 0%, #010103 0%, #050208 100%, #207cca 100%);
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-white:before {
  content: "";
  background: linear-gradient(0deg, rgba(245, 245, 248, 0.8) 0%, rgb(247, 247, 247) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-white-slant:before {
  content: "";
  width: 100%;
  margin-left: -45%;
  background: rgb(255, 255, 255);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: skewX(-20deg);
          transform: skewX(-20deg);
}

@media (max-width: 767px) {
  .overlay-white-slant:before {
    content: none;
  }
}

/* 
span.alternate {
  color: #161515;
  font-style: italic;
  font-weight: 400;
}

a {
  transition: 0.2s ease;
}

a:focus, a:hover {
  color: #161515;
  text-decoration: none;
} */

/* button:hover {
  cursor: pointer;
} */

.bg-gray {
  background: #fafafa;
}

.bg-blue {
  background: #161515;
}

/* .bg-banner-one {
  background: url(../images/background/welcome-banner.jpeg) no-repeat;
  background-size: cover;
  background-position: center center;
} */



@media (max-width: 767px) {
  .bg-banner-two {
    background-position: center center;
  }
}


.bg-speaker {
  background: url(/static/media/spaeker-background.4baf8294.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}


.bg-subscribe {
  background: url(/static/media/organizers.2ef8a623.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}

/* .bg-title {
  background: url(../images/background/page-title-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
} */

.section {
  padding: 40px 0;
}

.border-less {
  border-bottom: 1px solid #e5e5e5;
}

.section-title {
  margin-top: 5px;
  text-align: center;
  margin-bottom: 20px;
}

.section-title h3 {
  margin-bottom: 0px;
}

.section-title.white h3,
.section-title.white p {
  color: #fff;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.page-title {
  text-align: center;
}

.video {
  position: relative;
}

.video:before {
  border-radius: 3px;
}

.video img {
  width: 100%;
  border-radius: 8px;
}

.video .video-button {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video .video-box a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video .video-box a i {
  height: 125px;
  width: 125px;
  font-size: 40px;
  background: #161515;
  border-radius: 100%;
  color: #fff;
  line-height: 125px;
  text-align: center;
}

.video .video-box a iframe {
  width: 100%;
  height: 100%;
}

.form-control {
  background: transparent;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  font-size: 16px;
  padding: 15px 20px;
  height: 48px;
  margin-bottom: 20px;
  color: #b3b3b3;
}

.form-control:focus {
  border: 1px solid #ffffff;
  box-shadow: none;
}

.form-control.white {
  background: #fff;
  color: #000000e5;
}

select.form-control:not([size]):not([multiple]) {
  height: 100%;
}

.left {
  overflow: hidden;
}

.left img {
  margin-left: -40px;
}

@media (max-width: 767px) {
  .left img {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.right {
  overflow: hidden;
}

.right img {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .right img {
    margin-left: 0;
  }
}

.hide-overflow {
  overflow: hidden;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  /* ideally, transition speed should match zoom duration */
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.border-top {
  border-top: 1px solid;
}

.slick-slide:focus {
  outline: 0;
}

.btn {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease;
}

.btn-main {
  padding: 25px 45px;
  border-radius: 3px;
  background: #161515;
  color: #fff;
  outline: none;
}

.btn-main:hover {
  color: #fff;
}

.btn-main:focus {
  color: #fff;
  box-shadow: none;
}

.btn-main-md {
  padding: 15px 40px;
  border-radius: 0;
  background: #4e4d4d;
  color: #fff;
  outline: none;
  font-size: 1.9375rem;
}

.btn-main-md:hover {
  color: #fff;
  background: #161515;
}

.btn-main-md:focus {
  color: white;
  box-shadow: none;
}

.btn-transparent-md {
  padding: 15px 40px;
  background: transparent;
  outline: none;
  font-size: 1.9375rem;
  color: black;
  border: 1px solid #e5e5e5;
  border-radius: 0;
}

.btn-transparent-md:hover {
  background: #161515;
  border-color: #161515;
  color: #fff;
}

.btn-main-sm {
  padding: 15px 35px;
  border-radius: 3px;
  background: #161515;
  color: #fff;
  outline: none;
  font-size: 14px;
}

.btn-main-sm:hover {
  color: #fff;
}

.btn-main-sm:focus {
  color: #fff;
  box-shadow: none;
}

.btn-white-md {
  background: #fff;
  color: #222222;
  padding: 15px 40px;
  border-radius: 0;
}

.btn-white-md:hover {
  background: #161515;
  color: #fff;
}

.btn-rounded-icon {
  border-radius: 100px;
  color: #fff;
  border: 1px solid #fff;
  padding: 13px 50px;
}

.main-nav {
  background: #fff;
}

@media (max-width: 767px) {
  .main-nav {
    position: relative;
  }
}

.main-nav .navbar-brand {
  padding: 35px 40px 35px 50px;
  border-right: 1px solid #e5e5e5;
}

@media (max-width: 1200px) {
  .main-nav .navbar-brand {
    padding: 20px 30px;
  }
}

@media (max-width: 767px) {
  .main-nav .navbar-brand {
    border-right: none;
  }
}

.main-nav .navbar-toggler {
  font-size: 1.5rem;
  margin-right: 15px;
}

.main-nav .navbar-nav .nav-item {
  position: relative;
  font-family: "Roboto", sans-serif;
}

.main-nav .navbar-nav .nav-item .nav-link {
  position: relative;
  text-align: center;
  color: #222222;
  padding-left: 5px;
  padding-right: 17px;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-size: 1.06rem;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1352px) {
  .main-nav .navbar-nav .nav-item .nav-link {
    font-size: 0.86rem;
    padding-right: 10px;
  }
}

@media (max-width: 1200px) {
  .main-nav .navbar-nav .nav-item .nav-link {
    padding-left: 4px;
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .main-nav .navbar-nav .nav-item .nav-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.main-nav .navbar-nav .nav-item .nav-link span {
  color: #222222;
  margin-left: 16px;
}

@media (max-width: 1200px) {
  .main-nav .navbar-nav .nav-item .nav-link span {
    margin-left: 14px;
  }
}

@media (max-width: 1024px) {
  .main-nav .navbar-nav .nav-item .nav-link span {
    margin-left: 10px;
  }
}

@media (max-width: 991px) {
  .main-nav .navbar-nav .nav-item .nav-link span {
    display: none;
  }
}

.main-nav .navbar-nav .nav-item:hover .nav-link,
.main-nav .navbar-nav .nav-item.active .nav-link {
  color: #161515;
}

.main-nav .dropdown {
  position: relative;
}

.main-nav .dropdown .open>a,
.main-nav .dropdown .open>a:focus,
.main-nav .dropdown .open>a:hover {
  background: transparent;
}

.main-nav .dropdown.full-width .dropdown-menu {
  left: 0 !important;
  right: 0 !important;
}

.main-nav .dropdown .dropdown-menu {
  min-width: 220px;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.main-nav .dropdown .dropdown-menu li:not(:last-child) .dropdown-item {
  border-bottom: 1px solid #e5e5e5;
}

@media (max-width: 991px) {
  .main-nav .dropdown .dropdown-menu li:not(:last-child) .dropdown-item {
    border: 0;
  }
}

.main-nav .dropdown .dropdown-menu .dropdown-item {
  font-size: 15px;
  padding: 10px 16px;
  transition: 0.3s ease;
}

.main-nav .dropdown .dropdown-menu .dropdown-item:hover {
  background: #fff;
  color: #161515;
}

@media (max-width: 991px) {
  .main-nav .dropdown .dropdown-menu {
    -webkit-transform: none;
            transform: none;
    left: auto;
    position: relative;
    text-align: center;
    opacity: 1;
    visibility: visible;
    background: rgb(243, 243, 243);
  }
}

@media (min-width: 992px) {
  .main-nav .dropdown .dropdown-menu {
    transition: 0.3s all;
    position: absolute;
    display: block;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }

  .main-nav .dropdown:hover>.dropdown-menu {
    border-top: 3px solid #161515;
    opacity: 1;
    display: block;
    visibility: visible;
    color: #777;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  .main-nav .dropdown .dropleft .dropdown-menu {
    right: 100%;
  }
}

.main-nav .dropleft .dropdown-menu,
.main-nav .dropright .dropdown-menu {
  margin: 0;
}

.main-nav .dropleft .dropdown-menu {
  right: 0;
}

.main-nav .dropleft .dropdown-toggle::before,
.main-nav .dropright .dropdown-toggle::after {
  font-weight: bold;
  font-family: "FontAwesome";
  border: 0;
  font-size: 14px;
  vertical-align: 1px;
}

.main-nav .dropleft .dropdown-toggle::before {
  content: "\F104";
  margin-right: 7px;
}

.main-nav .dropright .dropdown-toggle::after {
  content: "\F105";
  margin-left: 7px;
}

.main-nav .dropdown-submenu:hover>.dropdown-toggle,
.main-nav .dropdown-submenu.active>.dropdown-toggle {
  color: #161515;
}

.main-nav .ticket {
  background: #161515;
  display: block;
  padding: 34px 32px;
}

@media (max-width: 1352px) {
  .main-nav .ticket {
    padding: 34px 26px;
  }
}

@media (max-width: 1200px) {
  .main-nav .ticket {
    padding: 23px 26px;
  }
}

@media (max-width: 991px) {
  .main-nav .ticket {
    text-align: center;
  }
}

.main-nav .ticket img {
  margin-right: 25px;
}

@media (max-width: 1200px) {
  .main-nav .ticket img {
    display: none;
  }
}

.main-nav .ticket span {
  color: #fff;
  font-size: 1.125rem;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  border-left: 1px solid #ffa366;
  padding-left: 24px;
}

@media (max-width: 1200px) {
  .main-nav .ticket span {
    padding-left: 0;
    font-size: 1rem;
    border-left: none;
  }
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none;
}

.page-title {
  position: relative;
  padding: 165px 0 65px;
}

@media (max-width: 1200px) {
  .page-title {
    padding: 140px 0 65px;
  }
}

@media (max-width: 767px) {
  .page-title {
    padding: 65px 0;
  }
}

.page-title h3,
.page-title li,
.page-title a {
  color: #fff;
}

.page-title .title h3 {
  font-weight: 500;
  text-transform: uppercase;
}

.page-title .breadcrumb {
  background: transparent;
}

.page-title .breadcrumb .breadcrumb-item {
  float: none;
  display: inline-block;
  text-transform: uppercase;
}

.page-title .breadcrumb .breadcrumb-item.active {
  color: #fff;
}

.page-title .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: "\F105";
  font-family: FontAwesome;
  color: #fff;
}

.footer-main {
  background: #272735;
  padding: 90px 0;
}

@media (max-width: 767px) {
  .footer-main {
    padding: 50px 0;
  }
}

.footer-main .block img {
  margin-bottom: 60px;
}

.footer-main .block ul.social-links-footer {
  margin-bottom: 0;
}

.footer-main .block ul.social-links-footer .list-inline-item:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 575px) {
  .footer-main .block ul.social-links-footer .list-inline-item:not(:last-child) {
    margin-right: 5px;
  }
}

.footer-main .block ul.social-links-footer li a {
  display: block;
  height: 50px;
  width: 50px;
  text-align: center;
  background: #fff;
}

.footer-main .block ul.social-links-footer li a i {
  line-height: 50px;
}

.footer-main .block ul.social-links-footer li a:hover {
  background: #161515;
}

.footer-main .block ul.social-links-footer li a:hover i {
  color: #fff;
}

.subfooter {
  background: #2a2a37;
  padding: 18px 0;
}

.subfooter .copyright-text p {
  font-family: "Montserrat", sans-serif;
}

.subfooter .copyright-text p a {
  color: #161515;
}

@media (max-width: 767px) {
  .subfooter .copyright-text {
    text-align: center;
  }
}

.subfooter .to-top {
  display: block;
  height: 45px;
  width: 45px;
  text-align: center;
  background: #161515;
  float: right;
}

.subfooter .to-top i {
  font-size: 1.5rem;
  color: #fff;
  line-height: 45px;
}

@media (max-width: 767px) {
  .subfooter .to-top {
    float: none;
    margin: auto;
    margin-top: 30px;
  }
}

.cta-ticket {
  position: relative;
}

.cta-ticket .content-block {
  padding: 100px 0;
}

.cta-ticket .content-block h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
}

.cta-ticket .content-block p {
  color: #e6e6e6;
  margin-bottom: 45px;
}

.cta-ticket .image-block {
  position: absolute;
  right: 5%;
  bottom: 0;
}

@media (max-width: 767px) {
  .cta-ticket .image-block {
    display: none;
  }
}

.cta-subscribe {
  position: relative;
}

.cta-subscribe .content {
  padding: 70px 0;
}

.cta-subscribe .content h3,
.cta-subscribe .content p {
  color: #fff;
}

.cta-subscribe .content h3 {
  font-weight: 500;
}

.cta-subscribe .content p {
  font-size: 1.9375rem;
}

.cta-subscribe .subscribe-button button {
  cursor: pointer;
}

@media (max-width: 767px) {
  .cta-subscribe .subscribe-button {
    margin-bottom: 30px;
  }
}

.banner {
  padding: 250px 0 150px;
  position: relative;
}

@media (max-width: 1024px) {
  .banner {
    padding: 100px 0;
  }
}

.banner .block {
  position: relative;
  z-index: 10;
}

.banner .block .timer .syotimer-cell {
  margin-right: 25px;
  margin-bottom: 33px;
  display: inline-block;
}

@media (max-width: 575px) {
  .banner .block .timer .syotimer-cell {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.banner .block .timer .syotimer-cell .syotimer-cell__value {
  min-width: 80px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  line-height: 77px;
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #fff;
  border: 2px solid #7c7491;
  margin-bottom: 8px;
  border-radius: 100%;
  box-shadow: 3.5px 6.062px 0px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
  .banner .block .timer .syotimer-cell .syotimer-cell__value {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .banner .block .timer .syotimer-cell .syotimer-cell__value {
    min-width: 65px;
    line-height: 61px;
    font-size: 15px;
  }
}

.banner .block .timer .syotimer-cell .syotimer-cell__unit {
  text-align: center;
  font-size: 1rem;
  text-transform: lowercase;
  font-weight: normal;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.banner .block h1 {
  /* text-transform: uppercase; */
  font-size: 5.0rem;
  color: #ececec;
  font-weight: 500;
  margin-bottom: 5px;
  text-shadow: 3.5px 6.062px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .banner .block h1 {
    font-size: 4rem;
  }
}

@media (max-width: 400px) {
  .banner .block h1 {
    font-size: 2rem;
  }
}

.banner .block h2 {
  text-transform: uppercase;
  font-size: 4.0rem;
  color: #fff;
  font-weight: 400;
  text-shadow: 3.5px 6.062px 0px rgba(0, 0, 0, 0.1);
  font-style: italic;
}

@media (max-width: 767px) {
  .banner .block h2 {
    font-size: 3.2rem;
  }
}

@media (max-width: 400px) {
  .banner .block h2 {
    font-size: 2.3rem;
  }
}

.banner .block h6 {
  color: #fff;
  font-weight: 400;
  font-size: 2.0rem;
  margin-bottom: 50px;
}

.banner-two {
  padding: 250px 0 150px;
  position: relative;
  overflow: hidden;
}

.banner-two .block h1 {
  color: #161515;
  font-size: 88px;
}

@media (max-width: 575px) {
  .banner-two .block h1 {
    font-size: 40px;
  }
}


.banner-two .block h2 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 74px;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .banner-two .block h2 {
    font-size: 35px;
  }
}

.banner-two .block h3 {
  font-size: 74px;
}

@media (max-width: 575px) {
  .banner-two .block h3 {
    font-size: 35px;
  }
}

.banner-two .block h6 {
  margin-top: 48px;
  font-weight: 400;
  margin-bottom: 60px;
}

@media (max-width: 575px) {
  .banner-two .block h6 {
    margin-top: 30px;
  }
}

.about .image-block {
  padding: 30px;
}

.about .image-block img {
  border-radius: 0%;
  width: 100%;
  box-shadow: 0px 0px 199px 10px rgba(3, 11, 24, 0.15);
}

@media (max-width: 767px) {
  .about .image-block {
    margin-bottom: 30px;
    text-align: center;
  }
}

.about .image-block.two {
  padding: 0;
  margin-top: 30px;
}

.about .image-block.two img {
  border-radius: 0;
  -webkit-transform: translate(30px, -30px);
          transform: translate(30px, -30px);
}

@media (max-width: 767px) {
  .about .image-block.two img {
    -webkit-transform: translate(0, -30px);
            transform: translate(0, -30px);
  }
}

.about .content-block {
  margin-left: 15px;
}

@media (max-width: 767px) {
  .about .content-block {
    text-align: center;
  }
}

.about .content-block h2 {
  margin-bottom: 35px;
}

.about .content-block h2:after {
  content: "";
  width: 60px;
  height: 3px;
  background: #161515;
  display: block;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .about .content-block h2:after {
    margin: auto;
  }
}

.about .content-block .description-one {
  margin-bottom: 20px;
}

.about .content-block .description-one-theme p {
  color: #222222;
  font-size: 3.0625rem;
}

.about .content-block .description-one p {
  color: #222222;
  font-size: 17px;
}

.about .content-block .description-two {
  margin-bottom: 30px;
}

.about .content-block .description-two p {
  font-size: 17px;
  text-align: justify;
}

@media (max-width: 575px) {
  .about .content-block ul.list-inline .list-inline-item {
    display: block;
  }
}

.about .content-block .list-inline-item:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 991px) {
  .about .content-block .list-inline-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .about .content-block .list-inline-item:not(:last-child) {
    margin-right: 0;
  }
}

.speakers {
  position: relative;
}

.speakers.white .speaker-item {
  background: #fff;
  border: 1px solid #e5e5e5;
}

.speakers .speaker-item {
  background: #eae9ec;
  padding: 7px;
  margin-bottom: 30px;
}

.speakers .speaker-item .image {
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 575px) {
  .speakers .speaker-item .image img {
    width: 100% !important;
  }
}

.speakers .speaker-item .image .primary-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161515;
  opacity: 0.85;
  transition: 0.3s ease;
}

.speakers .speaker-item .image .socials {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
}

.speakers .speaker-item .image .socials ul {
  width: 50%;
}

@media (max-width: 1200px) {
  .speakers .speaker-item .image .socials ul {
    width: 100%;
    text-align: center;
    padding: 0 20px;
  }
}

.speakers .speaker-item .image .socials ul li.list-inline-item:not(:last-child) {
  margin-right: 7px;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .speakers .speaker-item .image .socials ul li.list-inline-item:not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

@media (max-width: 1200px) {
  .speakers .speaker-item .image .socials ul li.list-inline-item:last-child {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.speakers .speaker-item .image .socials ul li a {
  display: block;
  height: 50px;
  width: 50px;
  text-align: center;
  border: 1px solid #fff;
}

.speakers .speaker-item .image .socials ul li a i {
  font-size: 0.9375rem;
  color: #fff;
  line-height: 50px;
}

.speakers .speaker-item .image .socials ul li a:hover {
  background: #fff;
}

.speakers .speaker-item .image .socials ul li a:hover i {
  color: #222222;
}

.speakers .speaker-item .image:hover .primary-overlay {
  top: 0;
}

.speakers .speaker-item .image:hover .socials {
  top: 0;
}

.speakers .speaker-item .content h5 a {
  font-size: 22px;
  color: #222222;
  font-weight: 400;
  margin-bottom: 0;
}

.speakers .speaker-item .content h5 a:hover {
  color: #161515;
}

.speakers .speaker-item .content p {
  font-size: 1.875rem;
  margin-bottom: 5px;
}

.schedule .schedule-tab {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

.schedule .schedule-tab ul li.nav-item {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .schedule .schedule-tab ul li.nav-item {
    width: 100%;
    margin-bottom: 10px;
  }
}

.schedule .schedule-tab ul li.nav-item a {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #161515;
  font-weight: 500;
  display: block;
  border-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 20px 30px;
}

.schedule .schedule-tab ul li.nav-item a span {
  margin-top: 2px;
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 1rem;
  color: #848484;
  font-weight: 400;
}

.schedule .schedule-tab ul li.nav-item a.active {
  background: #161515;
  color: #fff;
  position: relative;
}

.schedule .schedule-tab ul li.nav-item a.active span {
  color: #fff;
}

.schedule .schedule-tab ul li.nav-item a.active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #161515 transparent transparent;
}

@media (max-width: 767px) {
  .schedule .schedule-tab ul li.nav-item a.active:after {
    content: none;
  }
}

.schedule .schedule-contents {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
}

.schedule .schedule-contents .schedule-item ul {
  margin-bottom: 0;
}

.schedule .schedule-contents .schedule-item ul li {
  list-style: none;
}

.schedule .schedule-contents .schedule-item ul li div {
  display: inline-block;
}

.schedule .schedule-contents .schedule-item ul li .time {
  width: 20%;
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .time {
    width: 37%;
  }
}

@media (max-width: 575px) {
  .schedule .schedule-contents .schedule-item ul li .time {
    width: 48%;
  }
}

.schedule .schedule-contents .schedule-item ul li .speaker {
  width: 30%;
}

@media (max-width: 991px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 60%;
  }
}

@media (max-width: 575px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .schedule .schedule-contents .schedule-item ul li .speaker img {
    display: none;
  }
}

.schedule .schedule-contents .schedule-item ul li .subject {
  width: 30%;
}

@media (max-width: 1200px) {
  .schedule .schedule-contents .schedule-item ul li .subject {
    display: none;
  }
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .venue {
    display: none;
  }
}

.schedule .schedule-contents .schedule-item li.headings {
  padding: 22px 40px;
  background: #161515;
}

.schedule .schedule-contents .schedule-item li.headings div {
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.schedule .schedule-contents .schedule-item li.schedule-details {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #e5e5e5;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block {
  padding: 10px 40px;
  background: #fff;
  width: 100%;
  transition: 0.2s ease-in;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div {
  color: #222222;
  font-family: "Montserrat", sans-serif;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div i {
  font-size: 1.1875em;
  color: #c7c8c9;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div img {
  border-radius: 100%;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div span.time {
  margin-left: 5px;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div span.name {
  margin-left: 20px;
  transition: 0.2s ease-in;
}

@media (max-width: 575px) {
  .schedule .schedule-contents .schedule-item li.schedule-details .block div span.name {
    margin-left: 0;
  }
}

.schedule .schedule-contents .schedule-item li.schedule-details .block:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.schedule .schedule-contents .schedule-item li.schedule-details .block:hover span.name {
  color: #161515;
}

.schedule .download-button {
  padding-top: 40px;
  margin-bottom: 30px;
}

.schedule.two .schedule-tab {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.schedule.two .schedule-tab ul li.nav-item {
  margin-right: 0;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .schedule.two .schedule-tab ul li.nav-item {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .schedule.two .schedule-tab ul li.nav-item {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.schedule.two .schedule-tab ul li.nav-item a {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  color: #222222;
  font-weight: 500;
  display: block;
  border-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 20px 30px;
}

.schedule.two .schedule-tab ul li.nav-item a span {
  margin-top: 2px;
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 14px;
  color: #848484;
  font-weight: 400;
}

.schedule.two .schedule-tab ul li.nav-item a.active {
  background: #161515;
  color: #fff;
  position: relative;
}

.schedule.two .schedule-tab ul li.nav-item a.active span {
  color: #fff;
}

.schedule.two .schedule-tab ul li.nav-item a.active:after {
  content: "";
  position: absolute;
  left: 100%;
  right: 0;
  bottom: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #161515;
}

@media (max-width: 991px) {
  .schedule.two .schedule-tab ul li.nav-item a.active:after {
    content: none;
  }
}

.schedule.two .schedule-contents {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0;
}

.schedule.two .schedule-contents .schedule-item ul {
  margin-bottom: 0;
}

.schedule.two .schedule-contents .schedule-item ul li {
  list-style: none;
}

.schedule.two .schedule-contents .schedule-item ul li div {
  display: inline-block;
}

.schedule.two .schedule-contents .schedule-item ul li .time {
  width: 20%;
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .time {
    width: 37%;
  }
}

@media (max-width: 575px) {
  .schedule.two .schedule-contents .schedule-item ul li .time {
    width: 48%;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .speaker {
  width: 30%;
}

@media (max-width: 991px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 60%;
  }
}

@media (max-width: 575px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .subject {
  width: 30%;
}

@media (max-width: 1200px) {
  .schedule.two .schedule-contents .schedule-item ul li .subject {
    display: none;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .venue {
  width: 18.6%;
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .venue {
    display: none;
  }
}

.schedule.two .schedule-contents .schedule-item li.headings {
  padding: 25px 0;
  background: #161515;
}

.schedule.two .schedule-contents .schedule-item li.headings div {
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block {
  text-align: center;
  padding: 0;
  background: #fff;
  width: 100%;
  transition: 0.2s ease-in;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div {
  padding-top: 25px;
  padding-bottom: 25px;
  color: #222222;
  font-family: "Montserrat", sans-serif;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div i {
  font-size: 1.1875em;
  color: #c7c8c9;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div img {
  border-radius: 100%;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.time {
  margin-left: 0px;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.name {
  margin-left: 0px;
  transition: 0.2s ease-in;
}

@media (max-width: 575px) {
  .schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.name {
    margin-left: 0;
  }
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:hover {
  box-shadow: none;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:hover span.name {
  color: #161515;
}

.ticket-feature {
  overflow: hidden;
}

.ticket-feature .block {
  position: relative;
  padding-top: 150px;
  padding-bottom: 110px;
}

.ticket-feature .block .section-title,
.ticket-feature .block a {
  position: relative;
}

.ticket-feature .block .section-title h3 {
  font-weight: 500;
}

.ticket-feature .block .timer {
  position: relative;
  margin-top: 60px;
  margin-bottom: 35px;
}

.ticket-feature .block .timer .syotimer-cell {
  margin-right: 25px;
  margin-bottom: 33px;
  display: inline-block;
}

@media (max-width: 575px) {
  .ticket-feature .block .timer .syotimer-cell {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.ticket-feature .block .timer .syotimer-cell .syotimer-cell__value {
  min-width: 80px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.35);
  background-clip: content-box;
  background: rgba(255, 255, 255, 0.26);
  margin-bottom: 8px;
  border-radius: 100%;
}

@media (max-width: 575px) {
  .ticket-feature .block .timer .syotimer-cell .syotimer-cell__value {
    font-size: 30px;
    height: 80px;
    width: 80px;
    line-height: 80px;
  }
}

.ticket-feature .block .timer .syotimer-cell .syotimer-cell__unit {
  text-align: center;
  font-size: 1rem;
  text-transform: lowercase;
  font-weight: normal;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.ticket-feature .block-2 {
  position: relative;
  padding: 150px 10%;
  height: 100%;
  background: #161515;
}

@media (max-width: 575px) {
  .ticket-feature .block-2 {
    padding: 50px 20px;
  }
}

.ticket-feature .block-2 [class*=col-]:first-child {
  border-right: 1px solid #161515;
  border-bottom: 1px solid #161515;
}

.ticket-feature .block-2 [class*=col-]:nth-child(2) {
  border-bottom: 1px solid #161515;
}

.ticket-feature .block-2 [class*=col-]:nth-child(3) {
  border-right: 1px solid #161515;
}

.ticket-feature .block-2 .service-item {
  padding: 30px;
  text-align: center;
}

.ticket-feature .block-2 .service-item i,
.ticket-feature .block-2 .service-item h5 {
  color: #fff;
}

.ticket-feature .block-2 .service-item i {
  font-size: 3.25rem;
  margin-bottom: 20px;
}

.ticket-feature .block-2 .service-item h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  .ticket-feature .block-2 .service-item h5 {
    font-size: 1rem;
  }
}

.ticket-feature .block-2:after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 1000px 300px;
  border-color: transparent transparent #161515 transparent;
}

@media (max-width: 1200px) {
  .ticket-feature .block-2:after {
    content: none;
  }
}

.registration {
  overflow: hidden;
}

.registration .service-block {
  position: relative;
  padding: 120px 10%;
}

.registration .service-block [class*=col-]:first-child {
  border-right: 1px solid #161515;
  border-bottom: 1px solid #161515;
}

.registration .service-block [class*=col-]:nth-child(2) {
  border-bottom: 1px solid #161515;
}

.registration .service-block [class*=col-]:nth-child(3) {
  border-right: 1px solid #161515;
}

.registration .service-block .service-item {
  padding: 30px;
  text-align: center;
}

.registration .service-block .service-item i,
.registration .service-block .service-item h5 {
  color: #fff;
}

.registration .service-block .service-item i {
  font-size: 3.25rem;
  margin-bottom: 20px;
}

.registration .service-block .service-item h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  .registration .service-block .service-item h5 {
    font-size: 12px;
  }
}

.registration .registration-block {
  position: relative;
  height: 100%;
  padding: 120px 10%;
}

.registration .registration-block .block {
  position: relative;
  z-index: 9;
}

.registration .registration-block .block .title {
  margin-bottom: 35px;
}

.registration .registration-block .block .title h3 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

.registration .registration-block .block .title p {
  color: #b3b3b3;
  line-height: 1;
}

.pricing .pricing-item {
  border: 1px solid #e5e5e5;
}

.pricing .content-block h2:after {
  content: "";
  width: 60px;
  height: 3px;
  background: #161515;
  display: block;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .pricing .pricing-item {
    margin-bottom: 30px;
  }
}

.pricing .pricing-item .pricing-heading {
  padding: 20px 40px 30px 40px;
  background: #fafafa;
  border-bottom: 1px solid #e5e5e5;
}

.pricing .pricing-item .pricing-heading .title h6 {
  text-transform: uppercase;
  font-weight: 400;
  line-height: 50px;
  border-bottom: 1px solid #e5e5e5;
}

.pricing .pricing-item .pricing-heading .price {
  margin-top: 28px;
}

.pricing .pricing-item .pricing-heading .price h2 {
  font-size: 3.625rem;
  font-weight: 400;
  margin-bottom: 0px;
}

.pricing .pricing-item .pricing-heading .price h2 span {
  font-size: 1.5625rem;
}

.pricing .pricing-item .pricing-body {
  padding: 10px 15px;
}

.pricing .pricing-item .pricing-body-2 {
  padding: 45px 40px;
}

.pricing .pricing-item .pricing-body ul.feature-list li {
  list-style: none;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span {
  margin-right: 15px;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span.available {
  color: #161515;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span.unavailable {
  color: #d2d2d2;
}

.pricing .pricing-item .pricing-body ul.feature-list li:not(:last-child) {
  margin-bottom: 15px;
}

.pricing .pricing-item .pricing-footer {
  padding-bottom: 40px;
}

.pricing .pricing-item.featured {
  border: none;
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.pricing .pricing-item.featured .pricing-heading {
  background: #161515;
  border-bottom: 1px solid #161515;
}

.pricing .pricing-item.featured .pricing-heading .title h6 {
  color: #fff;
  border-bottom: 1px solid #161515;
}

.pricing .pricing-item.featured .pricing-heading .price {
  margin-top: 28px;
}

.pricing .pricing-item.featured .pricing-heading .price h2 {
  color: #fff;
  font-size: 3.625rem;
  margin-bottom: 0px;
}

.pricing .pricing-item.featured .pricing-heading .price h2 span {
  font-size: 1.5625rem;
}

.pricing .pricing-item.featured .pricing-heading .price p {
  color: #fff;
}

.pricing.two .pricing-item {
  border: 1px solid #e5e5e5;
  overflow: hidden;
}

@media (max-width: 991px) {
  .pricing.two .pricing-item {
    margin-bottom: 30px;
  }
}

.pricing.two .pricing-item .pricing-heading {
  position: relative;
  margin-bottom: 10px;
}

.pricing.two .pricing-item .pricing-heading .title h6 {
  position: relative;
}

.pricing.two .pricing-item .pricing-heading .price {
  position: relative;
}

.pricing.two .pricing-item .pricing-heading:before {
  content: "";
  position: absolute;
  bottom: -25%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 64px 500px 0 0;
  border-color: #fafafa transparent transparent transparent;
}

@media (max-width: 991px) {
  .pricing.two .pricing-item .pricing-heading:before {
    content: none;
  }
}

.pricing.two .pricing-item .pricing-body {
  padding: 70px 40px 45px;
}

.pricing.two .pricing-item .pricing-body ul.feature-list li p span.available {
  color: #161515;
}

.pricing.two .pricing-item.featured .pricing-heading:before {
  border-color: #161515 transparent transparent transparent;
}

.sponsors {
  position: relative;
}

.sponsors .sponsor-title {
  margin-top: 10px;
  margin-bottom: 30px;
}

.sponsors .sponsor-title h5 {
  color: #161515;
}

.sponsors .block {
  margin-bottom: 40px;
}

.sponsors .block .list-inline-item:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 991px) {
  .sponsors .block .list-inline-item:not(:last-child) {
    margin-right: 7px;
    margin-left: 7px;
  }
}

@media (max-width: 991px) {
  .sponsors .block .list-inline-item:last-child {
    margin-right: 7px;
    margin-left: 7px;
  }
}

.sponsors .block .image-block {
  padding: 15px 0;
  background: #fff;
  width: 374px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  margin-bottom: 10px;
  box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
}

.sponsors .block .image-block img {
  height: 55px;
}

.sponsors .block .image-block:hover {
  border: 1px solid #161515;
}

.sponsors .sponsor-btn {
  margin-top: 10px;
  margin-bottom: 30px;
}

.map {
  position: relative;
}

.map #map {
  height: 480px;
  color: white;
}

.map .address-block {
  position: absolute;
  padding: 45px 50px 50px 50px;
  top: 80px;
  left: 10%;
  background: #161515;
}

@media (max-width: 575px) {
  .map .address-block {
    display: none;
  }
}

.map .address-block h4,
.map .address-block li {
  color: #fff;
  margin-bottom: 20px;
}

.map .address-block h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

.map .address-block ul.address-list li {
  list-style: none;
  margin-bottom: 10px;
}

.map .address-block ul.address-list li i {
  font-size: 1.125rem;
}

.map .address-block ul.address-list li span {
  margin-left: 15px;
}

.map .address-block a {
  margin-top: 35px;
}

.map.new {
  overflow: hidden;
}

.map.new #map {
  height: 640px;
}

.map.new .address-block {
  left: 60%;
}

@media (max-width: 991px) {
  .map.new .address-block {
    left: 52%;
  }
}

@media (max-width: 767px) {
  .map.new .address-block {
    left: 5%;
  }
}

.map.new .register {
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 767px) {
  .map.new .register {
    width: 100%;
    position: relative;
  }
}

.map.new .register .block {
  padding: 130px 10%;
}

.map.new .register .block .title {
  position: relative;
  margin-bottom: 65px;
}

.map.new .register .block .title h3 {
  color: #fff;
  font-weight: 400;
}

.map.new .register .block .title p {
  color: #fff;
}

.map.new .register .block .form-control,
.map.new .register .block button {
  margin-bottom: 40px;
}

.feature .feature-content h2,
.feature .feature-content p {
  margin-bottom: 25px;
}

@media (max-width: 767px) {

  .feature .feature-content h2,
  .feature .feature-content p {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .feature .testimonial {
    text-align: center;
  }
}

.feature .testimonial p {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  font-style: italic;
  color: #242424;
}

.feature .testimonial ul.meta li {
  font-size: 12px;
  margin-right: 10px;
}

.feature .testimonial ul.meta li img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

@media (max-width: 575px) {
  .app-features .app-feature {
    margin-bottom: 30px;
  }
}

.app-features .app-explore {
  display: flex;
  justify-content: center !important;
  margin-bottom: 40px;
}

.banner-full .image {
  display: flex;
  justify-content: center;
}

.banner-full .image img {
  height: 625px;
}

@media (max-width: 767px) {
  .banner-full .image {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .banner-full .block {
    text-align: center;
  }
}

.banner-full .block .logo {
  margin-bottom: 40px;
}

.banner-full .block h1 {
  margin-bottom: 40px;
}

.banner-full .block p {
  font-size: 20px;
  margin-bottom: 50px;
}

.banner-full .block .app {
  margin-bottom: 20px;
}

.video-promo {
  padding: 150px 0;
}

.video-promo .content-block {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.video-promo .content-block h2 {
  font-size: 30px;
  color: #fff;
}

.video-promo .content-block p {
  margin-bottom: 30px;
}

.video-promo .content-block a i.video {
  height: 125px;
  width: 125px;
  background: #161515;
  display: inline-block;
  font-size: 40px;
  color: #fff;
  text-align: center;
  line-height: 125px;
  border-radius: 100%;
}

.video-promo .content-block a:focus {
  outline: 0;
}

.testimonial .testimonial-slider .item {
  padding-bottom: 10px;
}

.testimonial .testimonial-slider .item .block {
  padding: 40px;
  text-align: center;
  margin: 10px;
  border-radius: 5px;
}

.testimonial .testimonial-slider .item .block .image {
  margin-top: 30px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .testimonial .testimonial-slider .item .block .image {
    flex-grow: 1;
  }
}

.testimonial .testimonial-slider .item .block .image img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.testimonial .testimonial-slider .item .block p {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  color: #888888;
}

.testimonial .testimonial-slider .item .block cite {
  font-style: normal;
  font-size: 14px;
  color: #161616;
}

.testimonial .testimonial-slider .owl-dots .owl-dot:hover span {
  background: #161515;
}

.testimonial .testimonial-slider .owl-dots .owl-dot.active span {
  background: #161515;
}

.single-speaker .block {
  padding-bottom: 70px;
  border-bottom: 1px solid #e5e5e5;
}

.single-speaker .block .image-block {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 6px;
}

.single-speaker .block .image-block img {
  width: 100%;
}

@media (max-width: 767px) {
  .single-speaker .block .image-block {
    margin-bottom: 30px;
  }
}

.single-speaker .block .content-block .name h3 {
  font-weight: 400;
}

.single-speaker .block .content-block .profession {
  margin-bottom: 20px;
}

.single-speaker .block .content-block .profession p {
  color: #161515;
  font-size: 0.9375rem;
}

.single-speaker .block .content-block .details {
  margin-bottom: 35px;
}

.single-speaker .block .content-block .details p {
  font-size: 0.9375rem;
}

.single-speaker .block .content-block .details p:not(:last-child) {
  margin-bottom: 35px;
}

.single-speaker .block .content-block .social-profiles h5 {
  font-weight: 400;
  margin-bottom: 25px;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a {
  display: block;
  height: 40px;
  width: 40px;
  background: #fff;
  border: 1px solid #e5e5e5;
  text-align: center;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a i {
  line-height: 40px;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a:hover {
  background: #161515;
  border: 1px solid #161515;
  color: #fff;
}

.single-speaker .block-2 {
  padding-top: 70px;
  padding-bottom: 10px;
}

.single-speaker .block-2 h3,
.single-speaker .block-2 p,
.single-speaker .block-2 li {
  font-size: 0.9375rem;
}

.single-speaker .block-2 .personal-info h5,
.single-speaker .block-2 .skills h5 {
  font-weight: 400;
  margin-bottom: 15px;
}

.single-speaker .block-2 .personal-info p,
.single-speaker .block-2 .skills p {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .single-speaker .block-2 .personal-info {
    margin-bottom: 30px;
  }
}

.single-speaker .block-2 .personal-info ul li {
  position: relative;
  list-style: none;
  margin-left: 25px;
}

.single-speaker .block-2 .personal-info ul li:not(:last-child) {
  margin-bottom: 5px;
}

.single-speaker .block-2 .personal-info ul li:before {
  position: absolute;
  left: -25px;
  content: "\F10C";
  font-family: FontAwesome;
  color: #161515;
}

.single-speaker .block-2 .skills .skill-bar {
  margin-bottom: 20px;
}

.single-speaker .block-2 .skills .skill-bar p {
  margin-bottom: 5px;
}

.single-speaker .block-2 .skills .skill-bar .progress {
  border-radius: 0;
  overflow: visible;
}

.single-speaker .block-2 .skills .skill-bar .progress .progress-bar {
  background-color: #161515;
}

.single-speaker .block-2 .skills .skill-bar .progress .progress-bar span {
  font-family: "Montserrat", sans-serif;
  float: right;
  color: white;
}

.speakers-full-width {
  overflow: hidden;
}

.speakers-full-width .speaker-slider .speaker-image {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.speakers-full-width .speaker-slider .speaker-image img {
  width: 100%;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161515;
  opacity: 0.85;
  transition: 0.3s ease;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay p,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay i {
  color: #fff;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5 {
  padding-top: 25%;
  margin-bottom: 10px;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay ul,
.speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  transition: 0.7s ease;
  -webkit-transform: translate(0, 30px);
          transform: translate(0, 30px);
  opacity: 0;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  margin-bottom: 10px;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay ul .list-inline-item:not(:last-child) {
  margin-right: 10px;
}

.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay {
  top: 0;
}

.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay h5,
.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay p,
.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay ul {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  opacity: 1;
}

.speakers-full-width .speaker-slider .slick-prev {
  left: 60px;
}

@media (max-width: 767px) {
  .speakers-full-width .speaker-slider .slick-prev {
    left: 20px;
  }
}

.speakers-full-width .speaker-slider .slick-prev:before {
  content: "\F104";
}

.speakers-full-width .speaker-slider .slick-next {
  right: 60px;
}

@media (max-width: 767px) {
  .speakers-full-width .speaker-slider .slick-next {
    right: 20px;
  }
}

.speakers-full-width .speaker-slider .slick-next:before {
  content: "\F105";
}

.speakers-full-width .speaker-slider .slick-prev,
.speakers-full-width .speaker-slider .slick-next {
  background: #fff;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  z-index: 100;
}

@media (max-width: 1024px) {

  .speakers-full-width .speaker-slider .slick-prev,
  .speakers-full-width .speaker-slider .slick-next {
    height: 35px;
    width: 35px;
  }
}

.speakers-full-width .speaker-slider .slick-prev:hover:before,
.speakers-full-width .speaker-slider .slick-next:hover:before {
  color: #161515;
}

.speakers-full-width .speaker-slider .slick-prev:before,
.speakers-full-width .speaker-slider .slick-next:before {
  color: #161515;
  font-family: FontAwesome;
}

/* Controls
---------------------------------------------------------------------- */
.controls {
  text-align: center;
  margin-bottom: 40px;
}

.controls .btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  margin-right: 25px;
}

@media (max-width: 767px) {
  .controls .btn {
    margin-right: 10px;
  }
}

.controls .btn.focus,
.controls .btn:focus {
  outline: 0;
  box-shadow: none;
}

.controls .btn:hover {
  color: #161515;
}

.mixitup-control-active {
  color: #161515;
}

/* Container
---------------------------------------------------------------------- */
.gallery-wrapper {
  /* Target Elements
    ---------------------------------------------------------------------- */
  /* Grid Breakpoints
    ---------------------------------------------------------------------- */
  /* 2 Columns */
  /* 3 Columns */
}

.gallery-wrapper .gallery-item {
  padding: 15px;
}

.gallery-wrapper .gallery-item .image-block {
  padding: 6px;
  border: 1px solid #e5e5e5;
}

.gallery-wrapper .gallery-item .image-block .image {
  position: relative;
  overflow: hidden;
}

.gallery-wrapper .gallery-item .image-block .image img {
  width: 100%;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161515;
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: 0.3s ease;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay a {
  display: block;
  height: 55px;
  width: 55px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  outline: none;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay a i {
  color: #fff;
  line-height: 55px;
  font-size: 1.25rem;
}

.gallery-wrapper .gallery-item .image-block .image:hover .primary-overlay {
  opacity: 0.85;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.gallery-wrapper .mix,
.gallery-wrapper .gap {
  display: inline-block;
  vertical-align: top;
}

.gallery-wrapper .mix {
  background: #fff;
  position: relative;
}

.gallery-wrapper .mix {
  width: calc(50% - 0.5rem);
}

@media screen and (min-width: 768px) {
  .gallery-wrapper .mix {
    width: calc(33.3333333333% - 0.6666666667rem);
  }
}

.gallery-wrapper.less-gutter .gallery-item {
  padding: 5px;
}

.gallery-wrapper.less-gutter .gallery-item .image-block {
  padding: 0;
  border: none;
}

@media screen and (min-width: 1200px) {

  .gallery-wrapper.less-gutter .mix,
  .gallery-wrapper.less-gutter .gap {
    width: calc(25% - 0.75rem);
  }
}

.gallery-full {
  overflow: hidden;
}

.gallery-full .image {
  position: relative;
  overflow: hidden;
  margin-bottom: -1px;
}

.gallery-full .image img {
  width: 100%;
}

.gallery-full .image .primary-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161515;
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: 0.3s ease;
}

.gallery-full .image .primary-overlay a {
  display: block;
  height: 55px;
  width: 55px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  outline: none;
}

.gallery-full .image .primary-overlay a i {
  color: #fff;
  line-height: 55px;
  font-size: 1.25rem;
}

.gallery-full .image:hover .primary-overlay {
  opacity: 0.85;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.news .blog-post {
  transition: 0.2s ease;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .news .blog-post .post-thumb img {
    width: 100%;
  }
}

.news .blog-post .post-content {
  position: relative;
  padding: 30px 30px 25px 30px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #e5e5e5;
}

.news .blog-post .post-content .date {
  position: absolute;
  top: -32px;
  left: 30px;
  width: 65px;
  height: 65px;
  text-align: center;
  background: #161515;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news .blog-post .post-content .date h4 {
  color: #fff;
}

.news .blog-post .post-content .date h4 span {
  display: block;
  font-size: 1rem;
  font-weight: 400;
}

.news .blog-post .post-content .post-title {
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}

.news .blog-post .post-content .post-title h2 {
  margin-top: 10px;
}

.news .blog-post .post-content .post-title h2 a {
  font-weight: 400;
  color: #222222;
}

.news .blog-post .post-content .post-title h2 a:hover {
  color: #161515;
}

.news .blog-post .post-content .post-meta ul {
  margin-bottom: 0;
}

.news .blog-post .post-content .post-meta ul li i {
  margin-right: 7px;
}

.news .blog-post .post-content .post-meta ul li a {
  display: inline-block;
  padding-right: 25px;
  font-size: 0.875rem;
}

.news .blog-post:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.news .blog-post.single {
  margin-bottom: 100px;
}

.news .blog-post.single .post-title {
  padding-top: 35px;
  padding-bottom: 0;
  border: none;
  margin-bottom: 10px;
}

.news .blog-post.single .post-title h3 {
  font-weight: 400;
}

.news .blog-post.single .post-meta {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.news .blog-post.single .post-details p {
  padding: 13px 0;
  font-size: 15px;
}

.news .blog-post.single .post-details .quotes {
  background: #f5f5f8;
  padding: 30px;
  border-left: 3px solid #161515;
  margin: 15px 0;
}

.news .blog-post.single .post-details .quotes blockquote {
  font-size: 15px;
  font-style: italic;
}

.news .blog-post.single .post-details .share-block {
  display: flex;
  padding-top: 20px;
  padding-bottom: 25px;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block {
    flex-wrap: wrap;
  }
}

.news .blog-post.single .post-details .share-block p {
  padding: 0;
}

.news .blog-post.single .post-details .share-block .tag {
  flex-basis: 50%;
  align-self: center;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block .tag {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 30px;
    flex-grow: 1;
  }
}

.news .blog-post.single .post-details .share-block .tag p,
.news .blog-post.single .post-details .share-block .tag ul {
  display: inline;
  font-family: "Montserrat", sans-serif;
}

.news .blog-post.single .post-details .share-block .tag p {
  color: #222222;
}

.news .blog-post.single .post-details .share-block .tag ul li a {
  color: #848484;
  font-size: 15px;
}

.news .blog-post.single .post-details .share-block .tag ul li a:hover {
  color: #161515;
}

.news .blog-post.single .post-details .share-block .share {
  flex-basis: 50%;
  text-align: right;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block .share {
    flex-basis: 100%;
    flex-grow: 1;
    text-align: center;
  }
}

.news .blog-post.single .post-details .share-block .share p,
.news .blog-post.single .post-details .share-block .share ul {
  display: inline;
  font-family: "Montserrat", sans-serif;
}

.news .blog-post.single .post-details .share-block .share p {
  margin-right: 18px;
}

@media (max-width: 400px) {
  .news .blog-post.single .post-details .share-block .share p {
    display: block;
    margin-bottom: 10px;
  }
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share {
  margin-bottom: 0;
}

@media (max-width: 400px) {
  .news .blog-post.single .post-details .share-block .share ul.social-links-share {
    text-align: left;
  }
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share .list-inline-item:not(:last-child) {
  margin-right: 7px;
}

@media (max-width: 400px) {
  .news .blog-post.single .post-details .share-block .share ul.social-links-share .list-inline-item:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a {
  display: block;
  height: 38px;
  width: 38px;
  text-align: center;
  background: #fff;
  border: 1px solid #e5e5e5;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a i {
  line-height: 38px;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a:hover {
  background: #161515;
  border-color: #161515;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a:hover i {
  color: #fff;
}

.news .blog-post.single:hover {
  box-shadow: none;
}

.news .comments {
  padding-bottom: 80px;
}

.news .comments h5 {
  font-weight: 400;
  margin-bottom: 10px;
}

.news .comments .comment {
  padding-top: 14px;
}

.news .comments .comment img {
  margin-right: 30px;
}

.news .comments .comment .media-body h6 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.news .comments .comment .media-body p,
.news .comments .comment .media-body ul {
  font-size: 15px;
}

.news .comments .comment .media-body p {
  margin-bottom: 14px;
}

.news .comments .comment .media-body ul {
  margin-bottom: 10px;
}

.news .comments .comment .media-body ul li span {
  margin-right: 7px;
}

.news .comments .comment .media-body ul li a {
  color: #161515;
}

.news .comment-form h5 {
  font-weight: 400;
  margin-bottom: 30px;
}

.news .comment-form .form-control,
.news .comment-form button {
  margin-bottom: 30px;
}


.sidebar {
  padding: 0 20px;
}

.widget {
  margin-bottom: 45px;
}

.widget .widget-header {
  font-weight: 400;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .widget.search {
    margin-top: 30px;
  }
}

.widget.search .input-group .form-control {
  border-right: none;
}

.widget.search .input-group .input-group-addon {
  background: #161515;
  color: #fff;
  border: none;
  border-radius: 0;
  line-height: 45px;
  padding: 0 10px;
}

.widget.search .input-group .input-group-addon i {
  font-size: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.widget.category ul.category-list li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  list-style: none;
}

.widget.category ul.category-list li a {
  font-size: 15px;
  letter-spacing: 0;
  display: block;
}

.widget.category ul.category-list li a:hover {
  color: #161515;
}

.widget.category ul.category-list li:last-child {
  margin-bottom: 5px;
}

.widget.tags ul li a {
  display: block;
  padding: 10px 25px;
  border: 1px solid #e5e5e5;
  margin-bottom: 15px;
  font-size: 15px;
}

.widget.tags ul li a:hover {
  background: #161515;
  color: #fff;
  border-color: #161515;
}

.widget.latest-post .media {
  padding: 15px 0;
}

.widget.latest-post .media:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.widget.latest-post .media img {
  margin-right: 15px;
}

.widget.latest-post .media .media-body h6 a {
  font-size: 15px;
  color: #222222;
  line-height: 1;
  font-weight: 400;
}

.widget.latest-post .media .media-body h6 a:hover {
  color: #161515;
}

.widget.latest-post .media .media-body p span {
  margin-right: 10px;
}

.news-hr .news-post-hr {
  display: flex;
}

@media (max-width: 1200px) {
  .news-hr .news-post-hr {
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .news-hr .news-post-hr {
    margin-bottom: 30px;
  }
}

.news-hr .news-post-hr .post-thumb {
  flex-basis: 50%;
}

@media (max-width: 1200px) {
  .news-hr .news-post-hr .post-thumb {
    flex-basis: 100%;
  }
}

.news-hr .news-post-hr .post-thumb img {
  width: 100%;
}

.news-hr .news-post-hr .post-contents {
  flex-basis: 50%;
  padding: 30px;
  position: relative;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: #e5e5e5;
}

@media (max-width: 1200px) {
  .news-hr .news-post-hr .post-contents {
    border-left: 1px solid #e5e5e5;
    flex-basis: 100%;
  }
}

.news-hr .news-post-hr .post-contents .post-title h6 {
  margin-bottom: 36px;
  margin-top: 5px;
}

.news-hr .news-post-hr .post-contents .post-title h6 a {
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  font-size: 20px;
  color: #222222;
}

.news-hr .news-post-hr .post-contents .post-title h6 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 37px;
  content: "";
  background: #161515;
  width: 30px;
  height: 2px;
}

.news-hr .news-post-hr .post-contents .post-exerpts p {
  font-size: 15px;
}

.news-hr .news-post-hr .post-contents .date {
  margin-top: 17px;
  width: 65px;
  height: 65px;
  text-align: center;
  background: #161515;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-hr .news-post-hr .post-contents .date h4 {
  color: #fff;
  font-weight: 400;
}

.news-hr .news-post-hr .post-contents .date h4 span {
  display: block;
  font-size: 1rem;
  font-weight: 400;
}

.news-hr .news-post-hr .post-contents .more {
  text-align: right;
  margin-top: -25px;
}

.news-hr .news-post-hr .post-contents .more a {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  text-transform: uppercase;
  font-size: 14px;
}

.news-hr .news-post-hr .post-contents:after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
}

@media (max-width: 1200px) {
  .news-hr .news-post-hr .post-contents:after {
    content: none;
  }
}

.about {
  padding: 120px 0;
}

.testimonial .testimonial-item {
  margin-bottom: 30px;
}

.testimonial .testimonial-item .comment {
  padding: 40px 30px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  position: relative;
  transition: 0.3s ease;
}

.testimonial .testimonial-item .comment:before {
  content: "";
  position: absolute;
  left: -2px;
  bottom: -27px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 27px 0;
  border-color: transparent #e5e5e5 transparent transparent;
  transition: 0.3s ease;
}

.testimonial .testimonial-item .comment:after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: -26px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 28px 27px 0;
  border-color: transparent #fff transparent transparent;
  transition: 0.3s ease;
}

.testimonial .testimonial-item .comment:hover {
  background: #161515;
  border: 1px solid #161515;
}

.testimonial .testimonial-item .comment:hover p {
  color: #fff;
}

.testimonial .testimonial-item .comment:hover:before {
  border-color: transparent #161515 transparent transparent;
}

.testimonial .testimonial-item .comment:hover:after {
  border-color: transparent #161515 transparent transparent;
}

.testimonial .testimonial-item .media {
  margin-left: 50px;
}

.testimonial .testimonial-item .media img {
  height: 65px;
  width: 65px;
  border-radius: 100%;
}

.testimonial .testimonial-item .media .media-body {
  margin-left: 15px;
  align-self: center;
}

.testimonial .testimonial-item .media .media-body .name p {
  font-family: "Montserrat", sans-serif;
  color: #222222;
}

.testimonial .testimonial-item .media .media-body .profession p {
  font-size: 0.8126rem;
  color: #161515;
}

.error .block {
  margin-top: 60px;
  margin-bottom: 60px;
}

.error .block img {
  margin-bottom: 45px;
}

.error .block h3 {
  font-weight: 400;
  color: #161515;
  margin-bottom: 40px;
}

.error .block h3 span {
  font-style: italic;
  color: #222222;
}

.faq .accordion-block #accordion .card,
.faq .accordion-block #accordionTwo .card {
  border-radius: 0;
  margin-bottom: 20px;
}

.faq .accordion-block #accordion .card .card-header,
.faq .accordion-block #accordionTwo .card .card-header {
  background: #fff;
  padding: 0;
}

.faq .accordion-block #accordion .card .card-header h5 a,
.faq .accordion-block #accordionTwo .card .card-header h5 a {
  font-weight: 400;
  color: #222222;
}

.faq .accordion-block #accordion .card .card-header h5 a span,
.faq .accordion-block #accordionTwo .card .card-header h5 a span {
  padding: 20px;
  border-right: 1px solid #e5e5e5;
  margin-right: 20px;
}

.faq .accordion-block #accordion .card .card-body,
.faq .accordion-block #accordionTwo .card .card-body {
  color: #848484;
  padding: 15px 20px;
}

.faq .accordion-block #accordion .card .collapse.show+.card-header h5 a,
.faq .accordion-block #accordionTwo .card .collapse.show+.card-header h5 a {
  color: #161515;
}

.contact-form .form-control {
  margin-bottom: 30px;
}

.contact-form button {
  margin-top: 30px;
  margin-bottom: 30px;
}

/*# sourceMappingURL=style.css.map */



.event-table {
  width: 100%;
}

.event-table td,
.event-table th {
  color: #2b686e;
  padding: 10px;
}

.event-table td {
  text-align: center;
  vertical-align: middle;
}

.event-table td:last-child {
  font-size: 0.95em;
  line-height: 1.4;
  text-align: left;
}

.event-table th {
  background-color: #daeff1;
  font-weight: 300;
}

.event-table tr:nth-child(2n) {
  background-color: white;
}

.event-table tr:nth-child(2n+1) {
  background-color: #edf7f8;
}
.pop_up_box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
}

.pop_up_button {
    font-size: 1em;
    padding: 10px;
    color: #fff;
    border: 2px solid #06d85f;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.pop_up_button:hover {
    background: #06d85f;
}

.pop_up_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 5;
}

.pop_up_overlay:target {
    visibility: visible;
    opacity: 1;
}

.pop_up_popup {
    margin: 0px auto;
    padding: 20px;
    top: 30%;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
}

.pop_up_popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.pop_up_popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.pop_up_popup .close:hover {
    color: #06d85f;
}

.pop_up_popup .content {
    max-height: 30%;
    overflow: auto;
}

@media screen and (max-width: 700px) {
    .pop_up_box {
        width: 70%;
    }

    .pop_up_popup {
        width: 70%;
    }
}
.textBox {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
  }
  
  .textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
  }
  
  
  .st_card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
  }
  
  .st_card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  
  .st_container {
    padding: 2px 16px;
  }
  
  
  
  .fv_container {
    box-shadow: 0 15px 30px 1px grey;
    background: rgba(255, 255, 255, 0.90);
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    height: 250px;
    width: 500px;
    margin-bottom: 22px;
  }
  
  @media screen and (max-width: 490px) {
    .fv_container {
      box-shadow: 0 15px 30px 1px grey;
      background: rgba(255, 255, 255, 0.90);
      text-align: center;
      border-radius: 5px;
      overflow: hidden;
      height: 250px;
      width: 350px;
      margin-bottom: 22px;
    }
  
    .fv_product-image {
      display: none;
    }
  
    .fv_product-details {
  
      float: left;
      width: 100%;
  
    }
  
  }
  
  @media screen and (max-width: 360px) {
    .fv_container {
      box-shadow: 0 15px 30px 1px grey;
      background: rgba(255, 255, 255, 0.90);
      text-align: center;
      border-radius: 5px;
      overflow: hidden;
      height: 250px;
      width: 100%;
      margin-bottom: 22px;
    }
  
    .fv_product-image {
      display: none;
    }
  
    .fv_product-details {
  
      float: left;
      width: 100%;
    }
  }
  
  
  /* @media screen and (max-width: 760px) {
    .fv_container {
      box-shadow: 0 15px 30px 1px grey;
      background: rgba(255, 255, 255, 0.90);
      text-align: center;
      border-radius: 5px;
      overflow: hidden;
      height: 250px;
      width: 650px;
      margin-bottom: 22px;
    }
    
  }
   */
  
  
  
  .fv_product-details {
    position: relative;
    text-align: left;
    overflow: hidden;
    padding: 20px;
  
    height: 100%;
    float: left;
    width: 50%;
    max-width: 50%;
  
  }
  
  
  .fv_container .product-details>p {
    font-family: 'EB Garamond', serif;
    text-align: center;
    font-size: 18px;
    color: #7d7d7d;
  
  }
  
  .fv_control {
    position: absolute;
    bottom: 5%;
    left: 55.8%;
    color: white;
  
  }
  
  .fv_btn {
  
    -webkit-transform: translateY(0px);
  
            transform: translateY(0px);
    transition: 0.3s linear;
    background: #595a5a;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border: none;
    color: #eee;
    padding: 10px;
    margin: 0;
  
  }
  
  .fv_btn:hover {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    background: #d5d6d8;
    color: black
  }
  
  .fv_btn span {
    font-family: 'Farsan', cursive;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    margin: 0;
  
  }
  
  .fv_btn .fv_price,
  .fv_shopping-cart {
    background: #333;
    border: 0;
    margin: 0;
  }
  
  .fv_btn .fv_price {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
    padding-right: 15px;
  }
  
  .fv_btn .fv_shopping-cart {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    position: absolute;
    background: #333;
    z-index: 1;
    left: 0;
    top: 0;
  }
  
  .fv_btn .fv_buy {
    z-index: 3;
    font-weight: bolder;
    color: white;
  }
  
  .fv_btn:hover .fv_price {
    -webkit-transform: translateX(-110%);
            transform: translateX(-110%);
  }
  
  .fv_btn:hover .fv_shopping-cart {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  
  
  
  .fv_product-image {
    transition: all 0.3s ease-out;
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 100%;
    float: right;
    width: 45%;
    display: inline-block;
  }
  
  .fv_container img {
    width: 100%;
    height: 100%;
  }
  
  .fv_info {
    background: rgba(27, 26, 26, 0.9);
    font-family: 'monospace', monospace;
    transition: all 0.3s ease-out;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    position: absolute;
    text-align: left;
    font-size: 105%;
    cursor: no-drop;
    color: #FFF;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  
  .fv_info h2 {
    text-align: center
  }
  
  .fv_product-image:hover .fv_info {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  
  .fv_info ul li {
    transition: 0.3s ease;
  }
  
  .fv_info ul li:hover {
    -webkit-transform: translateX(50px) scale(1.3);
            transform: translateX(50px) scale(1.3);
  }
  
  .fv_product-image:hover img {
    transition: all 0.3s ease-out;
  }
  
  .fv_product-image:hover img {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
  }
.wrapper_list {
	/* max-width: 90%; */
	margin: 0 auto;
}

ol_list {
	list-style: none;
	padding: 0;
}

li_list + li_list {
	margin-top: 1rem;
}

li_list {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background: aliceblue;
	padding: 1.5rem;
	border-radius: 1rem;
	width: calc(100% - 2rem);
	box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
}

li_list::before {
	counter-increment: list-item;
	content: counter(list-item);
	font-size: 3rem;
	font-weight: 700;
	width: 2em;
	height: 2em;
	background: black;
	flex: 0 0 auto;
	border-radius: 50%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

li_list:nth-child(even) {
	flex-direction: row-reverse;
	background: lavender;
	margin-right: -2rem;
	margin-left: 2rem;
}



.section-team_rs {
	font-family: "Poppins", sans-serif;
	/* padding: 80px 0; */
}



.section-team_rs .single-person {
	/* margin-top: 20px; */
	padding: 30px;
	background-color: #f2f2f2;
	border-radius: 5px;
    min-height: 450px;
}

.section-team_rs .single-person:hover {
	background: linear-gradient(to right, #606061, #bdbebe);
}

.section-team_rs .single-person .person-image {
    position: relative;
    border-radius: 50%;
    border: 4px dashed transparent;
    transition: padding .3s;
}

.section-team_rs .single-person:hover .person-image {
	padding: 12px;
    border: 4px dashed #fff;
}

.section-team_rs .single-person .person-image img {
	width: 100%;
    border-radius: 50%;
}

.section-team_rs .single-person:hover .speciality {
	color: #fff;
}
.wrapper_list {
	/* max-width: 90%; */
	margin: 0 auto;
}

ol_list {
	list-style: none;
	padding: 0;
}

li_list + li_list {
	margin-top: 1rem;
}

li_list {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background: aliceblue;
	padding: 1.5rem;
	border-radius: 1rem;
	width: calc(100% - 2rem);
	box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
}

li_list::before {
	counter-increment: list-item;
	content: counter(list-item);
	font-size: 3rem;
	font-weight: 700;
	width: 2em;
	height: 2em;
	background: black;
	flex: 0 0 auto;
	border-radius: 50%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

li_list:nth-child(even) {
	flex-direction: row-reverse;
	background: lavender;
	margin-right: -2rem;
	margin-left: 2rem;
}
.wrapper_list {
	/* max-width: 90%; */
	margin: 0 auto;
}

ol_list {
	list-style: none;
	padding: 0;
}

li_list + li_list {
	margin-top: 1rem;
}

li_list {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background: aliceblue;
	padding: 1.5rem;
	border-radius: 1rem;
	width: calc(100% - 2rem);
	box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
}

li_list::before {
	counter-increment: list-item;
	content: counter(list-item);
	font-size: 3rem;
	font-weight: 700;
	width: 2em;
	height: 2em;
	background: black;
	flex: 0 0 auto;
	border-radius: 50%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

li_list:nth-child(even) {
	flex-direction: row-reverse;
	background: lavender;
	margin-right: -2rem;
	margin-left: 2rem;
}
.wrapper_list {
	/* max-width: 90%; */
	margin: 0 auto;
}

ol_list {
	list-style: none;
	padding: 0;
}

li_list + li_list {
	margin-top: 1rem;
}

li_list {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background: aliceblue;
	padding: 1.5rem;
	border-radius: 1rem;
	width: calc(100% - 2rem);
	box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
}

li_list::before {
	counter-increment: list-item;
	content: counter(list-item);
	font-size: 3rem;
	font-weight: 700;
	width: 2em;
	height: 2em;
	background: black;
	flex: 0 0 auto;
	border-radius: 50%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

li_list:nth-child(even) {
	flex-direction: row-reverse;
	background: lavender;
	margin-right: -2rem;
	margin-left: 2rem;
}



.section-team_rs {
	font-family: "Poppins", sans-serif;
	/* padding: 80px 0; */
}



.section-team_rs .single-person {
	/* margin-top: 20px; */
	padding: 30px;
	background-color: #f2f2f2;
	border-radius: 5px;
    min-height: 300px;
}

.section-team_rs .single-person:hover {
	background: linear-gradient(to right, #606061, #bdbebe);
}

.section-team_rs .single-person .person-image {
    position: relative;
    border-radius: 50%;
    border: 4px dashed transparent;
    transition: padding .3s;
}

.section-team_rs .single-person:hover .person-image {
	padding: 12px;
    border: 4px dashed #fff;
}

.section-team_rs .single-person .person-image img {
	width: 100%;
    border-radius: 50%;
}

.section-team_rs .single-person:hover .speciality {
	color: #fff;
}

.delay-show { 
	-webkit-animation: showMe 2s forwards; 
	        animation: showMe 2s forwards;
}

.delay-news-show { 
	-webkit-animation: showMe 3s forwards; 
	        animation: showMe 3s forwards;
}
@-webkit-keyframes showMe{
    100%{
        opacity: 1;
    }
    0%{
        opacity: 0;
    }
}
@keyframes showMe{
    100%{
        opacity: 1;
    }
    0%{
        opacity: 0;
    }
}
.block.image-text .min-height-fix {
    display: flex
}

.block.image-text .rounded-box {
    display: flex;
    flex-direction: column;
    width: 100%
}

.block.image-text .rounded-box .sidekick-image {
    background-image: none
}

.block.image-text .rounded-box .sidekick-text {
    padding: 25px;
    text-align: left
}

.block.image-text .rounded-box .sidekick-text > *:first-child {
    margin-top: 0
}

.block.image-text .rounded-box .sidekick-text > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .block.image-text .rounded-box {
        flex-direction: row;
        align-items: center;
        min-height: 350px
    }

    .block.image-text .rounded-box .sidekick-image, .block.image-text .rounded-box .sidekick-text {
        width: 50%
    }

    .block.image-text .rounded-box .sidekick-image {
        height: auto;
        align-self: stretch;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat
    }

    .block.image-text .rounded-box .sidekick-image img {
        display: none
    }

    .block.image-text .rounded-box .sidekick-text {
        padding: 40px;
        text-align: center
    }

    .block.image-text.image-right .sidekick-text {
        order: -1
    }
}

@media only screen and (min-width: 992px) {
    .block.image-text .rounded-box {
        min-height: 400px
    }
}

